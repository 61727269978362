import { Ability } from '@casl/ability';

import auth from 'commons/auth';
import { userService } from 'commons/services';

const ability = new Ability([]);

export const abilityHistoryListener = async () => {
  const loggedUser = auth.getLoggedUser();

  if (!loggedUser || !loggedUser.id) {
    return;
  }

  const { data: userPermissions } = await userService.getUserPermissions(
    loggedUser.id
  );

  if (!!userPermissions.length) {
    const abilities = [];

    userPermissions.forEach(({ permissions, securityKey }) => {
      abilities.push({
        subject: securityKey,
        actions: permissions.map(permission => permission.key),
      });
    });
    ability.update(abilities);
  }
};

export default ability;
