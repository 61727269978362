import { createAction } from 'redux-actions';

export default {
  restoreState: createAction('mapShape/RESTORE_STATE'),
  clearMapShapes: createAction('mapShape/CLEAR_MAP_SHAPES'),
  updateActiveShape: createAction('mapShape/UPDATE_ACTIVE_SHAPE'),
  saveAreaShapes: createAction('mapShape/SAVE_AREA_SHAPES'),
  clearShapes: createAction('mapShape/CLEAR_SHAPES'),
  addNewShape: createAction('mapShape/ADD_NEW_SHAPE'),
  changeShapeTag: createAction('mapShape/CHANGE_SHAPE_TAG'),
  changeShapeDescription: createAction('mapShape/CHANGE_SHAPE_DESCRIPTION'),
  deleteShape: createAction('mapShape/DELETE_SHAPE'),
  getAreaSnapshots: createAction('mapShape/GET_AREA_SNAPSHOTS'),
  updateAreaSnapshots: createAction('mapShape/UPDATE_AREA_SNAPSHOTS'),
  toggleAreaSnapshot: createAction('mapShape/TOGGLE_AREA_SNAPSHOT'),
  getAreaSnapshotHistory: createAction('mapShape/GET_AREA_SNAPSHOT_HISTORY'),
  updateAreaSnapshotHistory: createAction(
    'mapShape/UPDATE_AREA_SNAPSHOT_HISTORY'
  ),
  saveAreaSnapshot: createAction('mapShape/SAVE_AREA_SNAPSHOT'),
  editAreaSnapshot: createAction('mapShape/EDIT_AREA_SNAPSHOT'),
  clearAreaSnaphots: createAction('mapShape/CLEAR_AREA_SNAPSHOTS'),
  clearHistory: createAction('mapShape/CLEAR_HISTORY'),
  deleteAreaSnapshot: createAction('mapShape/DELETE_AREA_SNAPSHOT_REQUEST'),
  deleteAreaSnapshotFromList: createAction(
    'mapShape/DELETE_AREA_SNAPSHOT_SUCCESS'
  ),
  getTags: createAction('mapShape/GET_TAGS'),
  updateTagsList: createAction('mapShape/UPDATE_TAGS_LIST'),
  saveTag: createAction('mapShape/SAVE_TAG'),
  addTagToList: createAction('mapShape/ADD_TAG_TO_LIST'),
  updateTagInList: createAction('mapShape/UPDATE_TAG_IN_LIST'),
  deleteTag: createAction('mapShape/DELETE_TAG'),
  deleteTagFromList: createAction('mapShape/DELETE_TAG_FROM_LIST'),
  updateTagInAllShapes: createAction('mapShape/UPDATE_TAG_IN_ALL_SHAPES'),
  deleteTagFromAllShapes: createAction('mapShape/DELETE_TAG_FROM_ALL_SHAPES'),
  clearTags: createAction('mapShape/CLEAR_TAGS'),
  addPointToDistance: createAction('mapShape/ADD_POINT_TO_DISTANCE'),
  updateDistance: createAction('mapShape/UPDATE_DISTANCE'),
  clearDistances: createAction('mapShape/CLEAR_DISTANCES'),
  addPathToPolygon: createAction('mapShape/ADD_PATH_TO_POLYGON'),
  updatePolygon: createAction('mapShape/UPDATE_POLYGON'),
  clearPolygons: createAction('mapShape/CLEAR_POLYGONS'),
  addBoundsToRectangle: createAction('mapShape/ADD_BOUNDS_TO_RECTANGLE'),
  updateRectangle: createAction('mapShape/UPDATE_RECTANGLE'),
  clearRectangles: createAction('mapShape/CLEAR_RECTANGLES'),
  addSettingsToCircle: createAction('mapShape/ADD_SETTINGS_TO_CIRCLE'),
  updateCircle: createAction('mapShape/UPDATE_CIRCLE'),
  clearCircles: createAction('mapShape/CLEAR_CIRCLES'),
};
