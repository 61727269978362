import React from 'react';
import { object, array, func } from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/styles';
import { injectIntl, FormattedMessage } from 'react-intl';

import Save from '@material-ui/icons/Save';
import Drafts from '@material-ui/icons/Drafts';
import PersonAdd from '@material-ui/icons/PersonAdd';
import DeleteSweep from '@material-ui/icons/DeleteSweep';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';

import MultiDialog from 'commons/components/MultiDialog';
import { ACTIVITY_ACTIONS } from 'commons/constants';
import { activityActions } from 'commons/store/activity';
import { appActions } from 'commons/store/app';

import ActivityUsers from './ActivityUsers';
import ActivityDialog from './ActivityDialog';

import styles from './styles';

export class ActivityActions extends React.PureComponent {
  static propTypes = {
    /* activity state */
    users: array.isRequired,
    /* app state */
    settings: object.isRequired,
    /* auth state */
    loggedUser: object.isRequired,
    /* activity actions */
    saveActivity: func.isRequired,
    updateActivityMembers: func.isRequired,
    //exportActivity: func.isRequired,
    /* app actions */
    showGlobalDrawer: func.isRequired,
    /* material-ui styles */
    classes: object.isRequired,
    /* react-intl */
    intl: object.isRequired,
  };

  state = {
    exportModalOpen: false,
    activityId: '',
    ordinaryDialog: { open: false },
    recurrentDialog: { open: false },
    openMenu: null,
    anchorEl: null,
    activity: {},
    actions: [],
  };

  componentDidMount() {
    this.setState({
      activity: this.props.activity,
      actions: [
        {
          id: ACTIVITY_ACTIONS.ADD_USER,
          name: <FormattedMessage id="LABELS.COMMONS.ADD_USER" />,
          icon: <PersonAdd />,
          action: this.handleAssociateUsers,
        },
        {
          id: ACTIVITY_ACTIONS.CONCLUDE_ACTIVITY,
          name: <FormattedMessage id="LABELS.ACTIVITIES.CONCLUDE_ACTIVITY" />,
          icon: <Save />,
          action: this.handleConcludeActivity,
        },
        {
          id: ACTIVITY_ACTIONS.REOPEN_ACTIVITY,
          name: <FormattedMessage id="LABELS.ACTIVITIES.REOPEN_ACTIVITY" />,
          icon: <Drafts />,
          action: this.handleReopenActivity,
        },
        {
          id: ACTIVITY_ACTIONS.DELETE_ACTIVITY,
          name: <FormattedMessage id="LABELS.ACTIVITIES.DELETE_ACTIVITY" />,
          icon: <DeleteSweep />,
          action: this.handleDeleteActivity,
        },
      ],
    });
  }

  isFinhished = ({ author, responsible, status }, item) => {
    const { loggedUser } = this.props;
    const { REOPEN_ACTIVITY, CONCLUDE_ACTIVITY } = ACTIVITY_ACTIONS;
    const userCanReopen = [author.id, responsible.id].includes(loggedUser.id);

    return (
      status === 'FINISHED' &&
      item.id !== REOPEN_ACTIVITY &&
      item.id === CONCLUDE_ACTIVITY &&
      userCanReopen
    );
  };

  isNotFinhised = ({ status }, item) => {
    const { REOPEN_ACTIVITY } = ACTIVITY_ACTIONS;
    return status !== 'FINISHED' && item.id === REOPEN_ACTIVITY;
  };

  isInvalidInitialDate = ({ initialDate }, item) => {
    const { CONCLUDE_ACTIVITY } = ACTIVITY_ACTIONS;
    return new Date(initialDate) > new Date() && item.id === CONCLUDE_ACTIVITY;
  };

  isValidUser = ({ author, responsible }, item) => {
    const { loggedUser } = this.props;
    const validUser = [responsible.id, author.id].includes(loggedUser.id);

    return validUser && item.id === ACTIVITY_ACTIONS.DELETE_ACTIVITY;
  };

  isRunning = ({ status }, item) =>
    status !== 'FINISHED' && item.id === ACTIVITY_ACTIONS.REOPEN_ACTIVITY;

  filterActivityActions = activity =>
    this.state.actions.filter(
      item =>
        !this.isFinhished(activity, item) &&
        !this.isNotFinhised(activity, item) &&
        !this.isInvalidInitialDate(activity, item) &&
        !this.isValidUser(activity, item) &&
        !this.isRunning(activity, item)
    );

  handleAssociateUsers = activity => {
    const { intl } = this.props;

    this.props.showGlobalDrawer({
      component: (
        <ActivityUsers
          users={this.props.users}
          activity={activity}
          onSave={this.handleAcitivtyMembersUpdate}
        />
      ),
      title: intl.formatMessage({ id: 'LABELS.USER.USERS_ASSOCIATION' }),
    });

    this.setState({ activity });
  };

  handleAcitivtyMembersUpdate = (activityId, members) => {
    this.props.updateActivityMembers({ activityId, members });
  };

  handleConcludeActivity = activity => {
    this.props.saveActivity({
      ...activity,
      members: [],
      status: 'FINISHED',
      concludedBy: { id: this.props.loggedUser.id },
      concludedAt: new Date().toISOString(),
      reopenedBy: null,
      reopenedAt: null,
    });
  };

  handleReopenActivity = activity => {
    this.props.saveActivity({
      ...activity,
      members: [],
      status: 'RUNNING',
      reopenedBy: { id: this.props.loggedUser.id },
      reopenedAt: new Date().toISOString(),
      concludedBy: null,
      concludedAt: null,
    });
  };

  handleDeleteActivity = activity => {
    const recurrentDialog = {
      open: true,
      title: activity.title,
      text: this.props.intl.formatMessage({
        id: 'MESSAGES.ACTIVITIES.DELETE_RECURRENT_ACTIVITY',
      }),
    };

    const ordinaryDialog = {
      open: true,
      title: activity.title,
      text: this.props.intl.formatMessage({
        id: 'MESSAGES.ACTIVITIES.DO_YOU_WANT_TO_DELETE',
      }),
      onConfirm: () => this.handleDeleteConfirm(false),
      onCancel: this.handleDialogCancel,
    };

    activity.recurrentActivityId
      ? this.setState({ recurrentDialog, activity })
      : this.setState({ ordinaryDialog, activity });
  };

  handleDeleteConfirm = deleteRecurrent => {
    const { activity } = this.state;

    this.props.deleteActivity({ activityId: activity.id, deleteRecurrent });
    this.handleDialogCancel();
  };

  handleDialogCancel = () => {
    this.setState({
      ordinaryDialog: { open: false },
      recurrentDialog: { open: false },
      activity: null,
    });
  };

  handleCloseOptions = () => {
    this.setState({ anchorEl: null, openMenu: false });
  };

  handleMultipleOptions = (event, id) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget, openMenu: id });
  };

  handleMultipleOptionsClose = () => {
    this.setState({ anchorEl: null, openMenu: false });
  };

  render() {
    const { activity } = this.props;
    const { anchorEl, openMenu, ordinaryDialog, recurrentDialog } = this.state;

    return (
      <>
        <MultiDialog {...ordinaryDialog} />
        <ActivityDialog
          dialog={recurrentDialog}
          onDeleteConfirm={this.handleDeleteConfirm}
          onDialogCancel={this.handleDialogCancel}
        />

        <IconButton
          disabled={activity.status === 'FINISHED'}
          onClick={event => {
            this.handleMultipleOptions(event, activity.id);
          }}
        >
          <MoreVert />
        </IconButton>
        {this.filterActivityActions(activity).length > 0 && (
          <Menu
            id={activity.id}
            anchorEl={anchorEl}
            open={openMenu === activity.id}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={event => {
              this.handleCloseOptions();
              event.stopPropagation();
            }}
          >
            {this.filterActivityActions(activity).map(item => (
              <MenuItem
                key={item.id}
                onClick={event => {
                  this.handleMultipleOptionsClose();
                  item.action(activity);
                  event.stopPropagation();
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </Menu>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  app: { settings },
  auth: { user },
  activity: { lists },
}) => ({
  settings,
  loggedUser: user,
  users: lists.users,
});

const mapDispatchToProps = {
  showGlobalDrawer: appActions.showGlobalDrawer,
  saveActivity: activityActions.saveActivity,
  deleteActivity: activityActions.deleteActivity,
  updateActivityMembers: activityActions.updateActivityMembers,
  //exportActivity: activityActions.exportActivity,
};

export default compose(
  injectIntl,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ActivityActions);
