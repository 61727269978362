import produce from 'immer';
import { handleActions } from 'redux-actions';

import { loadingActions } from 'commons/store/loading';

const isLoading = ({ loading }, action) => !!loading[action];

const actionLoading = ({ loading }, action) => loading[action];

const actionsLoadings = ({ loading }, actions) => {
  return (
    Object.keys(loading)
      .filter(item => actions.includes(item))
      // eslint-disable-next-line no-sequences
      .reduce((acc, item) => ((acc[item] = loading[item]), acc), {})
  );
};

export const selectors = {
  isLoading,
  actionLoading,
  actionsLoadings,
};

const initialState = {};

export default handleActions(
  {
    [loadingActions.setLoading]: produce((draft, { payload }) => {
      const { action } = payload;
      draft[action] = payload;
    }),
    [loadingActions.unsetLoading]: produce((draft, { payload }) => {
      delete draft[payload];
    }),
  },
  initialState
);
