export default ({ menu }) => ({
  content: {
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    zIndex: 110,
    width: 52,
    background: menu.background,
    overflow: 'hidden',
    transition: 'width 225ms cubic-bezier(.25, .1, .25, .6) 0ms',
    '&.expanded': {
      width: 248,
      overflowY: 'auto',
    },
  },
  toggleMenu: {
    display: 'flex',
    height: '3.5rem',
    marginBottom: 2,
  },
  title: {
    color: menu.text.color,
    textTransform: 'uppercase',
    fontSize: '.85rem',
    padding: '1rem',
  },
  toggleButton: {
    color: menu.text.color,
    marginLeft: '.2rem',
    marginTop: '.5rem',
    transition: 'transform 225ms cubic-bezier(.25, .1, .25, .6) 0ms',
    transform: 'rotate(0deg)',
    '&.expanded': {
      transform: 'rotate(90deg)',
    },
  },
});
