import React from 'react';
import { func, object } from 'prop-types';

import { Form, Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

import validate from 'commons/validate';
import { TextFieldAdapter } from 'commons/form';

import styles from './styles';

const useStyles = makeStyles(styles);

const validateForm = values => {
  const requiredFieldsErrors = validate.requiredFields({
    fields: ['username', 'password'],
    values,
  });

  return requiredFieldsErrors;
};

export function LoginForm({
  intl,
  onSubmit,
  onRecovery,
  changePasswordDisabled,
}) {
  const classes = useStyles();

  const handleForgotPassword = React.useCallback(() => onRecovery(), [
    onRecovery,
  ]);

  const renderForm = ({ handleSubmit, values }) => (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Field
        name="username"
        margin="dense"
        type="text"
        className={classes.field}
        label={intl.formatMessage({
          id: 'LABELS.LOGIN.USERNAME',
          defaultMessage: 'Login',
        })}
        component={TextFieldAdapter}
        autoComplete="off"
        fullWidth
      />
      <Field
        name="password"
        margin="dense"
        type="password"
        className={classes.field}
        label={intl.formatMessage({ id: 'LABELS.COMMONS.PASSWORD' })}
        component={TextFieldAdapter}
        autoComplete="off"
        fullWidth
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        className={classes.loginButton}
        disabled={!values.username || !values.password}
      >
        <FormattedMessage id="LABELS.COMMONS.ENTER" />
      </Button>
      {!changePasswordDisabled && (
        <div className={classes.forgotPassword} onClick={handleForgotPassword}>
          <FormattedMessage id="LABELS.COMMONS.FORGOT_MY_PASSWORD" />
        </div>
      )}
    </form>
  );

  return (
    <div className={classes.container}>
      <Form onSubmit={onSubmit} validate={validateForm} render={renderForm} />
    </div>
  );
}

LoginForm.propTypes = {
  /* own props */
  onSubmit: func.isRequired,
  onRecovery: func.isRequired,
  /* react-intl */
  intl: object.isRequired,
};

export default compose(
  injectIntl,
  React.memo
)(LoginForm);
