import { createAction } from 'redux-actions';

export default {
  initTree: createAction('file/INIT_TREE'),
  getTreeNode: createAction('file/GET_TREE_NODE_REQUEST'),
  updateTreeNode: createAction('file/GET_TREE_NODE_SUCCESS'),
  toggleTreeNode: createAction('file/TOGGLE_TREE_NODE'),
  insertNode: createAction('file/INSERT_NODE_REQUEST'),
  updateNode: createAction('file/UPDATE_NODE_REQUEST'),
  deleteNode: createAction('file/DELETE_NODE_REQUEST'),
  deleteLocalNode: createAction('file/DELETE_NODE_SUCCESS'),
  downloadFile: createAction('file/DOWNLOAD_FILE'),
  expandNode: createAction('file/EXPAND_NODE'),
  fileDetails: createAction('file/FILE_DETAILS'),
  moveFolderToDestiny: createAction('file/MOVE_FOLDER_TO_DESTINY'),
  getNodeFolders: createAction('file/GET_NODE_FOLDERS'),
  addNodeFoldersToList: createAction('file/GET_NODE_FOLDERS_SUCCESS'),
  updateNodeContractedState: createAction('file/UPDATE_NODE_CONTRACTED_STATE'),
  setNodeAsDestiny: createAction('file/SET_NODE_AS_DESTINY'),
  clearFilesTree: createAction('file/CLEAR_FILES_TREE'),
  getMapFileTreeForLayer: createAction('file/GET_MAP_FILE_TREE_FOR_LAYER'),
  updateFileDetails: createAction('file/UPDATE_FILE_DETAILS'),
  getFileDetails: createAction('file/GET_FILE_DETAILS'),
};
