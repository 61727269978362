import React from 'react';
import { func, object } from 'prop-types';

import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';

import { Selector } from 'commons/form';

import styles from './styles';

const useStyles = makeStyles(styles);

export function FilterHeader({ intl, filter, onChange }) {
  const classes = useStyles();

  const {
    editing: { themes },
  } = useSelector(({ activity }) => activity);

  const getFilterListForFieldName = React.useCallback(
    (name, value) => {
      let list = themes;
      const { theme, category } = filter;

      name === 'category' && (list = theme.categories);
      name === 'elementType' && (list = category.elementTypes);

      return list.find(item => item.id === value);
    },
    [filter, themes]
  );

  const handleFilterChange = React.useCallback(
    ({ target }) => {
      const { name, value } = target;
      onChange({
        name,
        value: getFilterListForFieldName(name, value),
      });
    },
    [getFilterListForFieldName, onChange]
  );

  return (
    <div className={classes.container}>
      <Selector
        name="theme"
        className={classes.filterField}
        value={filter.theme.id}
        label={intl.formatMessage({ id: 'LABELS.COMMONS.THEME' })}
        onChange={handleFilterChange}
        options={themes}
      />
      {/* <Selector
        name="category"
        disabled={!filter.theme.id}
        className={classes.filterField}
        value={filter.category.id}
        label={intl.formatMessage({ id: 'LABELS.MAP.CATEGORY' })}
        onChange={handleFilterChange}
        options={filter.theme.categories}
      />
      <Selector
        name="elementType"
        disabled={!filter.category.id}
        className={classes.filterField}
        value={filter.elementType.id}
        label={intl.formatMessage({ id: 'LABELS.COMMONS.ELEMENT_TYPE' })}
        onChange={handleFilterChange}
        options={filter.category.elementTypes}
      /> */}
    </div>
  );
}

FilterHeader.propTypes = {
  /* own props */
  filter: object.isRequired,
  onChange: func.isRequired,
  /* react-intl */
  intl: object.isRequired,
};

export default React.memo(injectIntl(FilterHeader));
