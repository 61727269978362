import { takeEvery, put, putResolve } from 'redux-saga/effects';

import notification from 'commons/notification';
import api, { baseURL } from 'commons/api';
import routes from 'commons/routes';
import { config } from 'commons/store';
import { fileService } from 'commons/services';
import { fileActions } from 'commons/store/file';
import { mapThemeActions } from 'commons/store/mapTheme';

function* initTree() {
  yield putResolve(
    fileActions.updateTreeNode([{ id: 'root', root: true, children: [] }])
  );
  yield* getTreeNode({ payload: { node: 'root' } });
}

function* toggleTreeNode({ payload: node }) {
  if (!node.contracted) {
    yield* getTreeNode({ payload: { node: node.id } });
  }
  yield put(fileActions.updateNodeContractedState(node.id));
}

function* getTreeNode({ payload }) {
  const { node, silent } = payload;

  yield* api(
    fileService.getTreeNode,
    node,
    fileActions.updateTreeNode,
    function*(err) {
      yield notification('MESSAGES.FILES.COULD_NOT_GET_FILES_TREE', {
        type: 'error',
      });
    },
    { delay: 0, multiple: false, loading: !silent }
  );
}

function* moveFolder({ payload }) {
  yield* api(
    fileService.moveFolder,
    payload,
    function*() {
      const { source, destiny } = payload;
      yield putResolve(fileActions.deleteLocalNode(source));
      yield put(fileActions.getTreeNode({ node: { destiny } }));
      yield put(fileActions.fileDetails());
      yield notification('MESSAGES.FILES.SUCCESSFUL_MOVED_FOLDER', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.FILES.COULD_NOT_MOVE_FOLDER', {
        type: 'error',
      });
    },
    { multiple: true }
  );
}

function* getNodeFolders({ payload = 'root' }) {
  yield* api(
    fileService.getNodeFolders,
    payload,
    fileActions.addNodeFoldersToList,
    function*(err) {
      yield notification('MESSAGES.FILES.COULD_NOT_GET_FILES_TREE', {
        type: 'error',
      });
    },
    { delay: 0, multiple: false, loading: false }
  );
}

function* insertNode({ payload }) {
  const { parentId, node, silent } = payload;

  yield* api(
    fileService.insertNode,
    { parentId, node },
    function*(node) {
      const url = config.history.location.pathname;

      yield put(fileActions.expandNode(parentId));

      if (url === routes.urls.FILES) {
        yield* getTreeNode({ payload: { node: parentId, silent } });
        return;
      }

      if (url.includes(routes.urls.MAP_SETTINGS)) {
        yield* getTreeNode({ payload: { node: parentId, silent } });
        return;
      }
    },
    function*(err) {
      yield notification('MESSAGES.FILES.COULD_NOT_CREATE_NEW_FOLDER', {
        type: 'error',
      });
    },
    { loading: false }
  );
}

function* updateNode({ payload }) {
  yield* api(
    fileService.updateNode,
    payload,
    function*(node) {
      const {
        node: { childrenLength },
      } = payload;

      yield putResolve(fileActions.deleteLocalNode(node.id));
      yield putResolve(
        fileActions.updateTreeNode([{ ...node, childrenLength }])
      );
    },
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_RENAME_FOLDER', {
        type: 'error',
      });
    }
  );
}

function* deleteNode({ payload }) {
  const { nodeId, noInfoError } = payload;
  const id = noInfoError ? nodeId : payload;

  yield* api(
    fileService.deleteNode,
    id,
    function*() {
      yield put(fileActions.deleteLocalNode(id));
    },
    function*(err) {
      if (noInfoError) {
        return;
      }
      yield notification('MESSAGES.MAP.COULD_NOT_DELETE_FOLDER', {
        type: 'error',
      });
    }
  );
}

function* download({ payload }) {
  yield window.open(`${baseURL}/files/download?id=${encodeURIComponent(payload)}`, '_blank');
}

function* getMapFileTreeForLayer({ payload }) {
  yield* api(
    fileService.getMapFileTreeForLayer,
    payload,
    function*(root) {
      yield put(fileActions.updateTreeNode([root]));
    },
    function*(err) {
      console.log(err);
      yield notification('MESSAGES.FILES.COULD_NOT_GET_FILES_TREE', {
        type: 'error',
      });
    }
  );
}

function* getFileDetails({ payload }) {
  yield* api(
    fileService.getFileDetails,
    payload,
    function*(response) {
      yield put(fileActions.updateFileDetails(response));
      yield put(mapThemeActions.openVideoPlayer(true));
      if (response.goproGpsMetadata && response.goproGpsMetadata.length) {
        yield put(
          mapThemeActions.updateEditingElement({
            location: {
              lat: response.goproGpsMetadata[0].value[0],
              lng: response.goproGpsMetadata[0].value[1],
            },
          })
        );
        yield put(mapThemeActions.setIsGeoReferencedVideo(true));
      } else {
        yield put(mapThemeActions.setIsGeoReferencedVideo(false));
      }
    },
    err => console.log(err),
    { loading: false }
  );
}

export default function*() {
  yield takeEvery(fileActions.initTree.toString(), initTree);
  yield takeEvery(fileActions.toggleTreeNode.toString(), toggleTreeNode);
  yield takeEvery(fileActions.getTreeNode.toString(), getTreeNode);
  yield takeEvery(fileActions.getNodeFolders.toString(), getNodeFolders);
  yield takeEvery(fileActions.insertNode.toString(), insertNode);
  yield takeEvery(fileActions.updateNode.toString(), updateNode);
  yield takeEvery(fileActions.deleteNode.toString(), deleteNode);
  yield takeEvery(fileActions.moveFolderToDestiny.toString(), moveFolder);
  yield takeEvery(fileActions.downloadFile.toString(), download);
  yield takeEvery(
    fileActions.getMapFileTreeForLayer.toString(),
    getMapFileTreeForLayer
  );

  yield takeEvery(fileActions.getFileDetails.toString(), getFileDetails);
}
