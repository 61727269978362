export default {
  container: {
    position: 'relative',
    padding: '.5rem',
  },
  dropzone: {
    border: '2px dashed rgba(40, 79, 145, 0.61)',
    minHeight: '20rem',
    margin: '.8rem',
    flex: '1',
  },
  fileInput: {
    margin: '1rem',
  },
  dropFileText: {
    textAlign: 'center',
    lineHeight: '8',
    color: '#284f91',
  },
  thumbnail: {
    textAlign: 'center',
    fontSize: 'small',
    margin: '.5rem',
  },
  uploadedFiles: {
    margin: '.5rem',
  },
  itemAvatar: {
    height: 40,
    width: 40,
  },
  secondary: {
    color: '#ff6103',
  },
  filename: { wordWrap: 'break-word' },
};
