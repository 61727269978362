export default {
  container: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    zIndex: 1,
    alignItems: 'center',
    padding: '.2rem 0 .2rem 1rem',
    justifyContent: 'space-between',
    borderBottom: '1px solid #e6e6e6',
    background: '#f9f9f9',
  },
  closeButton: {
    margin: '.3rem',
    height: 43,
    width: 43,
  },
  content: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  elementsOpen: {
    transform: 'translateX(-20rem)',
  },
};
