import { takeEvery, put, fork } from 'redux-saga/effects';

import auth from 'commons/auth';
import routes from 'commons/routes';
import notification from 'commons/notification';
import api from 'commons/api';
import { config } from 'commons/store';
import { authService } from 'commons/services';
import { authActions } from 'commons/store/auth';
import { appActions } from 'commons/store/app';
import { mapLayerActions } from 'commons/store/mapLayer';

function* getLoggedInUser() {
  yield* api(
    authService.getLoggedInUser,
    {},
    function*(data) {
      yield put(mapLayerActions.updateExpandedNodes(data.preferences));
      yield put(authActions.updateLoggedInUser(data));
    },
    err => console.log(err)
  );
}

function* logIn({ payload: { username, password, redirect } }) {
  yield* api(
    authService.logIn,
    { username, password },
    function*(data) {
      yield* authenticate({ payload: { accessToken: data, redirect } });
      yield put(appActions.getModules());
    },
    function*(_err) {
      yield notification('MESSAGES.LOGIN.COULD_NOT_LOG_IN', { type: 'error' });
    }
  );
}

function* authenticate({ payload: { accessToken, redirect } }) {
  auth.setToken(accessToken.access_token);
  yield fork(getLoggedInUser);
  config.history.push(redirect || routes.urls.PANEL);
}

function* logOut() {
  auth.clear();
  yield put(authActions.deleteLoggedInUser());
  yield put(appActions.clearApp());
  config.history.push(routes.urls.LOGIN);
}

function* changePassword({ payload: { newPassword, oldPassword, reset } }) {
  yield* api(
    authService.changePassword,
    { newPassword, oldPassword },
    function*() {
      reset();
      yield notification('MESSAGES.USER.PASSWORD_CHANGED', { type: 'success' });
    },
    function*(err) {
      yield notification('MESSAGES.USER.COULD_NOT_CHANGE_PASSWORD', {
        type: 'error',
      });
    }
  );
}

function* recoveryPassword({ payload }) {
  yield* api(
    authService.recoveryPassword,
    payload,
    function*() {
      yield notification('MESSAGES.USER.AN_EMAIL_HAS_BEEN_SENT_TO_YOU', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification(
        'MESSAGES.USER.COULD_NOT_REQUEST_A_PASSWORD_RECOVERY',
        { type: 'error' }
      );
    }
  );
}

function* resetPassword({ payload }) {
  yield* api(
    authService.resetPassword,
    payload,
    function*(data) {
      auth.setToken(data.access_token);
      yield fork(getLoggedInUser);
      yield put(appActions.getModules());
      yield notification('MESSAGES.LOGIN.PASSWORD_CHANGED', {
        type: 'success',
      });
      config.history.push(routes.urls.PANEL);
    },
    function*(err) {
      yield notification('MESSAGES.LOGIN.COULD_NOT_CHANGE_PASSWORD', {
        type: 'error',
      });
    }
  );
}

export default function*() {
  yield takeEvery(authActions.logIn.toString(), logIn);
  yield takeEvery(authActions.logOut.toString(), logOut);
  yield takeEvery(authActions.authenticate.toString(), authenticate);
  yield takeEvery(authActions.getLoggedInUser.toString(), getLoggedInUser);
  yield takeEvery(authActions.changePassword.toString(), changePassword);
  yield takeEvery(authActions.recoveryPassword.toString(), recoveryPassword);
  yield takeEvery(authActions.resetPassword.toString(), resetPassword);
}
