import { config } from 'commons/store';

const getTenant = () => sessionStorage.getItem('__tenant');

const getToken = () => JSON.parse(localStorage.getItem(`__token`));

const setTenant = tenant => {
  sessionStorage.setItem('__tenant', tenant);
  document.cookie = `tenantid=${tenant}`;
};

const setToken = token =>
  localStorage.setItem(`__token`, JSON.stringify(token));

const deleteToken = () => localStorage.removeItem(`__token`);

const getHeaders = () => {
  const headers = {};
  const token = getToken();
  if (!!token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

const getLoggedUser = () => {
  return config.store.getState().auth.user; // TODO: usar um selector
};

const clear = () => {
  deleteToken();
};

export default {
  getToken,
  setToken,
  deleteToken,
  getTenant,
  setTenant,
  clear,
  getHeaders,
  getLoggedUser,
};
