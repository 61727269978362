import React from 'react';
import { object, func } from 'prop-types';

import { Field } from 'react-final-form';
import { injectIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

import { DatePickerAdapter } from 'commons/form';

import styles from './styles';

const useStyles = makeStyles(styles);

export function ActivityDateFields({ intl, values, onError }) {
  const classes = useStyles();

  const minDate = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return values.initialDate || date;
  };

  return (
    <>
      <Grid item xs={4} className={classes.field}>
        <Field
          name="dueDate"
          autoComplete="off"
          margin="dense"
          variant="inline"
          component={DatePickerAdapter}
          onError={onError}
          label={intl.formatMessage({ id: 'LABELS.ACTIVITIES.DUE_DATE' })}
          minDateMessage={intl.formatMessage({
            id: 'MESSAGES.ACTIVITIES.DATE_LESS_THAN_CURRENT_DATE',
          })}
          disablePast
          fullWidth
          autoOk
        />
      </Grid>
      <Grid item xs={4} className={classes.field}>
        <Field
          name="initialDate"
          margin="dense"
          autoComplete="off"
          variant="inline"
          onError={onError}
          maxDate={values.dueDate}
          component={DatePickerAdapter}
          label={intl.formatMessage({
            id: 'LABELS.ACTIVITIES.INITIAL_DATE',
          })}
          minDateMessage={intl.formatMessage({
            id: 'MESSAGES.ACTIVITIES.DATE_LESS_THAN_CURRENT_DATE',
          })}
          maxDateMessage={intl.formatMessage({
            id: 'MESSAGES.ACTIVITIES.INITIAL_DATE_GREATER_THAN_DUE_DATE',
          })}
          disablePast
          fullWidth
          autoOk
        />
      </Grid>
      <Grid item xs={4} className={classes.field}>
        <Field
          name="criticalDate"
          margin="dense"
          autoComplete="off"
          variant="inline"
          onError={onError}
          component={DatePickerAdapter}
          maxDate={values.dueDate}
          minDate={minDate()}
          label={intl.formatMessage({
            id: 'LABELS.ACTIVITIES.CRITICAL_DATE',
          })}
          invalidDateMessage={null}
          minDateMessage={intl.formatMessage({
            id: 'MESSAGES.ACTIVITIES.DATE_LESS_THAN_CURRENT_DATE',
          })}
          maxDateMessage={intl.formatMessage({
            id: 'MESSAGES.ACTIVITIES.CRITIAL_DATE_GREATER_THAN_DUE_DATE',
          })}
          disablePast
          fullWidth
          autoOk
        />
      </Grid>
    </>
  );
}

ActivityDateFields.propTypes = {
  /* own props */
  values: object.isRequired,
  onError: func.isRequired,
  /* react-intl */
  intl: object.isRequired,
};

export default React.memo(injectIntl(ActivityDateFields));
