import React from 'react';
import { useDispatch } from 'react-redux';

import { authActions } from 'commons/store/auth';

function GuestLogin() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      authActions.logIn({
        username: 'guest',
        password: '654321',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default React.memo(GuestLogin);
