import React, { useEffect, useMemo, useState } from 'react';

import fileAPI from 'commons/services/file';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { appActions } from 'commons/store/app';

export default function DailyUploadLimit({ classes }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const settings = useSelector(({ app: { settings } }) => settings);
  const usedUploadLimit = useSelector(
    ({ app: { usedUploadLimit } }) => usedUploadLimit
  );

  const infoUploadLimit = useMemo(() => {
    let unit = 'gb';
    let color = '#000000';
    const baseToConvertToKilobyte = 1000;
    const baseToConvertToMegabyte = 1000000;
    const baseToConvertToGigabyte = 1000000000;
    const value = settings?.maxDailyUpload || 1;
    const limit = value / baseToConvertToGigabyte;
    const used = usedUploadLimit / baseToConvertToGigabyte;
    let usedLabel = used;
    if (usedUploadLimit < baseToConvertToMegabyte) {
      //menor que 1mb
      unit = 'kb';
      usedLabel = usedUploadLimit / baseToConvertToKilobyte;
    } else if (usedUploadLimit < baseToConvertToGigabyte) {
      //menor que 1gb
      unit = 'mb';
      usedLabel = usedUploadLimit / baseToConvertToMegabyte;
    }
    let percent = (used * 100) / limit;
    if (percent >= 70) color = '#FF7700';
    else if (percent >= 90) color = '#ff0000';
    return (
      <>
        <span style={{ color }}>{`${usedLabel.toFixed(4)}${unit}`}</span>
        <span>{` / ${limit}gb`}</span>
      </>
    );
  }, [settings, usedUploadLimit]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await fileAPI.getUsedDailyLimit();
        dispatch(appActions.getUsedDailyLimit(response.data));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch]);

  if (loading) {
    return <CircularProgress size={12} />;
  }

  return (
    <>
      {settings?.maxDailyUpload && settings.maxDailyUpload > 0 && (
        <div className={classes.uploadLimitInfo}>
          <FormattedMessage id="LABELS.FILES.DAILY_UPLOAD_SIZE" />:{' '}
          {infoUploadLimit}
        </div>
      )}
    </>
  );
}
