import React from 'react';

import cx from 'clsx';
import { string, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';

import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/styles';

import { VIEWS } from 'commons/constants';
import { ActivityDrawerContext } from 'commons/contexts';
import { activityActions } from 'commons/store/activity';

import ActivityEditor from './ActivityEditor';
import ActivityDetails from './ActivityDetails';

import styles from './styles';

const useStyles = makeStyles(styles);

const { closeActivity } = activityActions;

export function ActivityDrawer({ openedView, activity }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [elementsDrawerOpen, setElementsDrawerOpen] = React.useState(false);

  const activityTitle = React.useMemo(() => {
    if (openedView === VIEWS.ACTIVITY_EDITOR) {
      const title = activity.id ? 'EDITING_ACTIVITY' : 'NEW_ACTIVITY';

      return (
        <FormattedMessage variant="h6" id={`LABELS.ACTIVITIES.${title}`} />
      );
    }

    return activity.title;
  }, [activity, openedView]);

  const handleClose = React.useCallback(() => dispatch(closeActivity()), [
    dispatch,
  ]);

  const handleSecondaryDrawerToggle = React.useCallback(() => {
    setElementsDrawerOpen(prev => !prev);
  }, []);

  return (
    <Drawer
      anchor="right"
      className={cx({ [classes.elementsOpen]: elementsDrawerOpen })}
      open={!!openedView}
      onClose={handleClose}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h6">{activityTitle}</Typography>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </div>
        <div className={classes.content}>
          {openedView === VIEWS.ACTIVITY_EDITOR ? (
            <ActivityDrawerContext.Provider
              value={{
                elementsDrawerOpen,
                onSecondaryDrawerToggle: handleSecondaryDrawerToggle,
              }}
            >
              <ActivityEditor />
            </ActivityDrawerContext.Provider>
          ) : (
            <ActivityDetails />
          )}
        </div>
      </div>
    </Drawer>
  );
}

ActivityDrawer.propTypes = {
  /* activity state */
  openedView: string.isRequired,
  activity: object.isRequired,
};

const mapStateToProps = ({ activity: { activity, editing } }) => ({
  activity,
  openedView: editing.openedView,
});

export default compose(
  connect(mapStateToProps),
  React.memo
)(ActivityDrawer);
