import React from 'react';
import { func, string, object } from 'prop-types';

import { FormattedMessage, injectIntl } from 'react-intl';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/styles';

import { VIEWS } from 'commons/constants';

import styles from './styles';

const useStyles = makeStyles(styles);

export function ActivityTypeSelector({ intl, value, onChange }) {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.container}>
      <FormLabel component="legend">
        <FormattedMessage id="LABELS.ACTIVITIES.ACTIVITY_TYPE" />
      </FormLabel>
      <RadioGroup
        aria-label="Type"
        name="type"
        className={classes.radioGroup}
        value={value}
        onChange={onChange}
      >
        <FormControlLabel
          value={VIEWS.MAP}
          control={<Radio />}
          label={intl.formatMessage({ id: 'LABELS.COMMONS.MAP' })}
        />
        <FormControlLabel
          value={VIEWS.ELEMENT}
          control={<Radio />}
          label={intl.formatMessage({
            id: 'LABELS.COMMONS.ELEMENT',
          })}
        />
      </RadioGroup>
    </FormControl>
  );
}

ActivityTypeSelector.propTypes = {
  /* own props */
  value: string.isRequired,
  onChange: func.isRequired,
  /* react-intl */
  intl: object.isRequired,
};

export default React.memo(injectIntl(ActivityTypeSelector));
