import axios from 'axios';

export default {
  getModules: () => axios.get('/modules'),
  getApplicationFilters: (moduleId, applicationId) =>
    axios.get(`/modules/${moduleId}/applications/${applicationId}/filters`),
  updateApplicationFilters: (moduleId, applicationId, application) =>
    axios.put(
      `/modules/${moduleId}/applications/${applicationId}/filters`,
      application
    ),
};
