import { handleActions } from 'redux-actions';

import { layerTypeActions } from '.';

/* state */
const layerTypeState = {
  name: '',
  attributes: [],
  styleFunction: '',
};

const initialState = {
  layerTypes: [],
  editing: false,
  details: layerTypeState,
};

/* handler */
export default handleActions(
  {
    [layerTypeActions.updateLayerTypesList]: (state, { payload }) => ({
      ...state,
      layerTypes: payload,
    }),
    [layerTypeActions.saveLayerTypeAsCurrent]: (state, { payload }) => ({
      ...state,
      details: payload,
      editing: true,
    }),
    [layerTypeActions.addLayerTypeToList]: (state, { payload }) => ({
      ...state,
      layerTypes: [payload, ...state.layerTypes],
    }),
    [layerTypeActions.addNewLayerType]: state => ({
      ...state,
      editing: true,
    }),
    [layerTypeActions.cancelLayerTypeEditing]: state => ({
      ...state,
      editing: false,
      details: layerTypeState,
    }),
    [layerTypeActions.updateLayerTypeInList]: (state, { payload }) => {
      const { layerTypes: _layerTypes } = state;
      const { id } = payload;

      const layerTypes = _layerTypes.map(item =>
        item.id === id ? payload : item
      );

      return { ...state, layerTypes };
    },
    [layerTypeActions.removeLayerTypeFromList]: (state, { payload }) => {
      const { layerTypes: _layerTypes, details } = state;

      const layerTypes = _layerTypes.filter(item => item.id !== payload);

      const restState =
        details.id === payload
          ? { details: layerTypeState, editing: false }
          : { ...state };

      return { ...restState, layerTypes };
    },
    [layerTypeActions.clearLayerTypes]: () => initialState,
  },
  initialState
);
