import React from 'react';
import { object, func } from 'prop-types';

import { Form, Field } from 'react-final-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

import LoginContainer from 'commons/components/LoginContainer';

import validate from 'commons/validate';
import { MINIMUM_PASSWORD_SIZE } from 'commons/constants';
import { TextFieldAdapter } from 'commons/form';
import { authActions } from 'commons/store/auth';

import styles from './styles';
import SwipeableViews from 'react-swipeable-views';
import { RecoveryForm } from 'App/Login/RecoveryForm/RecoveryForm';

export class ResetPassword extends React.Component {
  state = {
    changeForm: this.props.match.params.id,
  };
  static propTypes = {
    /* auth actions */
    resetPassword: func.isRequired,
    recoveryPassword: func.isRequired,
    /* material-ui styles */
    classes: object.isRequired,
    /* react-router */
    match: object.isRequired,
    /* react-intl */
    intl: object.isRequired,
  };

  validateForm = values => {
    const { intl } = this.props;
    const { new_password, confirm_password } = values;

    const invalidFieldErrors = {};

    new_password !== confirm_password &&
      (invalidFieldErrors.confirm_password = intl.formatMessage({
        id: 'MESSAGES.LOGIN.PASSWORD_DO_NOT_MATCH',
      }));

    if (!!new_password) {
      new_password.length < MINIMUM_PASSWORD_SIZE &&
        (invalidFieldErrors.new_password = intl.formatMessage({
          id: 'MESSAGES.COMMONS.PASSWORD_TOO_SHORT',
        }));
    }

    const requiredFieldsErrors = validate.requiredFields({
      fields: ['new_password', 'confirm_password'],
      values,
    });

    return { ...requiredFieldsErrors, ...invalidFieldErrors };
  };

  handleRecovery = values => {
    const {
      match: { params },
    } = this.props;

    this.props.resetPassword({
      password: values.confirm_password,
      key: params.id,
    });
  };

  handleRecoveryByEmail = values => {
    this.props.recoveryPassword(values.username);
  };

  handleRecoveryPassword = () => {
    this.setState({ changeForm: 1 });
  };

  renderLink = () => {
    const { classes } = this.props;
    return (
      <div>
        <FormattedMessage id="Este link não é mais válido para criação de senha." />
        <div
          className={classes.forgotPassword}
          onClick={this.handleRecoveryPassword}
        >
          <FormattedMessage id="Clique aqui para criar nova senha." />
        </div>
      </div>
    );
  };
  renderRecovery = () => {
    const { classes, intl } = this.props;
    return (
      <div className={classes.form}>
        <SwipeableViews axis="x" index={0} className={classes.swipeViews}>
          <RecoveryForm
            intl={intl}
            canRender={true}
            onRecovery={this.handleRecoveryByEmail}
          />
        </SwipeableViews>
      </div>
    );
  };

  renderForm = ({ handleSubmit, values, invalid }) => {
    const { classes, intl } = this.props;
    const { changeForm } = this.state;
    return (
      <>
        {changeForm !== 'null' && changeForm !== 1 && (
          <div>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Field
                name="new_password"
                margin="dense"
                type="password"
                className={classes.field}
                label={intl.formatMessage({ id: 'LABELS.LOGIN.NEW_PASSWORD' })}
                component={TextFieldAdapter}
                autoComplete="off"
                fullWidth
              />
              <Field
                name="confirm_password"
                margin="dense"
                type="password"
                className={classes.field}
                label={intl.formatMessage({
                  id: 'LABELS.LOGIN.CONFIRM_PASSWORD',
                })}
                component={TextFieldAdapter}
                autoComplete="off"
                fullWidth
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.recoveryButton}
                disabled={invalid}
              >
                <FormattedMessage id="LABELS.COMMONS.SEND" />
              </Button>
            </form>
          </div>
        )}
        {changeForm === 'null' && this.renderLink()}
        {changeForm === 1 && this.renderRecovery()}
      </>
    );
  };

  render() {
    const { logo, classes } = this.props;

    return (
      <LoginContainer>
        <div className={classes.container}>
          <img className={classes.logo} src={logo} alt="logo" />
          <Form
            onSubmit={this.handleRecovery}
            validate={this.validateForm}
            render={this.renderForm}
          />
        </div>
      </LoginContainer>
    );
  }
}

const mapStateToProps = ({
  app: {
    settings: { logo },
  },
}) => ({ logo });

const mapDispatchToProps = {
  resetPassword: authActions.resetPassword,
  recoveryPassword: authActions.recoveryPassword,
};

export default compose(
  withRouter,
  injectIntl,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ResetPassword);
