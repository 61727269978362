import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import { VIEWS } from 'commons/constants';

import { fileActions } from 'commons/store/file';

/* selectors */
const treeSelector = ({ file: { tree } }) => tree.get('root') || {};

export const selectors = {
  treeSelector,
};

/* state */
const movingState = {
  source: {},
  parent: {},
  folders: [],
};

/* handler */
const initialState = {
  tree: Immutable.Map(),
  moving: movingState,
  expandedTreeNodes: Immutable.Set(),
  editing: {},
  drawer: { open: false, title: '', component: null },
  fileDetails: {},
};

const deleteChildren = (node, _expandedTreeNodes) => {
  if (!node.children.length) {
    return (_expandedTreeNodes = _expandedTreeNodes.delete(node.id));
  }

  if (node.children.length) {
    node.children.forEach(child => {
      _expandedTreeNodes = deleteChildren(child, _expandedTreeNodes);
    });
    return _expandedTreeNodes;
  }
};

const orderByLeafAndName = (a, b) => {
  if (a.metadata.public || b.metadata.public) {
    return 1;
  }

  if (!a.data && b.data) {
    return -1;
  }

  if (a.data && !b.data) {
    return 1;
  }

  if (a.name || b.name) {
    return a.name.localeCompare(b.name);
  }

  return 0;
};

export default handleActions(
  {
    [fileActions.updateTreeNode]: (state, { payload: nodes }) => {
      let { tree } = state;

      tree = nodes.reduce((tree, node) => {
        node.children = node.children || [];
        tree = tree.set(node.id, node);

        if (tree.has(node.parent)) {
          const parent = tree.get(node.parent);

          parent.children = parent.children.map(item =>
            item.id === node.id ? node : item
          );

          if (
            !tree.has(node.id) ||
            !parent.children.some(item => item.id === node.id)
          ) {
            parent.children.push(node);
            parent.children.sort(orderByLeafAndName);
          }

          parent.contracted = false;
          return tree;
        }

        return tree.set(node.parent, { id: node.parent, children: [node] });
      }, tree);

      tree = tree.set('root', { ...tree.get('root') });

      return { ...state, tree };
    },

    [fileActions.setNodeAsDestiny]: ({ moving, ...restState }, { payload }) => {
      const { source } = moving;
      const _source = !!source && source.id === payload.id ? {} : payload;

      return {
        ...restState,
        moving: { ...moving, source: _source },
      };
    },

    [fileActions.addNodeFoldersToList]: (
      { moving, ...restState },
      { payload }
    ) => ({
      ...restState,
      editing: { view: VIEWS.MOVING },
      moving: { ...moving, ...payload },
    }),

    [fileActions.expandNode]: (state, { payload }) => {
      const { expandedTreeNodes } = state;
      let _expandedTreeNodes = Immutable.Set(expandedTreeNodes);

      return {
        ...state,
        expandedTreeNodes: _expandedTreeNodes.add(payload),
      };
    },

    [fileActions.deleteLocalNode]: (state, { payload: nodeId }) => {
      const { expandedTreeNodes } = state;
      let _expandedTreeNodes = Immutable.Set(expandedTreeNodes);

      let { tree } = state;

      let node = tree.get(nodeId);

      tree = tree.delete(nodeId);

      let parent = tree.get(node.parent);

      _expandedTreeNodes = deleteChildren(node, _expandedTreeNodes);

      parent.children = parent.children.filter(child => child.id !== nodeId);
      parent.childrenLength = parent.children.length;

      if (!parent.children.length) {
        _expandedTreeNodes = expandedTreeNodes.delete(parent.id);
      }

      tree = tree.set('root', { ...tree.get('root') });

      return {
        ...state,
        tree,
        expandedTreeNodes: _expandedTreeNodes,
      };
    },

    [fileActions.fileDetails]: (state, { payload = {} }) => ({
      ...state,
      editing: payload,
      moving: movingState,
    }),

    [fileActions.updateNodeContractedState]: (state, { payload }) => {
      const { expandedTreeNodes } = state;
      let _expandedTreeNodes = Immutable.Set(expandedTreeNodes);

      _expandedTreeNodes = expandedTreeNodes.has(payload)
        ? expandedTreeNodes.delete(payload)
        : expandedTreeNodes.add(payload);

      return {
        ...state,
        expandedTreeNodes: _expandedTreeNodes,
      };
    },

    [fileActions.clearFilesTree]: () => initialState,

    [fileActions.updateFileDetails]: (state, { payload }) => ({
      ...state,
      fileDetails: payload,
    }),
  },
  initialState
);
