export default ({ palette }) => ({
  container: {
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  refreshBtn: {
    color: palette.primary.main,
  },
  warning: {
    textAlign: 'center',
    margin: '1rem 0',
    color: '#555',
  },
});
