export const REQUIRED = 'REQUIRED';
export const ALT_REQUIRED = 'ALT_REQUIRED';

export function evaluateFieldRules({
  rules = [],
  values = {},
  labels = {
    [REQUIRED]: 'MESSAGES.COMMONS.REQUIRED_FIELD',
    [ALT_REQUIRED]: 'MESSAGES.COMMONS.REQUIRED_FIELD',
  },
}) {
  if (!rules) {
    rules = [];
  }
  if (!values) {
    values = {};
  }
  return rules
    .flatMap(rule => {
      if (rule.type === REQUIRED) {
        return rule.keys
          .filter(key => !values[key])
          .map(key => ({ key, label: labels[rule.type] }));
      }
      if (rule.type === ALT_REQUIRED) {
        if (rule.keys.every(key => !values[key])) {
          return rule.keys.map(key => ({ key, label: labels[rule.type] }));
        }
      }
    })
    .filter(error => !!error)
    .reduce((acc, error) => {
      if (!acc[error.key]) {
        acc[error.key] = error.label;
      }
      return acc;
    }, {});
}
