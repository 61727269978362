import React from 'react';

import { object, func } from 'prop-types';

import FileDataLoader from 'commons/components/FileDataLoader';
import { makeStyles } from '@material-ui/styles';

import styles from './styles';

const imgHolder = process.env.PUBLIC_URL + '/images/blank_image.png';
const useStyles = makeStyles(styles);

export function ElementFormItem({ element, onSelect }) {
  const classes = useStyles();

  const handleSelect = React.useCallback(() => onSelect(element), [
    element,
    onSelect,
  ]);

  return (
    <div className={classes.container} onClick={handleSelect}>
      <FileDataLoader id={element.image}>
        {({ fileData, loading }) => (
          <div
            className={classes.image}
            style={{
              backgroundImage: `url(${fileData?.thumbnail || imgHolder})`,
            }}
          />
        )}
      </FileDataLoader>

      <div className={classes.name}>{element.name}</div>
    </div>
  );
}

ElementFormItem.propTypes = {
  /* own props */
  element: object.isRequired,
  onSelect: func.isRequired,
};

export default React.memo(ElementFormItem);
