export default ({ palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '22rem',
    backgroundColor: '#fff',
    padding: '0rem 2rem 4rem',
    borderRadius: '.25rem',
    alignItems: 'center',
    border: '1px solid #d8dde6',
    height: '35rem',
  },
  form: {
    '& > input': {
      height: 30,
      paddingLeft: 8,
      marginBottom: 4,
      width: '100%',
    },
  },
  field: {
    marginBottom: '8px !important',
  },
  logo: {
    width: '16rem',
    marginBottom: '1rem',
  },
  recoveryButton: {
    marginTop: '1rem',
    width: '100%',
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
  },
  forgotPassword: {
    cursor: 'pointer',
    color: palette.primary.main,
    fontSize: '.9rem',
    margin: '2rem 0 0',
    alignSelf: 'flex-end',
  },
});
