export default ({ palette }) => ({
  icon: {
    marginRight: 0,
    '&.active': {
      color: palette.primary.main,
    },
    '&.error': {
      color: '#f44336',
    },
  },
});
