import { getFormattedMessage } from 'commons/locale';
import { evaluateFieldRules } from './fieldRulesValidator';

const email = value => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

const activity = {
  userCanView({ author, responsible, members }, user) {
    return [author.id, responsible.id, ...members].includes(user.id);
  },
  hasStarted({ initialDate }) {
    return initialDate => new Date() >= new Date(initialDate);
  },
  userCanEdit({ author, status, initialDate }, user) {
    return (
      author.id === user.id &&
      status !== 'FINISHED' &&
      !this.hasStarted(initialDate)
    );
  },
  userCanPerformActions(activity, user) {
    return this.userCanView(activity, user) && activity.status !== 'FINISHED';
  },
};

const isInvalid = value => {
  if (value && value.constructor === Object && !Object.keys(value).length) {
    return false;
  }

  return !value;
};

const requiredFields = ({
  fields,
  values,
  errorLabel = 'MESSAGES.COMMONS.REQUIRED_FIELD',
}) => {
  return fields.reduce((acc, field) => {
    isInvalid(values[field]) && (acc[field] = getFormattedMessage(errorLabel));
    return acc;
  }, {});
};

const notEmptyFields = ({
  fields,
  values,
  errorLabel = 'MESSAGES.COMMONS.REQUIRED_FIELD',
}) =>
  fields.reduce((acc, field) => {
    !(!!values[field] && values[field].length > 0) &&
      (acc[field] = getFormattedMessage(errorLabel));
    return acc;
  }, {});

export default {
  email,
  activity,
  requiredFields,
  notEmptyFields,
  evaluateFieldRules,
};
