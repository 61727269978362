export default {
  container: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  content: {
    zIndex: 9,
    minWidth: '42rem',
    backgroundColor: 'rgba(244, 246, 249, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media only screen and (max-width: 1145px)': {
      width: '100%',
      minWidth: 'auto',
    },
  },
  headings: {
    zIndex: 2,
    padding: '5rem',
    color: '#fff',
    '@media only screen and (max-width: 1145px)': {
      display: 'none',
    },
  },
  title: {
    fontWeight: '900',
    margin: '3rem 0 3rem',
    textShadow: '0px 0px 8px #000',
    '@media only screen and (max-width: 1400px)': {
      fontSize: '2rem',
    },
  },
  subtitle: {
    textShadow: '0px 0px 8px #000',
    '@media only screen and (max-width: 1400px)': {
      fontSize: '2rem',
    },
  },
};
