//pesquisa de satisfacao
/* eslint-disable no-undef */
function pesquisa(user, npsKey) {
  if (
    !user ||
    Object.keys(user).length === 0 ||
    document.getElementById('trackwidget-js')
  ) {
    return;
  }
  var a = {
    key: npsKey,
    customer: {
      name: user.nmusuario,
      email: user.email,
      phone: '',
      Identificador: user.username,
    },
  };
  var e;
  var c = document.getElementsByTagName('script')[0];
  e = document.createElement('script');
  e.id = 'trackwidget-js';
  e.src = 'https://app.track.co/widget.min.js';
  e.type = 'text/javascript';
  e.async = true;
  e.onload = e.onreadystatechange = function() {
    var h = this.readyState;
    if (h && h !== 'complete' && h !== 'loaded') {
      return;
    }
    try {
      var c = new TrackWidget();
      c.createWidget(a);
    } catch (i) {}
  };
  c.parentNode.insertBefore(e, c);
}

export default pesquisa;
