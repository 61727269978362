export default ({ palette, menu, active }) => ({
  container: {
    display: 'flex',
    minHeight: 52,
  },
  collapseList: {
    width: '100% !important',
  },
  collapseItem: {
    padding: '8px 8px 8px 0 !important',
    maxHeight: '3rem',
    zIndex: 10,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
  },
  collapsibleText: {
    color: menu.text.color,
    fontSize: '.75rem !important',
    paddingLeft: '1rem',
    textTransform: 'uppercase',
  },
  nestedCollapseList: {
    marginTop: '.25rem',
  },
  iconItem: {
    margin: 0,
  },
  iconContainer: {
    margin: '0 !important',
    height: '3.2rem',
    width: 52,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.active': {
      backgroundColor: '#fff',
    },
  },
  icon: {
    color: menu.text.color,
    '&.active': {
      color: active,
    },
  },
});
