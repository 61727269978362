import React from 'react';
import { object, number, array } from 'prop-types';

import cx from 'clsx';

import { ToastContainer } from 'react-toastify';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';

import auth from 'commons/auth';
import routes from 'commons/routes';
import PrivateRoute from 'commons/components/PrivateRoute';
import GlobalDrawer from 'commons/components/GlobalDrawer';
import { AppContext } from 'commons/contexts';

import UploadsDrawer from './UploadsDrawer';
import ResetPassword from './ResetPassword';
import Menu from './Menu';
import Header from './Header';
import Login from './Login';
import GuestLogin from './GuestLogin';
import ImplicitGrantLogin from './ImplicitGrantLogin';

import AppInitializer from './AppInitializer';
import ActivityDrawer from './Activities/ActivityDrawer';

import styles from './styles';

import 'react-dnd-ui-tree/dist/react-dnd-ui-tree.css';
import 'react-toastify/dist/ReactToastify.css';
import pesquisa from './NPS/pesquisa';

export class App extends React.PureComponent {
  static propTypes = {
    /* state app */
    settings: object,
    modules: array,
    user: object,
    loading: number,
    /* react-router */
    location: object.isRequired,
    history: object.isRequired,
    /* material-ui styles */
    classes: object.isRequired,
  };

  state = {
    uploads: 0,
    expanded: false,
  };

  componentDidMount() {
    this.shouldGoToLogin() && this.props.history.push(routes.urls.LOGIN);
    this.screenOrientation();
  }

  screenOrientation = () => {
    let orientation =
      (window.screen.orientation || {}).type ||
      window.screen.mozOrientation ||
      window.screen.msOrientation;
    let orientationIOS = window.matchMedia('(orientation:portrait)').matches;
    if (
      window.screen.width < 1024 &&
      window.screen.height < 768 &&
      (orientation === 'portrait-secondary' ||
        orientation === 'portrait-primary' ||
        orientationIOS)
    ) {
      alert(
        'Caso esteja utilizando um dispositivo móvel, recomendamos que o utilize na horizontal.'
      );
    }
  };

  shouldGoToLogin = () => {
    const {
      location: { pathname },
    } = this.props;
    const {
      urls: { WRONG, RESET_PASSWORD, GUEST, IMPLICIT_GRANT },
    } = routes;

    if ([GUEST, IMPLICIT_GRANT].some(url => pathname.includes(url))) {
      return false;
    }

    const isUnsafeNotLodingUrl = [WRONG, RESET_PASSWORD].some(url =>
      pathname.includes(url)
    );

    return !this.shouldRenderMenuAndHeader() && !isUnsafeNotLodingUrl;
  };

  pesquisaSatisfacao = () => {
    const { user, settings } = this.props;
    const npsKey = settings.nps;
    if (npsKey && npsKey !== '') {
      pesquisa(user, npsKey);
    }
  };

  isUniqueApplication = () => {
    const { modules = [] } = this.props;
    const applications = (modules[0] || {}).applications || [];
    return modules.length === 1 && applications.length === 1;
  };

  shouldRenderMenuAndHeader = () => {
    const {
      location: { pathname },
    } = this.props;
    const {
      urls: { LOGIN, WRONG, RESET_PASSWORD },
    } = routes;

    const isSafeUrl = [LOGIN, WRONG, RESET_PASSWORD].some(url =>
      pathname.includes(url)
    );

    return !!auth.getToken() && !isSafeUrl;
  };

  handleMenuOpen = () => this.setState({ expanded: true });

  handleMenuToggle = () =>
    this.setState(({ expanded }) => ({ expanded: !expanded }));

  renderAppContainer = () => {
    const { classes, settings, user, modules, application } = this.props;
    const { expanded, uploads } = this.state;
    return (
      <>
        <AppContext.Provider
          value={{
            expanded,
            securitySubject: application.securityKey,
            openMenu: this.handleMenuOpen,
          }}
        >
          <ActivityDrawer />
          <GlobalDrawer />
          <ToastContainer
            className="toast-container"
            position="top-right"
            autoClose={5000}
            closeButton={false}
            draggable={false}
            rtl={false}
            newestOnTop
            closeOnClick
            pauseOnVisibilityChange
            hideProgressBar
            pauseOnHover
          />

          {this.shouldRenderMenuAndHeader() && (
            <>
              <UploadsDrawer />
              <Header
                user={user}
                uploads={uploads}
                logo={settings.white_logo}
                unique={this.isUniqueApplication()}
              />
              {!this.isUniqueApplication() && (
                <Menu onToggle={this.handleMenuToggle} />
              )}
            </>
          )}
          <div
            className={cx(classes.content, {
              unique: this.isUniqueApplication(),
              expanded,
            })}
          >
            <Switch>
              {routes.builder(modules).map((route, i) => (
                <PrivateRoute key={i} exact {...route} />
              ))}
            </Switch>
          </div>
          <Route exact path={routes.urls.LOGIN} component={Login} />
          <Route exact path={routes.urls.GUEST} component={GuestLogin} />
          <Route
            exact
            path={routes.urls.IMPLICIT_GRANT}
            component={ImplicitGrantLogin}
          />
          <Route
            exact
            path={`${routes.urls.RESET_PASSWORD}/:id`}
            component={ResetPassword}
          />
        </AppContext.Provider>
      </>
    );
  };

  render() {
    const { modules, application, settings, loading, location } = this.props;
    this.pesquisaSatisfacao();
    return (
      <AppInitializer
        modules={modules}
        application={application}
        settings={settings}
        loading={loading}
        location={location}
      >
        {this.renderAppContainer()}
      </AppInitializer>
    );
  }
}

const mapStateToProps = ({
  app: { modules, application, loading, settings },
  auth: { user },
}) => ({
  modules,
  application,
  settings,
  loading,
  user,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(App);
