import React from 'react';
import { func, bool, object } from 'prop-types';

import { Form, Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

import validate from 'commons/validate';
import { TextFieldAdapter } from 'commons/form';

import styles from './styles';

const useStyles = makeStyles(styles);

export function RecoveryForm({ intl, canRender, onRecovery }) {
  const classes = useStyles();

  const validateForm = values => {
    const errors = {};

    !validate.email(values.username) &&
      (errors.username = intl.formatMessage({
        id: 'MESSAGES.LOGIN.INVALID_EMAIL',
      }));

    !values.username &&
      (errors.username = intl.formatMessage({
        id: 'MESSAGES.COMMONS.REQUIRED_FIELD',
      }));

    return errors;
  };

  const renderRecoveryForm = ({ handleSubmit, values }) => (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Field
        name="username"
        margin="dense"
        type="email"
        className={classes.field}
        placeholder={intl.formatMessage({ id: 'LABELS.COMMONS.EMAIL' })}
        component={TextFieldAdapter}
        autoComplete="off"
        fullWidth
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        className={classes.sendButton}
        disabled={!validate.email(values.username)}
      >
        <FormattedMessage id="LABELS.COMMONS.SEND" />
      </Button>
    </form>
  );

  return (
    <div className={classes.container}>
      <div className={classes.recoveryTitle}>
        <FormattedMessage id="LABELS.LOGIN.PASSWORD_RECOVERING" />
      </div>
      <div className={classes.recoveryMessage}>
        <FormattedMessage id="MESSAGES.LOGIN.RECOVERY_MESSAGE" />
      </div>
      {canRender && (
        <Form
          onSubmit={onRecovery}
          validate={validateForm}
          render={renderRecoveryForm}
        />
      )}
    </div>
  );
}

RecoveryForm.propTypes = {
  /* actions */
  canRender: bool.isRequired,
  onRecovery: func.isRequired,
  /* react-intl */
  intl: object.isRequired,
};

export default compose(
  injectIntl,
  React.memo
)(RecoveryForm);
