import axios from 'axios';

export default {
  getThemes: clientId => axios.get('/themes', { params: { clientId } }),
  saveElementType: (themeId, payload) =>
    axios.put(`/themes/${themeId}/types/${payload.id}`, payload),
  createElementType: (themeId, payload) =>
    axios.post(`/themes/${themeId}/types`, payload),
  saveCategory: (themeId, payload) =>
    axios.put(`/themes/${themeId}/categories/${payload.id}`, payload),
  createCategory: (themeId, payload) =>
    axios.post(`/themes/${themeId}/categories`, payload),
};
