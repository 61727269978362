import { takeEvery } from 'redux-saga/effects';

import api from 'commons/api';
import notification from 'commons/notification';
import { serviceService } from 'commons/services';
import { serviceActions } from 'commons/store/service';

function* getServices() {
  yield* api(
    serviceService.getServices,
    {},
    serviceActions.updateServices,
    err => console.log(err),
    { multiple: false }
  );
}

function* getService({ payload }) {
  yield* api(
    serviceService.getService,
    payload,
    serviceActions.updateService,
    err => console.log(err),
    { multiple: false }
  );
}

function* saveService({ payload }) {
  yield* api(
    serviceService.saveService,
    payload.createService,
    function*() {
      !!payload.callback && payload.callback();
      yield notification('MESSAGES.SERVICE.SERVICE_SAVED', { type: 'success' });
    },
    function*(err) {
      yield notification('MESSAGES.SERVICE.COULD_NOT_SAVE_SERVICE', {
        type: 'error',
      });
    }
  );
}

function* updateService({ payload }) {
  console.log(payload);

  yield* api(
    serviceService.updateService,
    payload.createService,
    function*() {
      !!payload.callback && payload.callback();
      yield notification('MESSAGES.SERVICE.SERVICE_SAVED', { type: 'success' });
    },
    function*(err) {
      yield notification('MESSAGES.SERVICE.COULD_NOT_SAVE_SERVICE', {
        type: 'error',
      });
    }
  );
}

function* getPaymentMethods() {
  yield* api(
    serviceService.getPaymentMethods,
    {},
    serviceActions.updatePaymentMethods,
    err => console.log(err),
    { multiple: false }
  );
}

function* getDynamicForms({ payload }) {
  yield* api(
    serviceService.getDynamicForms,
    payload,
    serviceActions.updateDynamicForms,
    err => console.log(err),
    { multiple: false }
  );
}

export default function*() {
  yield takeEvery(serviceActions.getServices.toString(), getServices);
  yield takeEvery(
    serviceActions.getPaymentMethods.toString(),
    getPaymentMethods
  );
  yield takeEvery(serviceActions.getDynamicForms.toString(), getDynamicForms);
  yield takeEvery(serviceActions.saveService.toString(), saveService);
  yield takeEvery(serviceActions.getService.toString(), getService);
  yield takeEvery(serviceActions.updateService.toString(), updateService);
}
