import { createAction } from 'redux-actions';

export default {
  openActivityDrawer: createAction('activity/OPEN_ACTIVITY_DRAWER'),
  closeActivity: createAction('activity/CLOSE_ACTIVITY'),

  openActivityDetails: createAction('activity/OPEN_ACTIVITY_DETAILS'),

  getActivityThemesByClient: createAction(
    'activity/GET_ACTIVITY_THEMES_BY_CLIENT'
  ),
  getActivityUsersByClient: createAction(
    'activity/GET_ACTIVITY_USERS_BY_CLIENT_REQUEST'
  ),
  getActivityMapsByClient: createAction(
    'activity/GET_ACTIVITY_MAPS_BY_CLIENT_REQUEST'
  ),

  updateActivityEditingUsers: createAction(
    'activity/GET_ACTIVITY_USERS_BY_CLIENT_SUCCESS'
  ),
  updateActivityEditingMaps: createAction(
    'activity/GET_ACTIVITY_MAPS_BY_CLIENT_SUCCESS'
  ),
  updateActivityEditingThemes: createAction(
    'activity/GET_ACTIVITY_THEMES_BY_CLIENT_SUCESS'
  ),

  filterElements: createAction('activity/FILTER_ELEMENTS_REQUEST'),
  updateElementsBuffer: createAction('activity/FILTER_ELEMENTS_SUCCESS'),

  addElementsToActivity: createAction('activity/ADD_ELEMENTS_TO_ACTIVIY'),
  removeElementsFromActivity: createAction(
    'activity/REMOVE_ELEMENTS_FROM_ACTIVIY'
  ),

  getEditingActivity: createAction('activity/GET_EDITING_ACTIVITY_REQUEST'),
  updateEditingActivity: createAction('activity/UPDATE_EDITING_ACTIVITY'),
  saveActivity: createAction('activity/SAVE_ACTIVITY_REQUEST'),
  updateActivityInList: createAction('activity/UPDATE_ACTIVITY_SUCCESS'),
  addActivityToList: createAction('activity/SAVE_ACTIVITY_SUCCESS'),
  clearActivities: createAction('activity/CLEAR_ACTIVITIES'),

  getActivityForms: createAction('activity/GET_ACTIVITY_FORMS_REQUEST'),
  updateActivityEditingForms: createAction(
    'activity/GET_ACTIVITY_FORMS_SUCCESS'
  ),

  getActivityThemesList: createAction(
    'activity/GET_ACTIVITY_THEMES_LIST_REQUEST'
  ),

  getActivityMapsList: createAction('activity/GET_ACTIVITY_MAPS_LIST_REQUEST'),
  getActivityUsersList: createAction(
    'activity/GET_ACTIVITY_USERS_LIST_REQUEST'
  ),
  updateInActivityLists: createAction('activity/UPDATE_IN_ACTIVITY_LISTS'),

  filterActivities: createAction('activity/FILTER_ACTIVITIES'),
  updateActivities: createAction('activity/GET_ACTIVITIES_SUCCESS'),

  updateActivityMembers: createAction(
    'activity/UPDATE_ACTIVITY_MEMBERS_REQUEST'
  ),

  toggleActivityFavorite: createAction(
    'activity/TOGGLE_ACTIVITY_FAVORITE_REQUEST'
  ),

  clearActivityElements: createAction('activity/CLEAR_ACTIVITY_ELEMENTS'),

  deleteActivity: createAction('activity/DELETE_ACTIVITY_REQUEST'),
  deleteActivityFromList: createAction('activity/DELETE_ACTIVITY_SUCCESS'),

  getActivityElementForms: createAction(
    'activity/GET_ACTIVITY_FORM_VALUES_REQUEST'
  ),
  updateActivityElementForms: createAction(
    'activity/GET_ACTIVITY_FORM_VALUES_SUCCESS'
  ),
  saveActivityFormValues: createAction(
    'activity/SAVE_ACTIVITY_FORM_VALUES_REQUEST'
  ),
  updateActivityFormValues: createAction(
    'activity/SAVE_ACTIVITY_FORM_VALUES_SUCCESS'
  ),

  saveActivityMessage: createAction('activity/SAVE_ACTIVITY_MESSAGE_REQUEST'),
  updateActivityMessages: createAction(
    'activity/SAVE_ACTIVITY_MESSAGE_SUCCESS'
  ),

  exportActivity: createAction('activity/EXPORT_ACTIVITY'),
  exportActivitySuccess: createAction('activity/EXPORT_ACTIVITY_SUCCESS'),
};
