import axios from 'axios';

export default {
  getProfiles: () => axios.get('/profiles'),

  getProfile: id => axios.get(`/profiles/${id}`),

  getAllUsers: client => axios.get('/users/all', { params: { client } }),

  getActiveUsers: () => axios.get('/users'),

  getUser: id => axios.get(`/users/${id}`),

  getUsersByProfile: profile =>
    axios.get(`/permissions/profiles/${profile}/users`),

  getProfilesByUser: user => axios.get(`/permissions/user/${user}/profiles`),

  getUsersByFilter: ({ map, client }) =>
    axios.get(`/maps/${map.id}/users`, { params: { client } }),

  setUserProfiles: ({ userId, profiles }) =>
    axios.put(`/users/${userId}/set`, profiles),

  getUsersByMaps: maps => axios.post(`maps/users`, maps),

  saveProfile: data => axios.post('/profiles', data),

  deleteUser: ({ userId }) => axios.delete(`/users/${userId}`),

  saveUser: data => axios.post('/users', data),

  toggleMapActiveLayer: ({ mapId, nodeId }) =>
    axios.put(`/users/me/preferences/map/${mapId}/node/${nodeId}/toggle`),

  updateLayerMapOpacity: ({ nodeId, opacity }) =>
    axios.put(`/users/me/preferences/node/${nodeId}/opacity/${opacity}`),

  toggleClients: () => axios.put('/users/me/preferences/toggle-clients'),

  toggleMaps: () => axios.put('/users/me/preferences/toggle-maps'),

  toggleMapsOrder: () => axios.put('/users/me/preferences/toggle-maps-order'),

  getUserPermissions: userId => axios.get(`/users/${userId}/permissions`),

  addUserProfilePermission: ({ username, profileId }) =>
    axios.put(`/users/${username}/add/${profileId}`),

  removeUserProfilePermission: ({ username, profileId }) =>
    axios.put(`/users/${username}/remove/${profileId}`),

  getAvailablePermissions: () => axios.get('/permissions'),

  updateMapAccess: mapId => axios.put(`/users/me/preferences/map/${mapId}`),
};
