import { createAction } from 'redux-actions';

export default {
  getClients: createAction('client/GET_CLIENTS'),
  getActiveClients: createAction('client/GET_ACTIVE_CLIENTS'),
  updateClients: createAction('client/GET_CLIENTS_SUCCESS'),
  getNewClient: createAction('client/GET_NEW_CLIENT'),
  getClientDetails: createAction('client/GET_CLIENT_DETAILS'),
  saveClient: createAction('client/SAVE_CLIENT'),
  saveClientAsCurrent: createAction('client/SAVE_CLIENT_AS_CURRENT'),
  deleteClients: createAction('client/DELETE_CLIENTS'),
  deleteClient: createAction('client/DELETE_CLIENT'),
  deleteCurrentClient: createAction('client/DELETE_CURRENT_CLIENT'),
};
