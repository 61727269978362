import React from 'react';
import { object, bool, func } from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

export function UserItem({ user, active, onToggle }) {
  const handleUserToggle = React.useCallback(() => onToggle(user.id), [
    user,
    onToggle,
  ]);

  return (
    <ListItem key={user.id} dense button onClick={handleUserToggle}>
      <ListItemIcon>
        <Checkbox edge="start" checked={active} disableRipple />
      </ListItemIcon>
      <ListItemText id={user.id} primary={user.name} />
    </ListItem>
  );
}

UserItem.propTypes = {
  /* own props */
  user: object.isRequired,
  active: bool.isRequired,
  onToggle: func.isRequired,
};

export default UserItem;
