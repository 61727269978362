import React from 'react';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { create } from 'jss';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import { StylesProvider, jssPreset } from '@material-ui/styles';

import 'typeface-roboto';

import 'moment/locale/pt-br';

moment.locale('pt-br');

const jss = create({
  plugins: jssPreset().plugins,
});

export function BunchThemeProvider({ theme, children }) {
  useStyles();
  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          locale="pt-br"
          moment={moment}
        >
          {children}
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  '@global': {
    // Firefox
    'input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    // Chrome, Safari, Edge, Opera
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      margin: 0,
      '-webkit-appearance': 'none',
    },
    // Chrome Scroll width
    '::-webkit-scrollbar': {
      width: spacing(1),
    },
    // Chrome Scroll Track
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    // Chrome Scroll Handle
    '::-webkit-scrollbar-thumb': {
      background: palette.text.disabled,
    },
    // Chrome Scroll Handle on hover
    '::-webkit-scrollbar-thumb:hover': {
      background: palette.text.secondary,
    },
    // Firefox Scroll
    '*': {
      scrollbarWidth: 'thin',
    },
  },
}));
