import axios from 'axios';

import delay from '@redux-saga/delay-p';
import { call, put } from 'redux-saga/effects';

import { appActions } from 'commons/store/app';

export const baseURL = (axios.defaults.baseURL = '/bunch-backend/api/v1');

export const getFormEnvironment = headers => ({
  upload: {
    url: `${baseURL}/files/upload`,
    config: {
      method: 'POST',
      credentials: 'same-origin',
      headers,
    },
  },
  headers,
  customProps: { 'file-multiple': [] },
  disableOnLoadIntegration: true,
});

export default function*(fn, parameter, success, failure, settings = {}) {
  const _settings = {
    multiple: true,
    entireResponse: false,
    loading: true,
    delay: 500,
    ...settings,
  };

  try {
    if (_settings.loading) {
      yield put(appActions.showGlobalLoading());
    }
    const response = yield call(fn, parameter);
    const _response = response || {};
    const data = _settings.entireResponse ? _response : _response.data;

    yield delay(delay);

    if (_settings.loading) {
      yield put(appActions.hideGlobalLoading());
    }

    yield _settings.multiple ? call(success, data) : put(success(data));
  } catch (error) {
    if (_settings.loading) {
      yield put(appActions.hideGlobalLoading());
    }
    yield call(failure, error);
    console.error(error);
  }
}
