export default ({ menu }) => ({
  avatar: {
    margin: '0 .5rem 0 1rem',
  },
  popoverContent: {
    backgroundColor: menu.background,
  },
  user: {
    padding: '.5rem 1rem',
    borderBottom: '1px solid #313131',
  },
  userText: {
    color: '#fff',
  },
  listItemText: {
    color: '#fff',
    fontSize: '.9rem',
  },
  listItemIcon: {
    color: '#fff',
  },
  langSelector: {
    color: '#fff',
    padding: '.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flag: {
    width: '2rem',
    cursor: 'pointer',
    opacity: 0.5,
    '&:hover, &.active': {
      opacity: 1,
    },
  },
  label: {
    fontSize: '.9rem',
  },
});
