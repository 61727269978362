import { createAction } from 'redux-actions';

export default {
  restoreState: createAction('mapActivity/RESTORE_STATE'),
  clearMapActivities: createAction('mapActivity/CLEAR_MAP_ACTIVITIES'),

  filterMapActivities: createAction(
    'mapActivity/FILTER_MAP_ACTIVITIES_REQUEST'
  ),
  updateMapActivities: createAction(
    'mapActivity/FILTER_MAP_ACTIVITIES_SUCCESS'
  ),
  updateActivityInList: createAction('mapActivity/UPDATE_ACTIVITY_SUCCESS'),
  addActivityToList: createAction('mapActivity/SAVE_ACTIVITY_SUCCESS'),
  deleteActivityFromList: createAction('mapActivity/DELETE_ACTIVITY_SUCCESS'),
};
