import produce from 'immer';
import { handleActions } from 'redux-actions';

import { clientActions } from 'commons/store/client';

const clientState = {
  initials: '',
  name: '',
  observation: '',
  areaType: '',
  active: false,
};

const initialState = {
  clients: [],
  editing: false,
  client: clientState,
};

export default handleActions(
  {
    [clientActions.deleteClients]: () => initialState,

    [clientActions.updateClients]: produce((draft, { payload }) => {
      draft.clients = payload;
    }),

    [clientActions.saveClientAsCurrent]: produce((draft, { payload }) => {
      draft.client = payload;
      draft.editing = true;
    }),

    [clientActions.deleteClient]: produce(draft => {
      draft.client = clientState;
      draft.editing = false;
    }),

    [clientActions.getNewClient]: produce(draft => {
      draft.editing = true;
    }),

    [clientActions.deleteCurrentClient]: produce(draft => {
      draft.client = clientState;
      draft.editing = false;
    }),
  },
  initialState
);
