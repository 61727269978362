export default ({ header, menu }) => ({
  container: {
    position: 'absolute',
    height: '3.5rem',
    zIndex: 120,
    left: 52,
    right: 0,
    padding: '0 .5rem',
    backgroundColor: header.primary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
    transition: 'left 225ms cubic-bezier(.25, .1, .25, .6) 0ms',
    '&.expanded': {
      left: 248,
    },
    '&.unique': {
      left: 0,
    },
  },
  logo: {
    height: 36,
    cursor: 'pointer',
    margin: '.5rem 1rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    marginLeft: '.75rem',
    paddingLeft: '.5rem',
    alignItems: 'center',
    color: '#fff',
    cursor: 'pointer',
    borderLeft: `1px solid ${menu.nestedItem.color}`,
    '&:hover': {
      opacity: '.9',
    },
    '&:first-child': {
      border: 'none',
    },
    '& > svg': {
      marginRight: '.1rem',
    },
  },
  button: {
    color: '#f1f1f1',
  },
  buttonText: {
    marginLeft: '.5rem',
    fontSize: '.9rem',
  },
});
