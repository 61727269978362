import React from 'react';
import { array, object } from 'prop-types';

import LinearProgress from '@material-ui/core/LinearProgress';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MESSAGES } from 'commons/constants';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { FILE_UPLOAD_STATUS } from 'commons/constants';

import styles from './styles';

const useStyles = makeStyles(styles);

export function FilesProgress({ intl, files, fileToProcess }) {
  if (fileToProcess) {
    files = fileToProcess;
  }

  const classes = useStyles();

  const getProgressLabel = (progress = 0) => {
    const processingLabel = intl.formatMessage({
      id: 'LABELS.COMMONS.PROCESSING',
    });
    return progress === 100 ? processingLabel : `${progress} %`;
  };

  const getProgressType = ({ status, progress }) => {
    if (status || progress < 100) {
      return 'determinate';
    }
    return 'indeterminate';
  };

  const renderWarning = () => (
    <div className={classes.warning}>
      <FormattedMessage id="MESSAGES.FILES.NO_FILES_TO_DISPLAY" />
    </div>
  );

  const renderList = () => (
    <>
      {files.map(file => (
        <div key={file.id} className={classes.file}>
          <div>{file.name}</div>
          <div className={classes.progressBarStatus}>
            <LinearProgress
              color={'primary'}
              variant={getProgressType(file)}
              value={file.progress || (file.status ? 100 : 0)}
              classes={{
                bar1Determinate:
                  file.status === FILE_UPLOAD_STATUS.ERROR
                    ? classes.barColorFailed
                    : file.status === FILE_UPLOAD_STATUS.PROCESSERROR
                    ? classes.barColorFailed
                    : classes.barColorSuccess,
              }}
            />
          </div>
          {file.status ? (
            <Typography
              className={
                file.status === FILE_UPLOAD_STATUS.SUCCESS
                  ? classes.statusSuccess
                  : classes.statusFailed
              }
            >
              <FormattedMessage
                id={
                  file.status === FILE_UPLOAD_STATUS.SUCCESS
                    ? 'MESSAGES.COMMONS.SUCCESS'
                    : file.status === FILE_UPLOAD_STATUS.ERROR
                    ? 'MESSAGES.FILES.COULD_NOT_UPLOAD_FILE'
                    : MESSAGES['pt'].COULD_NOT_PROCESS_FILE
                }
              />
            </Typography>
          ) : (
            <Typography className={classes.progressPercentage}>
              {getProgressLabel(file.progress)}
            </Typography>
          )}
        </div>
      ))}
    </>
  );

  return (
    <div className={classes.container}>
      <Typography variant="h6">
        <FormattedMessage id="LABELS.COMMONS.FILES" />
      </Typography>
      {files.length ? renderList() : renderWarning()}
    </div>
  );
}

FilesProgress.propTypes = {
  /* own props */
  files: array.isRequired,
  intl: object.isRequired,
};

export default React.memo(injectIntl(FilesProgress));
