import React from 'react';
import { object, func } from 'prop-types';

import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import UserAvatar from 'commons/components/UserAvatar';

import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Person from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { withStyles } from '@material-ui/styles';

import { authActions } from 'commons/store/auth';
import Can from 'commons/components/Can';
import { PERMISSIONS } from 'commons/constants';

import styles from './styles';

export class UserPopover extends React.PureComponent {
  static propTypes = {
    /* own props*/
    user: object.isRequired,
    onProfile: func.isRequired,
    /* auth actions */
    logOut: func.isRequired,
    /* material-ui styles */
    classes: object.isRequired,
    /* react-router */
    history: object.isRequired,
    /*react-intl*/
    intl: object.isRequired,
  };

  state = {
    open: false,
    anchorEl: null,
  };

  handlePopover = ({ currentTarget }) => {
    this.setState({
      open: true,
      anchorEl: currentTarget,
    });
  };

  handleClose = () => this.setState({ open: false });

  handleLangChange = () => this.setState({ open: false });

  handleLogOut = () => this.props.logOut();

  handleProfile = () => {
    this.handleClose();
    this.props.onProfile();
  };

  /*renderLanguageSelector = () => {
    const {classes, intl} = this.props;

    return (
      <div className={classes.langSelector}>
        <div className={classes.label}>
          <FormattedMessage id="LABELS.COMMONS.LANGUAGE" />
        </div>
        <div className={classes.languages}>
          {['pt', 'en', 'es'].map(lang =>
            <img
              key={lang}
              alt={lang}
              onClick={}
              className={cx(
                classes.flag,
                {active: intl.locale === lang}
              )}
              src={`${process.env.PUBLIC_URL}/images/lang/${lang}.png`}
            />
          )}
        </div>
      </div>
    )
  }*/

  renderActionsList = classes => (
    <List component="div" disablePadding>
      <Can I={PERMISSIONS.EDIT} a={this.props.user.securityKey}>
        <ListItem button divider onClick={this.handleProfile}>
          <ListItemIcon>
            <Person className={classes.listItemIcon} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={classes.listItemText}
            primary={<FormattedMessage id="LABELS.COMMONS.SETTINGS" />}
          />
        </ListItem>
      </Can>
      <ListItem button divider onClick={this.handleLogOut}>
        <ListItemIcon>
          <PowerSettingsNew className={classes.listItemIcon} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          className={classes.listItemText}
          primary={<FormattedMessage id="LABELS.COMMONS.EXIT" />}
        />
      </ListItem>
    </List>
  );

  renderUserInfo = () => {
    const {
      classes,
      user: { name, email },
    } = this.props;

    return (
      <div className={classes.user}>
        <Typography
          color="primary"
          variant="body1"
          classes={{ colorPrimary: classes.userText }}
        >
          {name}
        </Typography>
        <Typography
          color="primary"
          variant="caption"
          classes={{ colorPrimary: classes.userText }}
        >
          {email}
        </Typography>
      </div>
    );
  };

  renderPopover = () => {
    const { classes } = this.props;
    const { open, anchorEl } = this.state;

    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        onClose={this.handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        disableEnforceFocus
      >
        <div className={classes.popoverContent}>
          {this.renderUserInfo()}
          {this.renderActionsList(classes)}
        </div>
      </Popover>
    );
  };

  render() {
    const { user, classes } = this.props;

    return (
      <>
        <UserAvatar
          user={user}
          className={classes.avatar}
          onClick={this.handlePopover}
        />
        {this.renderPopover()}
      </>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default compose(
  withRouter,
  injectIntl,
  withStyles(styles),
  connect(
    mapStateToProps,
    { logOut: authActions.logOut }
  )
)(UserPopover);
