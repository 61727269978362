import React from 'react';
import { func, number, object, bool, string } from 'prop-types';

import cx from 'clsx';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
//import HelpIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import routes from 'commons/routes';
import { AppContext } from 'commons/contexts';
import { appActions } from 'commons/store/app';

import { VIEWS } from 'commons/constants';

import UserPopover from './UserPopover';

import Can from 'commons/components/Can';
import { PERMISSIONS } from 'commons/constants';

import styles from './styles';

export class Header extends React.PureComponent {
  static contextType = AppContext;

  static propTypes = {
    /* own props */
    logo: string.isRequired,
    user: object.isRequired,
    unique: bool.isRequired,
    uploads: number.isRequired,
    /* app state */
    settings: object.isRequired,
    /* app actions */
    clearCurrentApplication: func.isRequired,
    showUploadsDrawer: func.isRequired,
    /* material-ui styles */
    classes: object.isRequired,
    /* react-router */
    history: object.isRequired,
    /* react-intl */
    intl: object.isRequired,
  };

  handleHome = () => {
    this.props.history.push(routes.urls.PANEL);
    this.props.clearCurrentApplication();
  };

  handleProfile = () => {
    this.props.clearCurrentApplication();
    this.props.history.push(routes.urls.SETTINGS);
  };

  handleHelp = () => {
    this.props.history.push(routes.urls.HELP);
  };

  handleFileUploadDrawer = () =>
    this.props.showUploadsDrawer({
      view: VIEWS.UPLOAD_PROGRESS,
    });

  render() {
    const { classes, logo, user, intl, unique } = this.props;
    const { expanded } = this.context;

    if (!this.props.user || !this.props.user.id) {
      return false;
    }

    return (
      <div className={cx(classes.container, { expanded, unique })}>
        <img
          className={classes.logo}
          onClick={this.handleHome}
          src={logo}
          alt="logo"
        />
        <div className={classes.actions}>
          <Can I={PERMISSIONS.EDIT} a={this.props.user.securityKey}>
            <Button
              color="secondary"
              className={classes.button}
              onClick={this.handleFileUploadDrawer}
            >
              <CloudUploadIcon />
              <Typography className={classes.buttonText}>
                {intl.formatMessage({ id: 'LABELS.COMMONS.UPLOADS' })}
              </Typography>
            </Button>
          </Can>
          {/* <Button
            color="secondary"
            className={classes.button}
            onClick={this.handleHelp}
          >
            <HelpIcon />
            <Typography className={classes.buttonText}>
              {intl.formatMessage({ id: 'LABELS.COMMONS.HELP' })}
            </Typography>
          </Button> */}

          <UserPopover user={user} onProfile={this.handleProfile} />
          {/* <UserPopover user={user} onProfile={this.handleProfile} /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { settings }, auth: { user } }) => ({
  settings,
  user,
});

const { clearCurrentApplication, showUploadsDrawer } = appActions;

const mapDispatchToProps = {
  clearCurrentApplication,
  showUploadsDrawer,
};

export default compose(
  withRouter,
  injectIntl,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Header);
