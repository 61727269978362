import produce from 'immer';

import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { appActions } from 'commons/store/app';

/* selectors */
const getAllApplications = modules =>
  modules.reduce(
    (acc, { applications }) => [
      ...acc,
      ...applications.filter(item => item.map),
    ],
    []
  );

const modulesSelector = state => state.app.modules;

const applicationsSelector = createSelector(
  modulesSelector,
  modules =>
    modules.reduce((acc, { applications }) => [...acc, ...applications], [])
);

const mapAplicationsSelector = createSelector(
  applicationsSelector,
  applications => applications.filter(item => item.map)
);

const applicationByIdSelector = createSelector(
  applicationsSelector,
  (_, applicationId) => applicationId,
  (applications, applicationId) =>
    applications.find(app => app.id === applicationId)
);

const moduleByApplicationId = createSelector(
  modulesSelector,
  (_, applicationId) => applicationId,
  (modules, applicationId) =>
    modules.find(module =>
      module.applications.some(app => app.id === applicationId)
    )
);

const removeExternalModulesSelector = createSelector(
  modulesSelector,
  modules => modules.filter(item => !item.external)
);

export const selectors = {
  getAllApplications,
  modulesSelector,
  applicationsSelector,
  mapAplicationsSelector,
  applicationByIdSelector,
  removeExternalModulesSelector,
  moduleByApplicationId,
};

/* states */
const settings = () => ({
  locales: {},
  title: '',
  logo: '',
  language: '',
  theme: {},
});

const getModulesState = () => {
  const modules = JSON.parse(sessionStorage.getItem('__modules'));
  return (
    modules || {
      module: {},
      application: {},
    }
  );
};

const initialState = {
  loading: 0,
  settings: settings(),
  modules: [],
  usedUploadLimit: 0,
  uploads: { open: false, view: '', node: null },
  drawer: { open: false, title: '', component: null },
  ...getModulesState(),
};

/* handler */
export default handleActions(
  {
    [appActions.showGlobalLoading]: produce(draft => {
      draft.loading++;
    }),

    [appActions.hideGlobalLoading]: produce(draft => {
      if (draft.loading > 0) {
        draft.loading--;
      }
    }),

    [appActions.showGlobalDrawer]: produce((draft, { payload }) => {
      draft.drawer = { open: true, ...payload };
    }),

    [appActions.hideGlobalDrawer]: produce(draft => {
      draft.drawer = initialState.drawer;
    }),

    [appActions.getUsedDailyLimit]: produce((draft, { payload }) => {
      draft.usedUploadLimit = payload;
    }),

    [appActions.updateModules]: produce((draft, { payload }) => {
      draft.modules = payload;
    }),

    [appActions.updateAppSettings]: produce((draft, { payload }) => {
      draft.settings = payload;
    }),

    [appActions.saveCurrentApplication]: (state, { payload }) => {
      sessionStorage.setItem('__modules', JSON.stringify(payload));

      return {
        ...state,
        ...payload,
      };
    },

    [appActions.clearCurrentApplication]: state => {
      const modules = { module: {}, application: {} };
      sessionStorage.setItem('__modules', JSON.stringify(modules));

      return {
        ...state,
        ...modules,
      };
    },

    [appActions.clearApp]: state => ({
      ...initialState,
      settings: state.settings,
    }),

    [appActions.showUploadsDrawer]: produce((draft, { payload }) => {
      draft.uploads = { open: true, ...payload };
    }),

    [appActions.hideUploadsDrawer]: produce(draft => {
      draft.uploads = { open: false };
    }),
  },
  initialState
);
