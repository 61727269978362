export default {
  container: {
    flex: '1',
    overflowY: 'auto',
    paddingBottom: '1rem',
  },
  form: {
    minWidth: '36rem',
  },
  values: {
    minWidth: '36rem',
    padding: '1rem',
  },
  value: {
    marginLeft: '.5rem',
  },
};
