export default ({ palette }) => ({
  container: {
    margin: '1rem',
  },
  file: {
    margin: '1.5rem 0',
  },
  warning: {
    textAlign: 'center',
    margin: '1rem 0',
    color: '#555',
  },
  progressBarStatus: {
    border: '1px solid #3b3a3b40',
    position: 'relative',
    borderRadius: 25,
    boxShadow: 'inset 0 -1px 1px rgba(255,255,255,0.3)',
  },
  barColorSuccess: {
    backgroundColor: '#31b331',
  },
  barColorFailed: {
    backgroundColor: '#ff0000',
  },
  statusSuccess: {
    float: 'right',
    color: '#008000',
  },
  statusFailed: {
    float: 'right',
    color: '#ff0000',
  },
  progressPercentage: {
    float: 'right',
  },
});
