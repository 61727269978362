import React from 'react';
import { string } from 'prop-types';

import { FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

export function MessageHolder({ title, message }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {!!title && (
        <div className={classes.title}>
          <FormattedMessage id={title} />
        </div>
      )}
      {!!message && (
        <div className={classes.message}>
          {message && <FormattedMessage id={message} />}
        </div>
      )}
    </div>
  );
}

MessageHolder.propTypes = {
  /* own props */
  title: string,
  message: string,
};

export default React.memo(MessageHolder);
