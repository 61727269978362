import axios from 'axios';

export default {
  getTags: clientId => axios.get(`/clients/${clientId}/tags`),

  getAreaSnapshots: mapId => axios.get(`/maps/${mapId}/areas`),

  getSnapshotHistory: ({ mapId, snapshot }) =>
    axios.get(`/maps/${mapId}/areas/${snapshot.id}/history`),

  saveTag: ({ clientId, tag }) => axios.post(`/clients/${clientId}/tags`, tag),

  updateTag: ({ clientId, tag }) =>
    axios.put(`/clients/${clientId}/tags/${tag.id}`, tag),

  deleteAreaSnapshot: ({ mapId, areaId }) =>
    axios.delete(`/maps/${mapId}/areas/${areaId}`),

  deleteAreaSnapshotShape: ({ mapId, areaId, shapeId }) =>
    axios.delete(`/maps/${mapId}/areas/${areaId}/shapes/${shapeId}`),

  saveAreaSnapshot: areaSnapshop =>
    axios.post(`/maps/${areaSnapshop.mapId}/areas`, areaSnapshop),

  updateAreaSnapshot: areaSnapshop =>
    axios.put(
      `/maps/${areaSnapshop.mapId}/areas/${areaSnapshop.id}`,
      areaSnapshop
    ),

  deleteTag: ({ clientId, tagId }) =>
    axios.delete(`/clients/${clientId}/tags/${tagId}`),
};
