export default ({ palette }) => ({
  container: {
    maxWidth: '46rem',
  },
  form: {
    marginTop: '1rem',
  },
  footer: {
    textAlign: 'right',
    margin: '.5rem .5rem 0rem 0rem',
  },
  fields: {
    margin: '.4rem .8rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  field: {
    margin: '0.5rem',
  },
  saveBtn: {
    margin: '0.775rem',
  },
});
