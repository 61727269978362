import { put, putResolve } from 'redux-saga/effects';

import { loadingActions } from 'commons/store/loading';

// dispara um setLoading/unsetLoading ao redor da saga original
export default function loadingSaga(saga) {
  return function* loadingWrapper(action) {
    const { type, payload } = action;

    try {
      yield putResolve(
        loadingActions.setLoading({
          data: payload,
          action: type,
        })
      );
      yield* saga(action);
    } finally {
      yield put(loadingActions.unsetLoading(type));
    }
  };
}
