import React from 'react';

import { object, bool, func } from 'prop-types';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/styles';

import MessageHolder from 'commons/components/MessageHolder';

import FormRender from '../FormRender';

import styles from './styles';

const useStyles = makeStyles(styles);

export function ElementFormValues({
  form,
  schema,
  element,
  blocked,
  formValues,
  onSave,
}) {
  const classes = useStyles();

  const activityElementValues = React.useMemo(() => {
    const valuesOfForm = formValues[form.formId] || {};

    return valuesOfForm[element.id] || {};
  }, [element, form, formValues]);

  const handleElementFormSave = React.useCallback(
    values => onSave(element.id, values),
    [element, onSave]
  );

  const renderContent = () => {
    if (!schema) {
      if (!Object.keys(activityElementValues).length) {
        return (
          <MessageHolder
            title="MESSAGES.ACTIVITIES.NO_ELEMENT_INFO"
            message="MESSAGES.ACTIVITIES.THERE_IS_NO_INFO_FOR_THIS_ELEMENT"
          />
        );
      }
      return (
        <div className={classes.values}>
          {Object.keys(activityElementValues).map(key => (
            <div key={key}>
              <span>{key}</span>
              <strong className={classes.value}>
                {JSON.stringify(activityElementValues[key])}
              </strong>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className={classes.form}>
        <FormRender
          blocked={blocked}
          schema={schema}
          values={activityElementValues}
          onSave={handleElementFormSave}
        />
      </div>
    );
  };

  return <div className={classes.container}>{renderContent()}</div>;
}

ElementFormValues.propTypes = {
  /* own props */
  blocked: bool.isRequired,
  form: object.isRequired,
  element: object.isRequired,
  onSave: func.isRequired,
  /* activity state */
  formValues: object,
};

const mapStateToProps = ({ activity: { activity } }) => ({
  formValues: activity.formValues,
});

export default connect(
  mapStateToProps,
  React.memo
)(ElementFormValues);
