import { handleActions } from 'redux-actions';

import { userActions } from 'commons/store/user';

const usersSelector = ({ users: { users } }) => users;
const userProfilesSelector = ({ users: { userProfiles } }) => userProfiles;
const profilesSelector = ({ users: { profiles } }) => profiles;
const profileSelector = ({ users: { profile } }) => profile;

export const selectors = {
  userProfilesSelector,
  profilesSelector,
  usersSelector,
  profileSelector,
};

/* handler */
const initialState = {
  profiles: [],
  users: [],
  profile: {},
  user: {},
  currentMapUsers: [],
  userProfiles: [],
};

const takeOutOfList = (users, newUsers) =>
  newUsers.filter(item => !users.some(user => user.id === item.id));

export default handleActions(
  {
    [userActions.updateProfiles]: (state, { payload }) => ({
      ...state,
      profiles: payload,
    }),
    [userActions.updateUserProfiles]: (state, { payload }) => ({
      ...state,
      userProfiles: payload,
    }),
    [userActions.updateProfile]: (state, { payload = {} }) => ({
      ...state,
      profile: payload,
    }),
    [userActions.updateUsers]: (state, { payload }) => ({
      ...state,
      users: payload,
    }),
    [userActions.updateUser]: (state, { payload }) => ({
      ...state,
      user: payload,
    }),
    [userActions.updateCurrentMapUsers]: (state, { payload }) => ({
      ...state,
      currentMapUsers: payload,
    }),
    [userActions.updateNewUsersAssociation]: (state, { payload }) => ({
      ...state,
      users: takeOutOfList(state.users, payload),
    }),
    [userActions.clearUsers]: (state, { payload }) => ({
      ...state,
      users: [],
    }),
    [userActions.getAvailablePermissionsSuccess]: (state, { payload }) => ({
      ...state,
      availablePermissions: payload,
    }),
  },
  initialState
);
