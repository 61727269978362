import React from 'react';
import { func, object, array } from 'prop-types';

import cx from 'clsx';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Close from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';

import { AppContext } from 'commons/contexts';
import { appSelectors, appActions } from 'commons/store/app';

import CollapsibleModule from './CollapsibleModule';

import styles from './styles';

export class Menu extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    /* own props */
    onToggle: func.isRequired,
    /* app state */
    modules: array.isRequired,
    module: object.isRequired,
    application: object.isRequired,
    /* app actions */
    saveCurrentApplication: func.isRequired,
    /* material-ui styles */
    classes: object.isRequired,
    /* react-router */
    history: object.isRequired,
  };

  handleSelectApplication = (module, application) => {
    this.setState({ expanded: false });
    if (application.type === 'APP' || !application.url) {
      // Para deixar de usar esse troço falto o app loader passar a url como query-string /appLoader?url=...
      this.props.saveCurrentApplication({ module, application });
    }
    if (application.type === 'ENTRIES') {
      const { applications, ...clone } = module;
      this.props.saveCurrentApplication({ module: clone, application });
    }
  };

  renderToggleHeader = (classes, expanded) => (
    <div className={cx(classes.toggleMenu)}>
      <IconButton
        className={cx(classes.toggleButton, { expanded })}
        onClick={this.props.onToggle}
      >
        {!expanded ? <MenuIcon /> : <Close />}
      </IconButton>
    </div>
  );

  renderContent = () => {
    const { classes, modules, application, module } = this.props;
    const { expanded } = this.context;

    return (
      <div className={cx(classes.content, { expanded })}>
        {this.renderToggleHeader(classes, expanded)}
        {modules.map(item => (
          <CollapsibleModule
            key={item.id}
            module={item}
            application={application}
            activeModule={module.id}
            onSelect={this.handleSelectApplication}
            expanded={expanded}
          />
        ))}
      </div>
    );
  };

  render() {
    const { expanded } = this.context;

    return (
      <>
        {expanded && <div onClick={this.handleMenuClose} />}
        {this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    app: { module, application },
  } = state;

  return {
    modules: appSelectors.removeExternalModulesSelector(state),
    module,
    application,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    { saveCurrentApplication: appActions.saveCurrentApplication }
  )
)(Menu);
