import { createAction } from 'redux-actions';

export default {
  logIn: createAction('auth/LOG_IN'),
  logOut: createAction('auth/LOG_OUT'),
  authenticate: createAction('auth/AUTHENTICATE'),
  getLoggedInUser: createAction('auth/GET_LOGGED_IN'),
  updateLoggedInUser: createAction('auth/GET_LOGGED_IN_USER_SUCCESS'),
  deleteLoggedInUser: createAction('auth/DELETE_LOGGED_IN_USER'),
  changePassword: createAction('auth/CHANGE_PASSWORD'),
  recoveryPassword: createAction('auth/RECOVERY_PASSWORD'),
  resetPassword: createAction('auth/RESET_PASSWORD'),
};
