import { handleActions } from 'redux-actions';

import { formActions } from 'commons/store/form';

/* selectors */
const simplifiedFieldsSelector = fields =>
  fields.map(item => ({
    id: item.key,
    name: item.title,
  }));

export const selectors = {
  simplifiedFieldsSelector,
};

/* state */
const initialState = {
  forms: [],
  fields: [],
};

/* handler */
export default handleActions(
  {
    [formActions.updateFormsList]: (state, { payload }) => ({
      ...state,
      forms: payload,
    }),

    [formActions.deleteFormsList]: (state, { payload }) => ({
      ...state,
      forms: [],
    }),

    [formActions.updateFormFields]: (state, { payload }) => ({
      ...state,
      fields: payload,
    }),

    [formActions.deleteFormFields]: state => ({
      ...state,
      fields: [],
    }),

    [formActions.updateForm]: (state, { payload }) => {
      const { form, application, context } = payload;
      const applicationObject = { ...state[application] } || {};

      applicationObject[context] = form;

      return { ...state, [application]: applicationObject };
    },

    [formActions.updateFormValue]: (state, { payload }) => {
      const { value, application, context } = payload;
      const applicationObject = { ...state[application] } || {};

      applicationObject[context].value = value;

      return { ...state, [application]: applicationObject };
    },

    [formActions.deleteForm]: (state, { payload }) => {
      const { application, context } = payload;

      const applicationObject = { ...state[application] };
      applicationObject[context] = {};

      return { ...state, [application]: applicationObject };
    },

    [formActions.deleteApplicationForms]: (state, { payload }) => {
      const { application } = payload;
      return { ...state, [application]: {} };
    },
  },
  initialState
);
