import React from 'react';
import { oneOfType, array, bool, func } from 'prop-types';

import { FormattedMessage } from 'react-intl';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import MultiSelect from 'commons/components/MultiSelect';

export function ActivityFormsField({
  forms,
  selected,
  disabled,
  loading,
  onChange,
}) {
  const handleFormChange = React.useCallback(
    ({ target }) => onChange(target.value),
    [onChange]
  );

  const renderFormValues = values => {
    return forms
      .filter(form => values.includes(form.formId))
      .map(item => item.label)
      .join(', ');
  };

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="select-multiple-checkbox">
        <FormattedMessage id="LABELS.COMMONS.FORM" />
      </InputLabel>
      <MultiSelect
        loading={loading}
        disabled={disabled}
        value={selected || []}
        onChange={handleFormChange}
        renderValue={renderFormValues}
        options={forms}
        mapOptionToKey={form => form.formId}
        mapOptionToLabel={form => form.label}
        large
      />
    </FormControl>
  );
}

ActivityFormsField.propTypes = {
  /* own props */
  forms: array.isRequired,
  selected: oneOfType([() => null, array]),
  onChange: func.isRequired,
  disabled: bool,
  loading: bool,
};

export default React.memo(ActivityFormsField);
