export default ({ palette }) => ({
  container: {
    flex: '1',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1',
    flexDirection: 'column',
    margin: '0 .5rem .5rem',
    padding: '1rem 0',
    overflow: 'hidden auto',
    backgroundColor: '#f9f9f9',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
  },
  attach: {
    display: 'none',
  },
  attachment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '.25rem',
    backgroundColor: '#f9f9f9',
    padding: '0 .25rem 0 1rem',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f9f9f9',
  },
  attachLabel: {
    cursor: 'pointer',
    '&.active': {
      color: palette.primary.main,
    },
  },
});
