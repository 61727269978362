import { takeEvery, fork } from 'redux-saga/effects';

import api from 'commons/api';
import notification from 'commons/notification';
import { parametersService } from 'commons/services';
import { parametersActions } from 'commons/store/parameters';

function* getAllParameters() {
  yield api(
    parametersService.getAllParameters,
    {},
    parametersActions.updateParametersList,
    function*() {
      yield notification('MESSAGES.FORMS.COULD_NOT_GET_FORMS', {
        type: 'error',
      });
    }
  );
}

function* saveParameter({ payload }) {
  yield* api(
    parametersService.saveParameter,
    payload,
    function*() {
      yield fork(getAllParameters);

      yield notification('MESSAGES.MAP.SUCCESSFUL_SAVED_MAP', {
        type: 'success',
      });
    },
    function* saveError(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_SAVE_MAP', { type: 'error' });
    },
    { multipleSuccessActions: true, loading: true }
  );
}

export default function*() {
  yield takeEvery(
    parametersActions.getAllParameters.toString(),
    getAllParameters
  );
  yield takeEvery(parametersActions.saveParameter.toString(), saveParameter);
}
