export default settings => {
  const currentTime = new Date().getHours();
  let background = '';

  if (!settings.bg_timelapse) {
    return `url(${settings.background})`;
  }

  if (currentTime >= 6 && currentTime < 9) {
    background = settings.bg_timelapse.morning;
  }

  if (currentTime >= 9 && currentTime < 15) {
    background = settings.bg_timelapse.noon;
  }

  if (currentTime >= 15 && currentTime < 16) {
    background = settings.bg_timelapse.afternoon1;
  }

  if (currentTime >= 16 && currentTime < 17) {
    background = settings.bg_timelapse.afternoon2;
  }

  if (currentTime >= 17 && currentTime < 18) {
    background = settings.bg_timelapse.sunset;
  }

  if (currentTime >= 18 && currentTime < 19) {
    background = settings.bg_timelapse.evening;
  }

  if (currentTime >= 19 || currentTime < 6) {
    background = settings.bg_timelapse.night;
  }

  return `url(${background})`;
};
