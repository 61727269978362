export default {
  entries: 'ENTRIES',
  app: 'APP',
  approvals: 'APPROVALS',
  maps: 'MAP_SETTINGS',
  files: 'FILES',
  layers: 'LAYERS',
  users: 'USERS',
  clients: 'CLIENTS',
  services: 'SERVICES',
  activities: 'ACTIVITIES',
  default: 'DEFAULT',
  themes: 'THEMES',
  profiles: 'PROFILES',
  export: 'EXPORT',
  import: 'IMPORT',
};
