import React from 'react';
import { decode } from 'querystring';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from 'commons/store/auth';

function ImplicitGrantLogin() {
  const dispatch = useDispatch();
  const { hash, search } = useLocation();
  const accessToken = decode(hash?.replace('#', ''));
  const { redirect } = decode(search?.replace('?', ''));

  React.useEffect(() => {
    if (!accessToken) {
      return;
    }
    dispatch(authActions.authenticate({ accessToken, redirect }));
  }, [accessToken, redirect, dispatch]);

  return null;
}

export default ImplicitGrantLogin;
