import React from 'react';
import { createCanBoundTo } from '@casl/react';
import ability from 'commons/ability';
import { AppContext } from 'commons/contexts';

export const BaseCan = createCanBoundTo(ability);

export default function Can(props) {
  const { securitySubject } = React.useContext(AppContext);

  const subject = props.a || securitySubject;

  return (
    <BaseCan I={props.I} a={subject}>
      {props.children}
    </BaseCan>
  );
}
