export default {
  container: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
};
