export default {
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '.2rem 0 .2rem 1rem',
    justifyContent: 'space-between',
  },
  content: {
    position: 'relative',
    minWidth: '26rem',
    maxWidth: '72rem',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  closeButton: {
    margin: '.3rem',
    height: 43,
    width: 43,
  },
};
