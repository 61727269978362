import React from 'react';
import { object, array, func } from 'prop-types';

import { FormattedMessage } from 'react-intl';

import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

import MessageHolder from 'commons/components/MessageHolder';
import { activityActions } from 'commons/store/activity';
import { loadingSelectors } from 'commons/store/loading';

import UserItem from './UserItem';

import styles from './styles';

const useStyles = makeStyles(styles);

const { updateActivityMembers } = activityActions;
const { isLoading } = loadingSelectors;

export function ActivityUsers({ activity, users, onSave }) {
  const classes = useStyles();

  const [selected, setSelected] = React.useState(activity.members);

  const membersLoading = useSelector(s => isLoading(s, updateActivityMembers));

  const handleUserToggle = React.useCallback(
    id =>
      setSelected(prev => {
        const _selected = new Set(prev);
        _selected.has(id) ? _selected.delete(id) : _selected.add(id);

        return Array.from(_selected);
      }),
    []
  );

  const handleSave = React.useCallback(() => {
    !membersLoading && onSave(activity.id, selected);
  }, [activity, selected, membersLoading, onSave]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="subtitle1">
          <FormattedMessage id="LABELS.USER.ASSOCIATED_USERS" />
        </Typography>
        {!!users.length && (
          <Button variant="contained" color="primary" onClick={handleSave}>
            {membersLoading && (
              <CircularProgress size={18} className={classes.loading} />
            )}
            <FormattedMessage id="LABELS.COMMONS.SAVE" />
          </Button>
        )}
      </div>
      <div className={classes.content}>
        {users.length ? (
          <List>
            {users.map(item => (
              <UserItem
                key={item.id}
                user={item}
                active={selected.includes(item.id)}
                onToggle={handleUserToggle}
              />
            ))}
          </List>
        ) : (
          <MessageHolder
            title="MESSAGES.USER.NO_USERS_TO_SHOW"
            message="MESSAGES.USER.HERE_ARE_DISPLAY_YOUR_CLIENT_USERS"
          />
        )}
      </div>
    </div>
  );
}

ActivityUsers.propTypes = {
  /* own props */
  activity: object.isRequired,
  users: array.isRequired,
  onSave: func.isRequired,
};

export default React.memo(ActivityUsers);
