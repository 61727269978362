import { handleActions } from 'redux-actions';

import { serviceActions } from 'commons/store/service';

const initialState = {
  paymentMethods: [],
  dynamicForms: [],
  services: [],
  service: {},
};

export default handleActions(
  {
    [serviceActions.updatePaymentMethods]: (state, { payload }) => ({
      ...state,
      paymentMethods: payload,
    }),
    [serviceActions.updateDynamicForms]: (state, { payload }) => ({
      ...state,
      dynamicForms: payload,
    }),
    [serviceActions.updateServices]: (state, { payload }) => ({
      ...state,
      services: payload,
    }),
    [serviceActions.updateService]: (state, { payload }) => ({
      ...state,
      service: payload,
    }),
    [serviceActions.clearService]: state => ({
      ...state,
      service: initialState.service,
    }),
  },
  initialState
);
