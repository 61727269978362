export default ({ palette }) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#fff',
    padding: '3rem 2rem 2rem',
    borderRadius: '.25rem',
    border: '1px solid #d8dde6',
  },
  backButton: {
    color: palette.primary.main,
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '.75rem .5rem',
  },
  logo: {
    maxWidth: '16rem',
    marginBottom: '1rem',
  },
  swipeViews: {
    maxWidth: '22rem',
  },
});
