import delay from '@redux-saga/delay-p';
import { takeEvery, put } from 'redux-saga/effects';

import { mapService } from 'commons/services';
import { loadingSaga } from 'commons/store/loading';
import { mapActivityActions } from 'commons/store/mapActivity';

function* filterMapActivities({ payload }) {
  try {
    yield delay(1000);
    const { data } = yield mapService.filterMapActivities(payload);
    yield put(mapActivityActions.updateMapActivities(data));
  } catch (err) {
    console.log(err);
  }
}

export default function*() {
  yield takeEvery(
    mapActivityActions.filterMapActivities.toString(),
    loadingSaga(filterMapActivities)
  );
}
