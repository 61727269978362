import { createAction } from 'redux-actions';

export default {
  restoreState: createAction('mapLayer/RESTORE_STATE'),
  openLayerEditor: createAction('mapLayer/OPEN_LAYER_EDITOR'),
  clearMapLayer: createAction('mapLayer/CLEAR_MAP_LAYER'),
  updateUsersFolderRestriction: createAction(
    'mapLayer/UPDATE_USERS_FOLDER_RESTRICTION'
  ),
  editUsersRestriction: createAction('mapLayer/EDIT_USERS_RESTRICTION'),
  toggleNodeActive: createAction('mapLayer/TOGGLE_NODE_ACTIVE'),
  removeNodeFromMap: createAction('mapLayer/REMOVE_NODE_FROM_MAP'),
  addNode: createAction('mapLayer/ADD_NODE'),
  updateNode: createAction('mapLayer/UPDATE_NODE'),
  deleteNode: createAction('mapLayer/DELETE_NODE'),
  toggleExpandNode: createAction('mapLayer/TOGGLE_EXPAND_NODE'),
  updateLayerOrder: createAction('mapLayer/UPDATE_LAYER_ORDER'),
  updateActiveLayers: createAction('mapLayer/UPDATE_MAP_ACTIVE_LAYERS'),
  updateExpandedNodes: createAction('mapLayer/UPDATE_EXPANDED_NODES'),
  changeLayerAttributes: createAction('mapLayer/CHANGE_LAYER_ATTRIBUTES'),
  addLayer: createAction('mapLayer/ADD_LAYER'),
  addLayerFiles: createAction('mapLayer/ADD_LAYER_FILES'),
  updateLayerFiles: createAction('mapLayer/ADD_LAYER_FILES_SUCCESS'),
  addDXFLayerFiles: createAction('mapLayer/ADD_DXF_LAYERS_TO_NODE'),
  addDXFLayers: createAction('mapLayer/ADD_DXF_LAYERS'),
  getDXFInputs: createAction('mapLayer/GET_DXF_LAYER_INPUTS'),
  updateLayerInputs: createAction('mapLayer/GET_DXF_LAYER_INPUTS_SUCCESS'),
  editLayer: createAction('mapLayer/EDIT_LAYER'),
  cancelEditing: createAction('mapLayer/CANCEL_EDITING'),
  getLayerDetails: createAction('mapLayer/GET_LAYER_DETAILS'),
  updateLayerAttrs: createAction('mapLayer/GET_LAYER_DETAILS_SUCCESS'),
  getLayerFilesStatus: createAction('mapLayer/GET_LAYER_FILES_STATUS'),
  updateLayerFilesStatus: createAction('mapLayer/GET_LAYER_FILES_SUCCESS'),
  updateTree: createAction('mapLayer/UPDATE_TREE'),
  moveNode: createAction('mapLayer/MOVE_NODE'),
  mergeLayerTypeAttrs: createAction('mapLayer/MERGE_LAYER_TYPE_ATTRIBUTES'),
  getAllMapsTrees: createAction('mapLayer/GET_ALL_MAPS_TREES_REQUEST'),
  updateAllMapTrees: createAction('mapLayer/GET_ALL_MAPS_TREES_SUCCESS'),
  addLayersToNode: createAction('mapLayer/ADD_LAYERS_TO_NODE'),
  exportLayer: createAction('mapLayer/EXPORT_LAYER'),
};
