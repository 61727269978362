import React from 'react';

export const AppContext = React.createContext();
export const MapContext = React.createContext();
export const LayeredMapContext = React.createContext();
export const ElementContext = React.createContext();
export const FilesContext = React.createContext();
export const ActivityElementsContext = React.createContext();
export const ActivityFormContext = React.createContext();
export const ActivitiesContext = React.createContext();
export const ActivityDrawerContext = React.createContext();
export const MapActivityContext = React.createContext();
export const RecentLayersContext = React.createContext();
