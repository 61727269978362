import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { mapActivityReducer } from './mapActivity';
import { parametersReducer } from './parameters';
import { mapThemeReducer } from './mapTheme';
import { layerTypeReducer } from './layerType';
import { mapLayerReducer } from './mapLayer';
import { mapShapeReducer } from './mapShape';
import { approvalReducer } from './approval';
import { activityReducer } from './activity';
import { loadingReducer } from './loading';
import { mapFileReducer } from './mapFile';
import { serviceReducer } from './service';
import { clientReducer } from './client';
import { entryReducer } from './entry';
import { authReducer } from './auth';
import { formReducer } from './form';
import { userReducer } from './user';
import { fileReducer } from './file';
import { mapReducer } from './map';
import { appReducer } from './app';

import layerFilterSlice from './layerFilter/layerFilterSlice';
import mapElementSlice from './mapElement/mapElementSlice';

export default history =>
  combineReducers({
    app: appReducer,
    activity: activityReducer,
    auth: authReducer,
    mapLayer: mapLayerReducer,
    approval: approvalReducer,
    entry: entryReducer,
    form: formReducer,
    users: userReducer,
    services: serviceReducer,
    client: clientReducer,
    map: mapReducer,
    layerType: layerTypeReducer,
    mapTheme: mapThemeReducer,
    mapShape: mapShapeReducer,
    mapFile: mapFileReducer,
    mapActivity: mapActivityReducer,
    file: fileReducer,
    parameters: parametersReducer,
    loading: loadingReducer,
    router: connectRouter(history),
    [layerFilterSlice.name]: layerFilterSlice.reducer,
    [mapElementSlice.name]: mapElementSlice.reducer,
  });
