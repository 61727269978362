import { all } from 'redux-saga/effects';

import { activitySaga } from './activity';
import { authSaga } from './auth';
import { appSaga } from './app';
import { approvalSaga } from './approval';
import { entrySaga } from './entry';
import { formSaga } from './form';
import { mapThemeSaga } from './mapTheme';
import { mapSaga } from './map';
import { mapActivitySaga } from './mapActivity';
import { fileSaga } from './file';
import { clientSaga } from './client';
import { layerTypeSaga } from './layerType';
import { mapFileSaga } from './mapFile';
import { parametersSaga } from './parameters';
import { serviceSaga } from './service';
import { mapLayerSaga } from './mapLayer';
import { userSaga } from './user';
import { mapShapeSaga } from './mapShape';

export default function* rootSaga() {
  yield all([
    appSaga(),
    activitySaga(),
    authSaga(),
    approvalSaga(),
    mapActivitySaga(),
    entrySaga(),
    formSaga(),
    userSaga(),
    serviceSaga(),
    clientSaga(),
    mapSaga(),
    mapThemeSaga(),
    mapLayerSaga(),
    mapShapeSaga(),
    mapFileSaga(),
    layerTypeSaga(),
    fileSaga(),
    parametersSaga(),
  ]);
}
