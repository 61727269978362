import produce from 'immer';

import { handleActions } from 'redux-actions';

import { mapActivityActions } from 'commons/store/mapActivity';

const initialState = {
  activities: [],
  filterableForms: [],
};

export default handleActions(
  {
    [mapActivityActions.restoreState]: (_, { payload }) => payload,

    [mapActivityActions.clearMapActivities]: () => initialState,

    [mapActivityActions.updateMapActivities]: produce(
      (draft, { payload = [] }) => {
        if (payload.length >= 0) {
          draft.activities = payload;
        } else {
          draft.activities = payload.activities;
          draft.filterableForms = payload.filterableForms;
        }
      }
    ),

    [mapActivityActions.addActivityToList]: produce((draft, { payload }) => {
      draft.activities.unshift(payload);
    }),

    [mapActivityActions.updateActivityInList]: produce((draft, { payload }) => {
      draft.activities = draft.activities.map(item =>
        item.id === payload.id ? payload : item
      );
    }),

    [mapActivityActions.deleteActivityFromList]: produce(
      (draft, { payload }) => {
        draft.activities.splice(
          draft.activities.findIndex(item => item.id === payload)
        );
      }
    ),
  },
  initialState
);
