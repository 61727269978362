import React from 'react';
import { object, array, func } from 'prop-types';

import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

export function ActivityElementItem({ theme, elements, onDelete, onEdit }) {
  const handleEdit = React.useCallback(() => onEdit(theme, elements), [
    elements,
    onEdit,
    theme,
  ]);

  const handleDelete = React.useCallback(() => onDelete(theme.id), [
    onDelete,
    theme,
  ]);

  return (
    <ListItem dense>
      <ListItemAvatar>
        <Avatar>{elements.length}</Avatar>
      </ListItemAvatar>
      <ListItemText primary={theme.name} />
      <ListItemSecondaryAction>
        <IconButton onClick={handleEdit} aria-label="Edit">
          <Edit />
        </IconButton>
        <IconButton onClick={handleDelete} aria-label="Delete">
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

ActivityElementItem.propTypes = {
  /* own props */
  theme: object.isRequired,
  elements: array.isRequired,
  onDelete: func.isRequired,
  onEdit: func.isRequired,
};

export default React.memo(ActivityElementItem);
