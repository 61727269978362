import { createAction } from 'redux-actions';

export default {
  restoreState: createAction('mapFile/RESTORE_STATE'),
  clearMapFiles: createAction('mapFile/CLEAN_MAP_FILES'),
  addNode: createAction('mapFile/ADD_NODE'),
  updateNode: createAction('mapFile/UPDATE_NODE'),
  deleteNode: createAction('mapFile/DELETE_NODE'),
  addFiles: createAction('mapFile/ADD_FILES'),
  initTree: createAction('mapFile/INIT_TREE'),
  getTreeNode: createAction('mapFile/GET_TREE_NODE_REQUEST'),
  updateTreeNode: createAction('mapFile/GET_TREE_NODE_SUCCESS'),
  toggleTreeNode: createAction('mapFile/TOGGLE_TREE_NODE'),
  clearFilesTree: createAction('mapFile/CLEAR_FILES_TREE'),
  updateNodeContractedState: createAction(
    'mapFile/UPDATE_NODE_CONTRACTED_STATE'
  ),
  getFileDetails: createAction('mapFile/GET_FILE_DETAILS'),
  updateFileDetails: createAction('mapFile/UPDATE_FILE_DETAILS'),
  reduceVideo: createAction('mapFile/REDUCE_VIDEO'),
  downloadFile: createAction('mapFile/DOWNLOAD_FILE'),
  insertNodeMapFile: createAction('mapFile/INSERT_NODE_MAP_FILE_REQUEST'),
  expandNode: createAction('mapFile/EXPAND_NODE'),
};
