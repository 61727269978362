import React from 'react';
import { object } from 'prop-types';

import { Field } from 'react-final-form';
import { injectIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

import { RECURRENCE_FREQUENCY } from 'commons/constants';
import {
  TextFieldAdapter,
  SelectAdapter,
  ImageUploadAdapter,
} from 'commons/form';

import styles from './styles';

const useStyles = makeStyles(styles);

export function ActivityComplementaryInfoFields({ intl }) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} className={classes.field}>
        <Field
          name="description"
          type="text"
          className={classes.description}
          placeholder={intl.formatMessage({
            id: 'LABELS.COMMONS.DESCRIPTION',
          })}
          component={TextFieldAdapter}
          autoComplete="off"
          fullWidth
          multiline
          rows="7"
        />
        <div className={classes.recurrentWrap}>
          <Field
            name="frequency"
            label={intl.formatMessage({
              id: 'LABELS.ACTIVITIES.RECURRENCE_FREQUENCY',
            })}
            component={SelectAdapter}
            options={RECURRENCE_FREQUENCY}
            fullWidth
          />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.imageSelector}>
        <Field
          className={classes.imageUploader}
          name="image"
          showUploadButton={false}
          component={ImageUploadAdapter}
        />
      </Grid>
    </>
  );
}

ActivityComplementaryInfoFields.propTypes = {
  /* react-intl */
  intl: object.isRequired,
};

export default React.memo(injectIntl(ActivityComplementaryInfoFields));
