export default ({ menu }) => ({
  container: {
    width: 'calc(100% - 2rem)',
    marginLeft: 25,
    marginTop: -1,
    '&:before': {
      content: 'close-quote',
      position: 'absolute',
      top: '-50%',
      left: 0,
      height: '100%',
      borderLeft: '1px dashed #6f6f6f',
    },
    '&:after': {
      content: 'close-quote',
      position: 'absolute',
      borderBottom: '1px dashed #6f6f6f',
      width: 10,
      left: 0,
    },
  },
  text: {
    color: menu.nestedItem.color,
    fontSize: '.9rem',
    transition: 'margin-left 225ms cubic-bezier(.25, .1, .25, .6) 0ms',
    '&.active': {
      color: menu.nestedItem.active,
      marginLeft: 4,
    },
  },
});
