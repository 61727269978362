import { takeEvery, put } from 'redux-saga/effects';

import notification from 'commons/notification';
import api from 'commons/api';
import { formService } from 'commons/services';
import { formActions } from 'commons/store/form';

function* getForm({ payload }) {
  yield* api(
    formService.getForm,
    payload,
    function*(form) {
      const { application, context } = payload;
      yield put(formActions.updateForm({ form, application, context }));
    },
    function*(err) {
      yield notification('MESSAGES.FORMS.COULD_NOT_GET_FORM_SCHEMA', {
        type: 'error',
      });
    }
  );
}

function* getAllForms() {
  yield* api(
    formService.getAllForms,
    {},
    formActions.updateFormsList,
    function*(err) {
      yield notification('MESSAGES.FORMS.COULD_NOT_GET_FORMS', {
        type: 'error',
      });
    },
    { loading: true, multiple: false }
  );
}

function* getFormFields({ payload }) {
  yield* api(
    formService.getFormFields,
    payload,
    formActions.updateFormFields,
    function*(err) {
      yield notification('MESSAGES.FORMS.COULD_NOT_GET_FORMS', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

export default function*() {
  yield takeEvery(formActions.getAllForms.toString(), getAllForms);
  yield takeEvery(formActions.getForm.toString(), getForm);
  yield takeEvery(formActions.getFormFields.toString(), getFormFields);
}
