export default {
  avatar: {
    color: '#000',
    backgroundColor: '#f1f1f1',
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      opacity: '.9',
    },
  },
  container: {},
};
