import { createSlice } from '@reduxjs/toolkit';
import { mapLayerService } from 'commons/services';

export const initialState = {
  filtersByLayerId: {},
  filterValueByLayerId: {},
};

const mapLayerFilterSlice = createSlice({
  name: 'mapLayerFilter',
  initialState,
  reducers: {
    updateLayerFilterValue(
      state,
      {
        payload: { layerId, filterValue },
      }
    ) {
      state.filterValueByLayerId[layerId] = filterValue;
    },
    updateLayerFilters(
      state,
      {
        payload: { layerId, filters },
      }
    ) {
      if (filters?.length) {
        state.filtersByLayerId[layerId] = filters;
      } else {
        delete state.filtersByLayerId[layerId];
      }
    },
    clearLayerFilters(
      state,
      {
        payload: { layerId },
      }
    ) {
      delete state.filtersByLayerId[layerId];
    },
  },
});

export const layerFiltersSelector = (
  { [mapLayerFilterSlice.name]: state },
  { layerId }
) => state.filtersByLayerId[layerId];

export const layerFilterValueSelector = (
  { [mapLayerFilterSlice.name]: state },
  { layerId }
) => state.filterValueByLayerId[layerId];

export const fetchLayerFilters = ({
  mapId,
  nodeId,
  layerId,
}) => async dispatch => {
  const response = await mapLayerService.getLayerFilters({ mapId, nodeId });
  const filters = response.data;
  dispatch(updateLayerFilters({ layerId, filters }));
};

export const {
  updateLayerFilterValue,
  updateLayerFilters,
  clearLayerFilters,
} = mapLayerFilterSlice.actions;

export default mapLayerFilterSlice;
