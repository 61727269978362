import React from 'react';

import cx from 'clsx';
import { array, bool, object, func } from 'prop-types';
import { compose } from 'recompose';
import { injectIntl } from 'react-intl';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { fileUploader } from 'commons/uploader';

import AutosizeTextarea from './AutosizeTextarea';
import MessageCard from './MessageCard';

import styles from './styles';

const useStyles = makeStyles(styles);

export function ActivityMessages({
  intl,
  blocked,
  messages = [],
  user,
  onSend,
}) {
  const classes = useStyles();

  const [message, setMessage] = React.useState('');
  const [attachment, setAttachment] = React.useState({});

  const handleMessageChange = React.useCallback(value => setMessage(value), []);

  const handleAttachmentDelete = () => setAttachment({});

  const handleAttachFile = React.useCallback(({ target }) => {
    const reader = new FileReader();
    const [file] = target.files;

    if (file) {
      reader.readAsDataURL(file);

      reader.onloadend = async () => {
        const data = new FormData();
        data.append('file', file);

        const attachment = await fileUploader(data);
        setAttachment(attachment);

        target.value = '';
      };
    }
  }, []);

  const handleMessageSend = () => {
    onSend({ message, attachment });
    setMessage('');
    setAttachment({});
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {messages.map((item, index) => (
          <MessageCard
            key={index}
            mine={item.user.id === user.id}
            message={item}
          />
        ))}
      </div>
      {!blocked && (
        <div className={classes.footer}>
          {!!attachment.id && (
            <div className={classes.attachment}>
              <Typography variant="body2">{attachment.name}</Typography>
              <IconButton
                aria-label="Send"
                onClick={handleAttachmentDelete}
                className={classes.sendBtn}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          )}
          <div className={classes.message}>
            <IconButton aria-label="Attach">
              <input
                className={classes.attach}
                id="contained-button-file"
                type="file"
                onInput={handleAttachFile}
              />
              <label
                className={cx(classes.attachLabel, { active: !!attachment.id })}
                htmlFor="contained-button-file"
              >
                <AttachFileIcon />
              </label>
            </IconButton>
            <AutosizeTextarea
              minRows="1"
              maxRows="3"
              text={message}
              placeholder={intl.formatMessage({
                id: 'LABELS.COMMONS.MESSAGES',
              })}
              onChange={handleMessageChange}
            />
            <IconButton
              color="primary"
              aria-label="Send"
              disabled={!attachment.id && !message.length}
              onClick={handleMessageSend}
              className={classes.sendBtn}
            >
              <SendIcon />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}

ActivityMessages.propTypes = {
  /* own props */
  messages: array,
  blocked: bool.isRequired,
  user: object.isRequired,
  onSend: func.isRequired,
};

export default compose(
  injectIntl,
  React.memo
)(ActivityMessages);
