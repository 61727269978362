import { takeEvery, put, race, take } from 'redux-saga/effects';

import api from 'commons/api';
import routes from 'commons/routes';
import notification from 'commons/notification';
import { config } from 'commons/store';
import { replaceParams } from 'commons/history';
import { entryService } from 'commons/services';
import { serviceService } from 'commons/services';
import { entryActions } from 'commons/store/entry';
import { formActions } from 'commons/store/form';

function* getEntries({ payload }) {
  yield* api(
    entryService.getEntries,
    payload,
    entryActions.updateEntries,
    function*(err) {
      yield notification('MESSAGES.ENTRIES.COULD_NOT_GET_ENTRIES', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* getEntryValues({ payload: { moduleId, applicationId } }) {
  yield* api(
    entryService.getEntryValues,
    { moduleId, applicationId },
    entryActions.updateEntryValues,
    function*(err) {
      yield notification('MESSAGES.ENTRIES.COULD_NOT_GET_ENTRIES', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* getHistory({ payload }) {
  yield* api(
    entryService.getHistory,
    payload,
    entryActions.updateHistory,
    err => console.log(err),
    { multiple: false }
  );
}

function* getEntryDetails({ payload }) {
  yield* api(
    entryService.getEntryDetails,
    payload,
    function*(data) {
      yield put(entryActions.updateEntryDetails(data));
      yield put(entryActions.getHistory(data.id));

      config.history.push(
        replaceParams(`${routes.urls['ENTRIES']}${routes.urls['DETAILS']}`, {
          id: data.applicationId,
          dId: data.id,
        })
      );
    },
    function*(err) {
      yield notification('MESSAGES.ENTRIES.COULD_NOT_GET_ENTRY_DETAILS', {
        type: 'error',
      });
    }
  );
}

function* saveEntry({ payload: { context, ...entryRegistry } }) {
  yield* api(
    entryService.saveEntry,
    entryRegistry,
    function*(entryId) {
      yield put(
        formActions.updateFormValue({
          value: entryRegistry.values,
          application: entryRegistry.applicationId,
          context,
        })
      );
      // TODO: extract entry request confirmation saga to allow extensibility.
      if (!entryRegistry.id) {
        const {
          data: services,
        } = yield serviceService.getServicesByApplicationId(
          entryRegistry.applicationId
        );

        const service = handleApplicationService(services);

        if (service) {
          yield put(
            entryActions.showEntryRequestConfirm({ serviceName: service.name })
          );

          const { confirm } = yield race({
            confirm: take(entryActions.CONFIRM_ENTRY_REQUEST),
            cancel: take(entryActions.CANCEL_ENTRY_REQUEST),
          });

          if (confirm) {
            yield serviceService.saveRequestFromEntry({
              entryId,
              serviceId: service.id,
            }); // TODO: handle success/failure
          }
        }
      }
      yield notification('MESSAGES.ENTRIES.SUCCESSFUL_SAVED_ENTRY', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.ENTRIES.COULD_NOT_SAVE_ENTRY', {
        type: 'error',
      });
    }
  );
}

// FIXME: POG
function handleApplicationService(services) {
  return Array.isArray(services) && services[0];
}

function* deleteEntry({ payload }) {
  yield* api(
    entryService.deleteEntry,
    payload,
    function*(data) {
      yield put(entryActions.deleteEntryFromList(data));
      yield notification('MESSAGES.ENTRIES.SUCCESSFUL_DELETED_ENTRY', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.ENTRIES.COULD_NOT_DELETE_ENTRY', {
        type: 'error',
      });
    }
  );
}

export default function*() {
  yield takeEvery(entryActions.getEntries.toString(), getEntries);
  yield takeEvery(entryActions.getEntryDetails.toString(), getEntryDetails);
  yield takeEvery(entryActions.getHistory.toString(), getHistory);
  yield takeEvery(entryActions.saveEntry.toString(), saveEntry);
  yield takeEvery(entryActions.deleteEntry.toString(), deleteEntry);
  yield takeEvery(entryActions.getEntryValues.toString(), getEntryValues);
}
