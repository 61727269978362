import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

import routes from 'commons/routes';
import { MESSAGES } from 'commons/constants';
import { getLang } from 'commons/locale';

import styles from './styles';

const useStyles = makeStyles(styles);

export function CrashRoute({ history }) {
  const classes = useStyles();

  const handleTryAgain = React.useCallback(() => {
    history.push(routes.urls.PANEL);
    window.location.reload();
  }, [history]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div>
          <h1 className={classes.title}>Whoops!</h1>
          <p className={classes.message}>
            {MESSAGES[getLang()].COULD_NOT_GET_CONFIG}
          </p>
          <Button
            variant="contained"
            className={classes.reloadButton}
            onClick={handleTryAgain}
          >
            {MESSAGES[getLang()].RELOAD}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(CrashRoute);
