export default {
  content: {
    position: 'absolute',
    top: '3.5rem',
    left: 52,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&.unique': {
      left: 0,
    },
    '&.expanded': {
      left: 248,
    },
    transition: 'left 225ms cubic-bezier(.25, .1, .25, .6) 0ms',
  },
};
