import React from 'react';

import { func, string } from 'prop-types';

import { makeStyles } from '@material-ui/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

export function AutosizeTextarea({
  text = '',
  placeholder = '',
  minRows = 1,
  maxRows = 2,
  onChange,
}) {
  const classes = useStyles();

  const [rows, setRows] = React.useState(+minRows);

  const handleChange = ({ target }) => {
    const textareaLineHeight = 24;
    const _minRows = +minRows;
    const _maxRows = +maxRows;

    const previousRows = target.rows;
    target.rows = _minRows;

    const currentRows = ~~(target.scrollHeight / textareaLineHeight);

    currentRows === previousRows && (target.rows = currentRows);

    if (currentRows >= _maxRows) {
      target.rows = _maxRows;
      target.scrollTop = target.scrollHeight;
    }

    setRows(currentRows < _maxRows ? currentRows : _maxRows);
    onChange(target.value);
  };

  return (
    <textarea
      className={classes.container}
      rows={rows}
      value={text}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
}

AutosizeTextarea.propTypes = {
  /* own props */
  text: string.isRequired,
  placeholder: string,
  minRows: string,
  maxRows: string,
  onChange: func.isRequired,
};

export default React.memo(AutosizeTextarea);
