import GeoJSON from 'geojson';

import { SHAPE_TYPE } from 'commons/constants';

export function geoJSON(coord) {
  const types = {};

  const methods = {
    Point() {
      types['Point'] = ['lat', 'lng'];
      return this;
    },
    LineString() {
      types['LineString'] = 'line';
      return this;
    },
    Polygon() {
      types['Polygon'] = 'path';
      return this;
    },
    getJSON() {
      return GeoJSON.parse(coord, types);
    },
  };

  return methods;
}

const convertDistanceToGeoJson = ({ path, editable, ...shape }) => ({
  line: path.map(coord => [+coord.lng.toFixed(6), +coord.lat.toFixed(6)]),
  shape: SHAPE_TYPE.DISTANCES,
  ...shape,
});

const convertPolygonToGeoJson = ({ path, editable, ...shape }) => ({
  path: path.map(coord => [+coord.lng.toFixed(6), +coord.lat.toFixed(6)]),
  shape: SHAPE_TYPE.POLYGONS,
  ...shape,
});

const convertRectangleToGeoJson = ({ bounds, editable, ...shape }) => ({
  path: [[bounds.west, bounds.south], [bounds.east, bounds.north]],
  shape: SHAPE_TYPE.RECTANGLES,
  ...shape,
});

const convertCircleToGeoJson = ({ center, editable, ...shape }) => ({
  shape: SHAPE_TYPE.CIRCLES,
  ...center,
  ...shape,
});

const extractBasicFromShapeGeoJson = ({ tag, description, shape }) => ({
  id: Math.random()
    .toString()
    .substr(2),
  tag,
  description,
  shape,
});

const parseGeoJsonBounds = coords => {
  const [sw, ne] = coords;
  const [west, south] = sw;
  const [east, north] = ne;

  return { west, south, east, north };
};

const parseGeoJsonDistance = ({ geometry, properties }) => {
  const { length } = properties;
  const { coordinates } = geometry;

  return {
    length,
    path: coordinates.map(([lng, lat]) => ({ lat, lng })),
    ...extractBasicFromShapeGeoJson(properties),
  };
};

const parseGeoJsonPolygon = ({ geometry, properties }) => {
  const { area } = properties;
  const { coordinates } = geometry;

  return {
    area,
    path: coordinates.map(([lng, lat]) => ({ lat, lng })),
    ...extractBasicFromShapeGeoJson(properties),
  };
};

const parseGeoJsoRectangle = ({ geometry, properties }) => {
  const { area } = properties;
  const { coordinates } = geometry;

  return {
    area,
    bounds: parseGeoJsonBounds(coordinates),
    ...extractBasicFromShapeGeoJson(properties),
  };
};

const parseGeoJsonCircle = ({ geometry, properties }) => {
  const { area, radius } = properties;
  const { coordinates } = geometry;
  const [lng, lat] = coordinates;

  return {
    area,
    radius,
    center: { lat, lng },
    ...extractBasicFromShapeGeoJson(properties),
  };
};

export const formatMeasurementsByType = ({ shape, type, intl }) => {
  const radiusLabel = intl.formatMessage({ id: 'LABELS.SHAPE.RADIUS' });

  if (type === SHAPE_TYPE.DISTANCES) {
    return `${intl.formatNumber(shape.length)} m`;
  }
  if (type === SHAPE_TYPE.CIRCLES) {
    return `${intl.formatNumber(
      shape.area
    )} m² - ${radiusLabel}: ${intl.formatNumber(shape.radius)} m`;
  }
  return `${intl.formatNumber(shape.area)} m²`;
};

export const computeRectangleArea = bounds => {
  if (!bounds) {
    return 0;
  }

  const { geometry, LatLng } = window.google.maps;

  const sw = bounds.getSouthWest();
  const ne = bounds.getNorthEast();

  const southWest = new LatLng(sw.lat(), sw.lng());
  const northEast = new LatLng(ne.lat(), ne.lng());
  const southEast = new LatLng(sw.lat(), ne.lng());
  const northWest = new LatLng(ne.lat(), sw.lng());

  return geometry.spherical
    .computeArea([northEast, northWest, southWest, southEast])
    .toFixed(2);
};

export const isShapeEmpty = (shape, type) => {
  if (type === SHAPE_TYPE.DISTANCES || type === SHAPE_TYPE.POLYGONS) {
    return !shape.path.length;
  }
  if (type === SHAPE_TYPE.RECTANGLES) {
    return !shape.bounds;
  }
  if (type === SHAPE_TYPE.CIRCLES) {
    return !shape.center;
  }
};

export const getCoordinateLngOffset = ({ lat, lng }, offset = 0.025) => ({
  lat,
  lng: lng + offset,
});

export const computeDistanceBetween = (fromLatLng, toLatLng) => {
  const { geometry, LatLng } = window.google.maps;

  const from = new LatLng(fromLatLng.lat, fromLatLng.lng);
  const to = new LatLng(toLatLng.lat, toLatLng.lng);

  return geometry.spherical.computeDistanceBetween(from, to).toFixed(2);
};

export const getCoordinateFromGeoJson = geoJson =>
  extractAllCoordinates(geoJson)[0];

export const getCoordinateFromLatLng = latLng => ({
  lat: +latLng.lat(),
  lng: +latLng.lng(),
});

export const getXYFromCoordinate = coordinate => ({
  y: +coordinate.lat,
  x: +coordinate.lng,
});

export const getCoordinateFromXY = (x, y) => ({
  lat: y,
  lng: x,
});

export const extractAllCoordinates = ({ features }) =>
  features.reduce((acc, { geometry: { coordinates } }) => {
    if (Array.isArray(coordinates[0])) {
      coordinates.forEach(([lng, lat]) => {
        acc.push({ lat, lng });
      });
    }
    if (typeof coordinates[0] === 'number') {
      acc.push({ lat: coordinates[1], lng: coordinates[0] });
    }

    return acc;
  }, []);

export const extractAllCoordinatesLineString = feature =>
  feature.geometry.coordinates.map(([lng, lat]) => ({ lat, lng }));

export const convertShapeToGeojonFeature = (shape, type) => {
  if (type === SHAPE_TYPE.DISTANCES) {
    return convertDistanceToGeoJson(shape);
  }
  if (type === SHAPE_TYPE.POLYGONS) {
    return convertPolygonToGeoJson(shape);
  }
  if (type === SHAPE_TYPE.RECTANGLES) {
    return convertRectangleToGeoJson(shape);
  }
  return convertCircleToGeoJson(shape);
};

export const parseGeojsonShapeFeature = ({ features }, editable) =>
  features.map(({ geometry, properties }) => {
    if (properties.shape === SHAPE_TYPE.DISTANCES) {
      return { ...parseGeoJsonDistance({ geometry, properties }), editable };
    }
    if (properties.shape === SHAPE_TYPE.POLYGONS) {
      return { ...parseGeoJsonPolygon({ geometry, properties }), editable };
    }
    if (properties.shape === SHAPE_TYPE.RECTANGLES) {
      return { ...parseGeoJsoRectangle({ geometry, properties }), editable };
    }
    return { ...parseGeoJsonCircle({ geometry, properties }), editable };
  });
