import { createAction } from 'redux-actions';

export default {
  getRequests: createAction('approvals/GET_REQUESTS'),
  updateRequests: createAction('approvals/GET_REQUESTS_SUCCESS'),
  deleteRequests: createAction('entry/DELETE_REQUESTS'),
  getRequestDetails: createAction('approvals/GET_REQUEST_DETAILS'),
  updateRequestDetails: createAction('approvals/GET_REQUEST_DETAILS_SUCCESS'),
  approveRequest: createAction('approvals/APPROVE_REQUEST'),
  disapproveRequest: createAction('approvals/DISAPPROVE_REQUEST'),
};
