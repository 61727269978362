export default {
  container: {
    width: '62rem',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflow: 'hidden',
  },
  content: {
    flex: '1',
    overflow: 'hidden',
    display: 'flex',
  },
  forms: {
    flex: '1 1 60%',
    display: 'flex',
    borderRight: '1px solid #dcdcdc',
    flexDirection: 'column',
  },
  tabs: {},
  messages: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 48px',
    '&.open': {
      flex: '1 1 40%',
      borderLeft: '1px solid #e6e6e6',
    },
  },
  messageHeader: {
    alignItems: 'center',
    display: 'flex',
    height: '3rem',
    justifyContent: 'space-between',
  },
  messagesTitle: {
    paddingLeft: '1rem',
  },
  formContent: {
    flex: '1',
    maxHeight: '100%',
    position: 'relative',
    overflow: 'hidden auto',
    paddingBottom: '1rem',
  },
};
