import { createAction } from 'redux-actions';

export default {
  getEntries: createAction('entry/GET_ENTRIES'),
  updateEntries: createAction('entry/GET_ENTRIES_SUCCESS'),
  deleteEntries: createAction('entry/DELETE_ENTRIES'),
  getEntryValues: createAction('entry/GET_ENTRY_VALUES'),
  updateEntryValues: createAction('entry/GET_ENTRY_VALUES_SUCCESS'),
  saveEntry: createAction('entry/SAVE_ENTRY'),
  deleteEntry: createAction('entry/DELETE_ENTRY'),
  deleteEntryFromList: createAction('entry/DELETE_ENTRY_SUCCESS'),
  getEntryDetails: createAction('entry/GET_ENTRY_DETAILS'),
  updateEntryDetails: createAction('entry/GET_ENTRY_DETAILS_SUCCESS'),
  deleteEntryDetails: createAction('entry/DELETE_ENTRY_DETAILS'),
  getHistory: createAction('entry/GET_HISTORY'),
  updateHistory: createAction('entry/GET_HISTORY_SUCCESS'),
  showEntryRequestConfirm: createAction('entry/SHOW_REQUEST_CONFIRM'),
  confirmEntryRequest: createAction('entry/CONFIRM_ENTRY_REQUEST'),
  cancelEntryRequest: createAction('entry/CANCEL_ENTRY_REQUEST'),
};
