export default ({ palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > input': {
      height: 30,
      paddingLeft: 8,
      marginBottom: 4,
      width: '100%',
    },
  },
  field: {
    marginBottom: '8px !important',
  },
  recoveryTitle: {
    textAlign: 'center',
    marginBottom: '.75rem',
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    fontWeight: 300,
    letterSpacing: -0.5,
  },
  recoveryMessage: {
    fontSize: '.9rem',
    marginBottom: '1rem',
  },
  sendButton: {
    marginTop: '1rem',
    width: '100%',
  },
  recoveryButton: {
    marginTop: '1rem',
    width: '50%',
    '&:hover': {
      backgroundColor: palette.primary.light,
    },
  },
});
