import React from 'react';

import { object, func } from 'prop-types';

import PlaceIcon from '@material-ui/icons/Place';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/styles';

import LayerStatusItem from './LayerStatusItem';
import { ListItemSecondaryAction } from '@material-ui/core';

import styles from './styles';

const useStyles = makeStyles(styles);

export function MapLayerItem({ map, onSelect }) {
  const classes = useStyles();

  const handleMapSelect = () => onSelect(map);

  return (
    <>
      <ListItem disableGutters className={classes.mapItem}>
        <ListItemText primary={map.name} />
        <ListItemSecondaryAction>
          <IconButton onClick={handleMapSelect} aria-label="Go">
            <PlaceIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in unmountOnExit>
        {map.layers.map(layer => (
          <LayerStatusItem key={layer.id} layer={layer} map={map.id} />
        ))}
      </Collapse>
    </>
  );
}

MapLayerItem.propTypes = {
  /* own props */
  map: object.isRequired,
  onSelect: func.isRequired,
};

export default React.memo(MapLayerItem);
