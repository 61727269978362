import React, { useMemo } from 'react';

import Immutable from 'immutable';
import createDecorator from 'final-form-calculate';
import { object, number, array } from 'prop-types';
import { OnChange } from 'react-final-form-listeners';
import { Form, Field } from 'react-final-form';
import { compose } from 'recompose';
import { useSelector, useDispatch, connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

import validate from 'commons/validate';
import { VIEWS } from 'commons/constants';
import { ActivityDrawerContext } from 'commons/contexts';
import { TextFieldAdapter, SelectAdapter } from 'commons/form';
import { activitySelectors, activityActions } from 'commons/store/activity';
import { loadingSelectors } from 'commons/store/loading';
import { clientActions } from 'commons/store/client';

import styles from './styles';

import ActivityComplementaryInfoFields from './ActivityComplementaryInfoFields';
import ActivityElementsDrawer from './ActivityElementsDrawer';
import ActivityTypeSelector from './ActivityTypeSelector';
import ActivityDateFields from './ActivityDateFields';
import ActivityFormsField from './ActivityFormsField';

const useStyles = makeStyles(styles);

const { activitySelector, activityElementsSizeSelector } = activitySelectors;
const { isLoading } = loadingSelectors;

const { getActiveClients } = clientActions;
const {
  saveActivity,
  getActivityForms,
  clearActivityElements,
  getActivityThemesByClient,
  getActivityMapsByClient,
  getActivityUsersByClient,
} = activityActions;

const criticalDateDecorator = createDecorator({
  field: 'dueDate',
  updates: (dueDate, name, { criticalDate }) => {
    const updateCriticalDate = !criticalDate || criticalDate > dueDate;
    return updateCriticalDate ? { criticalDate: dueDate } : {};
  },
});

export function ActivityEditor({
  intl,
  activity: activityProp,
  clients,
  user,
  elements,
  themes,
  maps,
  users,
  forms,
  elementsSize,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const activity = useMemo(
    () =>
      clients?.length === 1
        ? { ...activityProp, client: clients[0].id }
        : activityProp,
    [activityProp, clients]
  );

  const [dateError, setDateError] = React.useState(false);
  const [selectedForms, setSelectedForms] = React.useState(null);
  const [activityType, setActivityType] = React.useState(activity.type || '');

  const { onSecondaryDrawerToggle } = React.useContext(ActivityDrawerContext);

  const usersLoading = useSelector(s => isLoading(s, getActivityUsersByClient));
  const mapLoading = useSelector(s => isLoading(s, getActivityMapsByClient));
  const clientLoading = useSelector(s => isLoading(s, getActiveClients));

  const getActivityLists = React.useCallback(
    id => {
      dispatch(getActivityUsersByClient(id));
      dispatch(getActivityThemesByClient(id));
      dispatch(getActivityMapsByClient(id));
    },
    [dispatch]
  );

  React.useEffect(() => {
    dispatch(getActiveClients());

    activity.client && dispatch(getActivityForms(activity.client));
    activity.client && getActivityLists(activity.client);

    return () => setActivityType('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const canSetType = !activityType && activity.id;

    canSetType && setActivityType(activity.map ? VIEWS.MAP : VIEWS.ELEMENT);
  }, [activity, activityType]);

  React.useEffect(() => {
    const { forms } = activity;

    !selectedForms && !!forms && setSelectedForms(forms);
  }, [activity, selectedForms]);

  const filteredForms = React.useMemo(() => {
    if (activityType === VIEWS.ELEMENT && themes.length) {
      const [...keys] = Immutable.Map(elements).keys();
      const themeForms = themes
        .filter(item => keys.includes(item.id))
        .flatMap(item => item.formIds);

      setSelectedForms(prev =>
        !prev ? prev : prev.filter(item => themeForms.includes(item))
      );

      return forms.filter(
        item => themeForms.includes(item.formId) && item.formId !== 'oae'
      ); //TODO: POG OAE
    }

    return forms.filter(item => item.formId !== 'oae');
  }, [activityType, elements, forms, themes]);

  const handleValidate = React.useCallback(
    values => {
      const fields = [
        'title',
        'client',
        'responsible',
        'dueDate',
        'initialDate',
        'criticalDate',
      ];
      if (values.responsible && Object.keys(values.responsible).length === 0) {
        values.responsible = '';
      }
      activityType === VIEWS.MAP && !values.map && fields.push('map');
      activityType === VIEWS.ELEMENT &&
        !elementsSize &&
        fields.push('elements');

      return validate.requiredFields({ fields, values });
    },
    [activityType, elementsSize]
  );

  const handleDateError = React.useCallback((err, value) => {
    setDateError(!!err);
  }, []);

  const handleClientChanged = React.useCallback(
    id => {
      getActivityLists(id);
      dispatch(getActivityForms({ clientId: id }));
      dispatch(clearActivityElements());
    },
    [dispatch, getActivityLists]
  );

  const handleFormChange = React.useCallback(
    formIds => setSelectedForms(formIds),
    []
  );

  const handleActivityTypeChange = React.useCallback(
    ({ target }) => setActivityType(target.value),
    []
  );

  const handleActivitySave = React.useCallback(
    values => {
      const { frequency, ...restActivty } = activity;

      const newFrequency = !!values.frequency
        ? { frequency: values.frequency }
        : {};

      const author = activity.author || user;
      const map = activity.map || values.map;
      const responsible =
        !activity.responsible || Object.keys(activity.responsible).length === 0
          ? values.responsible
          : activity.responsible;

      const _activity = {
        ...restActivty,
        ...values,
        forms: selectedForms || [],
        elements,
        map,
        author,
        responsible,
        ...newFrequency,
      };

      dispatch(saveActivity(_activity));
    },
    [activity, dispatch, elements, selectedForms, user]
  );

  const renderForm = ({ handleSubmit, values }) => (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container className={classes.nowrap}>
        <Grid item xs={12} className={classes.field}>
          <Field
            name="title"
            margin="dense"
            type="text"
            label={intl.formatMessage({ id: 'LABELS.COMMONS.TITLE' })}
            component={TextFieldAdapter}
            autoComplete="off"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container className={classes.nowrap}>
        <Grid item xs={6} className={classes.field}>
          <Field
            name="client"
            loading={clientLoading}
            label={intl.formatMessage({ id: 'LABELS.COMMONS.CLIENT' })}
            component={SelectAdapter}
            options={clients}
            fullWidth
          />
          <OnChange name="client">
            {value => {
              values.map = '';
              values.responsible = {};
              handleClientChanged(value);
            }}
          </OnChange>
        </Grid>
        <Grid item xs={6} className={classes.field}>
          <Field
            fullWidth
            name="responsible"
            loading={usersLoading}
            disabled={!values.client}
            component={SelectAdapter}
            options={users}
            parse={value => (value ? { id: value } : '')}
            format={value => (value ? value.id : '')}
            label={intl.formatMessage({
              id: 'LABELS.ACTIVITIES.RESPONSIBLE',
            })}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.noWrapBottomMargin}>
        <Grid item xs={6} className={classes.noBottomMargin}>
          <ActivityTypeSelector
            value={activityType}
            onChange={handleActivityTypeChange}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.nowrap}>
        {activityType === VIEWS.MAP && (
          <Grid item xs={6} className={classes.noTopMargin}>
            <Field
              name="map"
              loading={mapLoading}
              disabled={!values.client}
              label={intl.formatMessage({ id: 'LABELS.COMMONS.MAP' })}
              component={SelectAdapter}
              options={maps}
              fullWidth
            />
          </Grid>
        )}
        {activityType === VIEWS.ELEMENT && (
          <Grid item xs={6} className={classes.noTopMargin}>
            <Badge
              className={classes.elementsContainer}
              badgeContent={elementsSize}
              classes={{ badge: classes.size }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={!values.client}
                className={classes.elements}
                onClick={!!values.client ? onSecondaryDrawerToggle : null}
                fullWidth
              >
                <AddIcon />
                <FormattedMessage id="LABELS.MAP.ADD_ELEMENT_ENTRY" />
              </Button>
            </Badge>
            <Field
              name="elements"
              subscription={{ touched: true, error: true }}
              render={({ meta: { touched, error } }) =>
                touched && error ? (
                  <span className={classes.error}>{error}</span>
                ) : null
              }
            />
          </Grid>
        )}
        <Grid item xs={6} className={classes.noTopMargin}>
          <ActivityFormsField
            forms={filteredForms}
            disabled={!values.client || !filteredForms.length}
            onChange={handleFormChange}
            selected={selectedForms}
            loading={false}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.nowrap}>
        <ActivityDateFields values={values} onError={handleDateError} />
      </Grid>
      <Grid container className={classes.nowrap}>
        <ActivityComplementaryInfoFields />
      </Grid>
      <div className={classes.footer}>
        <Button
          color="primary"
          disabled={dateError}
          variant="contained"
          type="submit"
        >
          <FormattedMessage id="LABELS.COMMONS.SAVE" />
        </Button>
      </div>
    </form>
  );

  return (
    <div className={classes.container}>
      <ActivityElementsDrawer />
      <Form
        initialValues={activity}
        render={renderForm}
        validate={handleValidate}
        onSubmit={handleActivitySave}
        decorators={[criticalDateDecorator]}
      />
    </div>
  );
}

ActivityEditor.propTypes = {
  /* activity state */
  activity: object.isRequired,
  elements: object.isRequired,
  users: array.isRequired,
  themes: array.isRequired,
  maps: array.isRequired,
  forms: array.isRequired,
  elementsSize: number.isRequired,
  /* client state */
  clients: array.isRequired,
  /* auth state */
  user: object.isRequired,
  /* react-intl */
  intl: object.isRequired,
};

const mapStateToProps = state => {
  const {
    client: { clients },
    auth: { user },
  } = state;

  const {
    activity,
    elements,
    editing: { maps, themes, users, forms },
  } = activitySelector(state);

  const elementsSize = activityElementsSizeSelector(state);

  return {
    activity,
    clients,
    user,
    elements,
    maps,
    themes,
    users,
    forms,
    elementsSize,
  };
};

export default compose(
  injectIntl,
  connect(mapStateToProps),
  React.memo
)(ActivityEditor);
