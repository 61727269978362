import components from './components';
import urls from './urls';
import types from './types';

const getRoutesSet = modules => [
  ...modules.reduce((attr, module) => {
    (module.applications || []).forEach(application => {
      attr.add(application.type);
    });
    return attr;
  }, new Set()),
];

export default modules =>
  getRoutesSet(modules).reduce((acc, routeType) => {
    !!components[routeType] &&
      acc.push(
        ...components[routeType].map(item => {
          const { component, extendedPath, ...rest } = item;
          return {
            path: `${urls[routeType]}${extendedPath || ''}`,
            component,
            ...rest,
          };
        })
      );

    return acc;
  }, components[types.default]);
