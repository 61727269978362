import { handleActions } from 'redux-actions';

import { entryActions } from 'commons/store/entry';

const initialState = {
  entries: {},
  timeline: [],
  values: [],
  details: {},
  entryRequestConfirm: {
    open: false,
  },
};

const takeOutOfList = (list, targetId) =>
  list.filter(item => item.id !== targetId);

export default handleActions(
  {
    [entryActions.updateEntries]: (state, { payload }) => ({
      ...state,
      entries: payload,
    }),
    [entryActions.updateEntryDetails]: (state, { payload }) => ({
      ...state,
      details: payload,
    }),
    [entryActions.updateEntryValues]: (state, { payload }) => ({
      ...state,
      values: payload,
    }),
    [entryActions.deleteEntryFromList]: (state, { payload }) => ({
      ...state,
      entries: {
        ...state.entries,
        values: takeOutOfList(state.entries.values, payload),
      },
    }),
    [entryActions.updateHistory]: (state, { payload }) => ({
      ...state,
      timeline: payload,
    }),
    [entryActions.deleteEntries]: state => initialState,
    [entryActions.deleteEntryDetails]: state => ({
      ...state,
      details: {},
      timeline: [],
    }),
    [entryActions.showEntryRequestConfirm]: (
      state,
      { payload: { serviceName } }
    ) => ({
      ...state,
      entryRequestConfirm: {
        ...state.entryRequestConfirm,
        open: true,
        serviceName,
      },
    }),
    [entryActions.confirmEntryRequest]: state => ({
      ...state,
      entryRequestConfirm: {
        open: false,
      },
    }),
    [entryActions.cancelEntryRequest]: state => ({
      ...state,
      entryRequestConfirm: {
        open: false,
      },
    }),
  },
  initialState
);
