import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import historyFunc from 'commons/history';

import middlewares from './middlewares';
import reducers from './reducers';

export const config = {
  history: null,
  store: null,
};

const configureStore = (initialState = {}) => {
  const windowExtensionCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = windowExtensionCompose || compose;

  config.history = historyFunc();

  const middlewareForSaga = createSagaMiddleware();
  const middlewareForRouter = routerMiddleware(config.history);

  const appliedMiddleware = applyMiddleware(
    thunk,
    middlewareForSaga,
    middlewareForRouter
  );

  const composed =
    process.env.NODE_ENV === 'production'
      ? appliedMiddleware
      : composeEnhancers(appliedMiddleware);

  config.store = createStore(reducers(config.history), initialState, composed);

  if (module.hot && process.env.NODE_ENV !== 'production') {
    module.hot.accept('commons/store/reducers', () =>
      config.store.replaceReducer(reducers(config.history))
    );
  }

  middlewareForSaga.run(middlewares);

  return config;
};

export default configureStore;
