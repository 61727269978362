import React from 'react';

import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

export const SelectAdapter = ({
  value = '',
  name,
  label,
  options = [],
  onChange,
  className,
  disabled,
  optionLabelExtractor = ({ name }) => name,
  ...restProps
}) => {
  return (
    <FormControl
      fullWidth
      className={className}
      margin="normal"
      disabled={!!disabled}
    >
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        input={<Input name={name} id={name} />}
        name={name}
        displayEmpty
        {...restProps}
      >
        <MenuItem value="" disabled />
        {options.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {optionLabelExtractor(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
