export default {
  field: {
    margin: '.4rem .8rem',
  },
  recurrentWrap: {
    marginTop: '1rem',
  },
  description: {
    margin: '0 .8rem .5rem 0',
  },
  imageSelector: {
    margin: '.8rem',
    display: 'flex',
    justifyContent: ' flex-end',
  },
  imageHolder: {
    maxHeight: '20rem',
  },
  imageUploader: {
    width: '100%',
  },
};
