import { createAction } from 'redux-actions';

export default {
  getLayerTypes: createAction('/layerType/GET_LAYER_TYPES'),
  updateLayerTypesList: createAction('layerType/UPDATE_LAYER_TYPES_LIST'),
  getLayerTypeDetails: createAction('layerType/GET_LAYER_TYPE_DETAILS'),
  saveLayerTypeAsCurrent: createAction('layerType/SAVE_LAYER_TYPE_AS_CURRENT'),
  clearLayerTypes: createAction('layerType/CLEAR_LAYER_TYPES'),
  deleteLayerType: createAction('layerType/DELETE_LAYER_TYPE'),
  removeLayerTypeFromList: createAction(
    'layerType/REMOVE_LAYER_TYPE_FROM_LIST'
  ),
  addNewLayerType: createAction('layerType/ADD_NEW_LAYER_TYPE'),
  saveLayerType: createAction('layerType/SAVE_LAYER_TYPE'),
  addLayerTypeToList: createAction('layerType/ADD_LAYER_TYPE_TO_LIST'),
  updateLayerType: createAction('layerType/UPDATE_LAYER_TYPE'),
  updateLayerTypeInList: createAction('layerType/UPDATE_LAYER_TYPE_IN_LIST'),
  cancelLayerTypeEditing: createAction('layerType/CANCEL_LAYER_TYPE_EDITING'),
};
