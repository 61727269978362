import axios from 'axios';

export default {
  getMaps: text => axios.get('/maps', text ? { params: { q: text } } : null),

  getMap: id => axios.get(`/maps/${id}/details`),

  getAttrs: id => axios.get(`/maps/${id}/attributes`),

  saveMap: data => axios.post('/maps', data),

  deleteMap: mapId => axios.delete(`/maps/${mapId}`),

  getMapUsersAssociation: mapId => axios.get(`maps/${mapId}/users/association`),

  saveMapUsersAssociation: ({ mapId, users }) =>
    axios.put(`maps/${mapId}/users/association`, users),

  getRecentlyLayers: () => axios.get('/maps/layers/recently'),

  deleteErrorLayer: layer => axios.delete(`/maps/layers/${layer}`),

  patchBoundBox: ({ id, bound: bbox }) =>
    axios.patch(`/maps/${id}`, { id, bbox }),

  saveMapType: ({ mapId, type }) =>
    axios.put(`/maps/${mapId}/preferences/type`, type, {
      headers: {
        'Content-Type': 'text/plain',
      },
    }),

  updateMapBbox: ({ mapId, bbox }) =>
    axios.put(`/maps/${mapId}/bbox`, { bbox }),

  deleteMapAreaType: id => axios.delete(`/maps/area-types/${id}`),

  saveMapAreaType: areaType => axios.post('/maps/area-types', areaType),

  getMapPreferences: mapId => axios.get(`/maps/${mapId}/preferences`),

  getMapAreaTypes: () => axios.get(`/maps/area-types`),

  saveAttr: ({ id, attributes }) =>
    axios.post(`/maps/${id}/attributes`, attributes),

  saveMapViews: ({ mapId, views }) => axios.put(`/maps/${mapId}/views`, views),

  filterMapActivities: ({ mapId, elementId, filter }) =>
    axios.post(`/maps/${mapId}/activities`, filter, { params: { elementId } }),

  getMapsByClientId: ({ id, text }) =>
    axios.get(`/maps/filter/${id}`, text ? { params: { q: text } } : null),

  getMapViews: ({ mapId, elementId }) =>
    axios.get(`/maps/${mapId}/views`, { params: { elementId } }),
};
