import React from 'react';
import { object, string, bool, func, oneOfType } from 'prop-types';

import cx from 'clsx';

import Backup from '@material-ui/icons/Backup';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';

import { fileUploader } from 'commons/uploader';

import styles from './styles';

export class ImageUploader extends React.PureComponent {
  static propTypes = {
    /* own props */
    value: oneOfType([string, object]),
    name: string,
    showUploadButton: bool,
    className: string,
    buttonClassName: string,
    onChange: func.isRequired,
    /* material-ui styles */
    classes: object.isRequired,
  };

  static defaultProps = {
    className: '',
    showUploadButton: true,
    name: 'image',
  };

  state = {
    uploading: false,
  };

  constructor() {
    super();
    this.inputFile = React.createRef();
  }

  extractImageSrc = image => {
    if (!!image && typeof image === 'string') {
      return image;
    }
    const { preview, link } = image || {};

    return (
      preview || link || `${process.env.PUBLIC_URL}/images/blank_image.png`
    );
  };

  handleImageUpload = async data => {
    try {
      const image = await fileUploader(data);
      this.setState({ uploading: false });
      this.props.onChange(image);
    } catch (e) {
      this.inputFile.current.value = '';
      this.setState({ uploading: false });
    }
  };

  handleFileSelect = e => {
    const { uploading } = this.state;
    if (uploading) {
      e.preventDefault();
      return;
    }
    this.inputFile.current.click();
  };

  handleImageChange = ({ target }) => {
    const reader = new FileReader();

    reader.readAsDataURL(target.files[0]);

    reader.onloadend = () => {
      let data = new FormData();
      data.append('file', target.files[0]);
      this.setState({ uploading: true });
      this.handleImageUpload(data);
    };
  };

  renderUploadButton = () => {
    const { classes, buttonClassName } = this.props;
    const { uploading } = this.state;

    return (
      <Button
        variant="contained"
        color="primary"
        className={cx(classes.button, buttonClassName)}
        onClick={this.handleFileSelect}
      >
        {uploading ? (
          <CircularProgress size={24} className={classes.buttonLoading} />
        ) : (
          <Backup />
        )}
      </Button>
    );
  };

  render() {
    const { classes, className, value, showUploadButton } = this.props;
    const { uploading } = this.state;

    return (
      <div className={cx(classes.container, className)}>
        <div className={classes.upload}>
          {!showUploadButton && uploading && (
            <div className={classes.loadingContainer}>
              <CircularProgress size={28} className={classes.imageLoading} />
            </div>
          )}
          <img
            className={cx(classes.image, { clickable: !showUploadButton })}
            onClick={!showUploadButton ? this.handleFileSelect : null}
            src={this.extractImageSrc(value)}
            alt="PhotoHolder"
          />
          <input
            accept="image/*"
            type="file"
            ref={this.inputFile}
            style={{ display: 'none' }}
            onChange={this.handleImageChange}
          />
        </div>
        {showUploadButton && this.renderUploadButton()}
      </div>
    );
  }
}

export default withStyles(styles)(ImageUploader);
