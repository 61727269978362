import React from 'react';
import { bool, object, any, string, func } from 'prop-types';

import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { Selector } from 'commons/form';

export class MultiDialog extends React.Component {
  static defaultProps = {
    open: false,
    text: '',
  };

  static defaultProps = {
    text: '',
    showName: true,
    confirmText: '',
    cancelText: '',
  };

  static propTypes = {
    code: string,
    text: string,
    title: string,
    value: any,
    field: string,
    open: bool.isRequired,
    options: object,
    confirmText: string,
    cancelText: string,
    onClose: func,
    onCancel: func,
    onConfirm: func,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (typeof prevState.value === 'undefined') {
      return { value: nextProps.value, code: nextProps.code };
    }
    if (!nextProps.open) {
      return { selectedOption: '', value: undefined, code: undefined };
    }
    return null;
  }

  state = { selectedOption: '' };

  handleValueChange = ({ target }) => this.setState({ value: target.value });

  handleCodeChange = ({ target }) => this.setState({ code: target.value });

  handleOptionSelect = e => this.setState({ selectedOption: e.target.value });

  handleKeyPress = e => e.charCode === 13 && this.handleConfirm();

  handleCancel = () => {
    const { onClose, onCancel } = this.props;

    !!onClose && onClose();
    !!onCancel && onCancel();

    this.setState({ selectedOption: '' });
  };

  handleConfirm = () => {
    const { options } = this.props;
    let { selectedOption, value, code } = this.state;

    if (!selectedOption && options) {
      selectedOption = options.selected;
    }

    this.props.onConfirm({
      selectedOption,
      name: value || this.props.value,
      code: code || this.props.code,
    });

    this.setState({ selectedOption: '' });
  };

  renderForm = () => {
    const { intl, code, field, showName, options } = this.props;

    return (
      <form style={{ minWidth: '22rem' }}>
        {typeof code !== 'undefined' && (
          <TextField
            autoFocus
            fullWidth
            id="code"
            label={intl.formatMessage({ id: 'LABELS.COMMONS.CODE' })}
            value={this.state.code || ''}
            onChange={this.handleCodeChange}
            style={{ minWidth: '26em' }}
            margin="dense"
          />
        )}
        {showName && (
          <TextField
            autoFocus
            fullWidth
            id="value"
            label={field || intl.formatMessage({ id: 'LABELS.COMMONS.NAME' })}
            value={this.state.value || ''}
            onChange={this.handleValueChange}
            onKeyPress={this.handleKeyPress}
            style={{ minWidth: '26em' }}
            margin="dense"
          />
        )}
        {!!options && (
          <Selector
            name="option"
            value={this.state.selectedOption || options.selected || ''}
            label={options.title}
            onChange={this.handleOptionSelect}
            options={options.values}
          />
        )}
      </form>
    );
  };

  renderText = () => {
    const { text = '' } = this.props;

    return <DialogContentText>{text}</DialogContentText>;
  };

  render() {
    const { open, title, confirmText, cancelText, text, onCancel } = this.props;

    return open ? (
      <Dialog open={open} onClose={this.handleClose}>
        {!!title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          {!text ? this.renderForm() : this.renderText()}
        </DialogContent>
        <DialogActions>
          {!!onCancel && (
            <Button onClick={this.handleCancel} color="default">
              <FormattedMessage id={cancelText || 'LABELS.COMMONS.CANCEL'} />
            </Button>
          )}
          <Button
            color={!!confirmText ? 'default' : 'primary'}
            disabled={!text && !this.state.value && !this.state.selectedOption}
            onClick={this.handleConfirm}
          >
            <FormattedMessage id={confirmText || 'LABELS.COMMONS.CONFIRM'} />
          </Button>
        </DialogActions>
      </Dialog>
    ) : null;
  }
}

export default compose(injectIntl)(MultiDialog);
