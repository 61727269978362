import React from 'react';
import TextField from '@material-ui/core/TextField';

export const TextFieldAdapter = ({
  input: { name, onChange, ...restInput },
  select,
  value,
  meta,
  ...rest
}) => (
  <TextField
    {...rest}
    name={name}
    helperText={meta.touched ? meta.error || meta.submitError : undefined}
    error={(meta.error || meta.submitError) && meta.touched}
    inputProps={restInput}
    onChange={onChange}
    select={select}
    value={value || restInput.value}
  />
);
