import produce from 'immer';

import { handleActions } from 'redux-actions';

import { DEFAULT_MAP_STYLES } from 'commons/constants';
import { mapActions } from 'commons/store/map';
import { mapThemeActions } from 'commons/store/mapTheme';

/* selectors */
const mapViewsSelector = ({ map }) => map.views;
const mapSelector = ({ map }) => map.map;
const mapWithoutCurrentMapSelector = ({ maps, map }) =>
  maps.filter(item => item.id !== map.id);

const getRecentlyLayersSelector = ({ map }) => map.recentlyLayers;

export const selectors = {
  mapViewsSelector,
  mapSelector,
  getRecentlyLayersSelector,
  mapWithoutCurrentMapSelector,
};

/* state */
const previousStack = {
  elementId: '',
  previousMap: {},
};

const initialState = {
  maps: [],
  map: {},
  views: [],
  activeView: 0,
  areaTypes: [],
  recentlyLayers: [],
  usersAssociation: {},
  previousStack,
  mapPreferences: {
    type: 'roadmap',
    activeLayers: [],
    styles: DEFAULT_MAP_STYLES,
  },
};

/* handler */
export default handleActions(
  {
    [mapActions.restoreState]: (_, { payload }) => payload,

    [mapActions.clearMap]: ({ maps }) => ({ ...initialState, maps }),

    [mapActions.savePreviousMapStack]: produce((draft, { payload }) => {
      draft.previousStack = payload;
    }),

    [mapActions.clearPreviousMapStack]: produce((draft, { payload }) => {
      draft.previousStack = previousStack;
    }),

    [mapActions.saveCurrentView]: produce((draft, { payload }) => {
      draft.activeView = payload;
    }),

    [mapActions.updateMapAreaTypes]: produce((draft, { payload }) => {
      draft.areaTypes = payload;
    }),

    [mapActions.updateRecentlyCreatedLayers]: produce((draft, { payload }) => {
      draft.recentlyLayers = payload;
    }),

    [mapActions.updateMapUsersAssociation]: produce((draft, { payload }) => {
      draft.usersAssociation = payload;
    }),

    [mapActions.clearMapUsersAssociation]: produce(draft => {
      draft.usersAssociation = {};
    }),

    [mapActions.updateMapPreferences]: produce((draft, { payload }) => {
      draft.mapPreferences = {
        styles: DEFAULT_MAP_STYLES,
        ...payload,
      };
    }),

    [mapActions.deleteMapFromList]: produce((draft, { payload }) => {
      draft.maps.splice(draft.maps.findIndex(item => item.id !== payload));
    }),

    [mapActions.updateMaps]: produce((draft, { payload }) => {
      draft.maps = payload;
    }),

    [mapActions.updateMap]: produce((draft, { payload }) => {
      draft.map = payload;
    }),

    [mapActions.updateMapViews]: produce((draft, { payload }) => {
      const { views, map } = payload;

      draft.views = views.map(({ data, ...rest }) => rest);
      draft.map = map;
      draft.activeView = 0;
    }),
    [mapThemeActions.deleteElementFromTheme]: produce((draft, { payload }) => {
      if (
        draft.previousStack.mapTheme &&
        draft.previousStack.mapTheme.themes &&
        draft.previousStack.mapTheme.themes[payload.themeId]
      ) {
        const elements =
          draft.previousStack.mapTheme.themes[payload.themeId].elements;

        draft.previousStack.mapTheme.themes[
          payload.themeId
        ].elements = elements.filter(item => item.id !== payload.id);
      }
    }),
  },
  initialState
);
