import { takeEvery, putResolve, select, fork } from 'redux-saga/effects';

import api from 'commons/api';
import notification from 'commons/notification';
import { mapThemeService, formService } from 'commons/services';
import { getCoordinateFromXY } from 'commons/geometry';
import { loadingSaga } from 'commons/store/loading';
import { mapElementActions } from 'commons/store/mapElement';
import { mapThemeActions } from 'commons/store/mapTheme';

import FileSaver from 'file-saver';

function* getElementSchema({ payload }) {
  try {
    const { data } = yield formService.getSchema(payload);
    yield putResolve(mapThemeActions.updateEditingElement({ schema: data }));
  } catch (err) {
    yield notification('MESSAGES.MAP.COULD_NOT_GET_ELEMENT_ENTRY_DETAILS', {
      type: 'error',
    });
  }
}

function* getElementDetails({ payload }) {
  const { element, theme } = payload;
  const { elementTypeId } = element;

  try {
    const { data } = yield mapThemeService.getElementDetails(element);

    const category = theme.categories.find(({ elementTypes }) =>
      elementTypes.find(type => type.id === elementTypeId)
    );

    yield fork(getElementSchema, { payload: data.formId });

    yield putResolve(
      mapThemeActions.updateEditingElement({
        theme,
        element: data,
        location: getCoordinateFromXY(element.x, element.y),
        category: category.id,
        elementType: elementTypeId,
      })
    );
  } catch (err) {
    yield notification('MESSAGES.MAP.COULD_NOT_GET_ELEMENT_ENTRY_DETAILS', {
      type: 'error',
    });
  }
}

function* exportElement({ payload }) {
  yield* api(
    mapThemeService.exportElement,
    payload,
    function*({ headers, data }) {
      const fileName = yield headers['content-disposition']
        .split(';')[1]
        .split('=')[1];

      FileSaver.saveAs(
        new Blob([data], { type: headers['content-type'] }),
        fileName
      );
    },
    err => console.log(err),
    { entireResponse: true }
  );
}

function* saveElement({ payload }) {
  try {
    const { image } = payload;
    const { element } = yield select(({ mapElement }) => mapElement);

    const newElement = { ...payload, image: !!image.id ? image.id : image };

    const { data } = yield mapThemeService[
      !payload.id ? 'saveElement' : 'updateElement'
    ](newElement);

    yield notification('MESSAGES.MAP.SUCCESSFUL_SAVED_ENTRY', {
      type: 'success',
    });

    newElement.id = data;

    if (element.id) {
      yield putResolve(mapElementActions.updateElement(newElement));
      !!payload.callBack && payload.callBack(newElement);
      return;
    }

    if (payload.id) {
      yield putResolve(mapThemeActions.updateElementInTheme(newElement));
      !!payload.callBack && payload.callBack(newElement);
      return;
    }

    yield putResolve(mapThemeActions.addElementToTheme(newElement));
    !!payload.callBack && payload.callBack(newElement);
  } catch (err) {
    console.log(err);
    yield notification('MESSAGES.MAP.COULD_NOT_SAVE_ELEMENT_ENTRY', {
      type: 'error',
    });
  }
}

function* deleteElement({ payload }) {
  try {
    yield mapThemeService.deleteElement(payload);
    yield putResolve(mapThemeActions.deleteElementFromTheme(payload));
  } catch (err) {
    console.log(err);
    yield notification('MESSAGES.MAP.COULD_NOT_DELETE_ELEMENT_ENTRY', {
      type: 'error',
    });
  }
}

function* updateElementStatus({
  payload: { theme, element, statusId, elementStatusHistory },
}) {
  yield* api(
    mapThemeService.updateElementStatus,
    {
      themeId: theme.id,
      elementId: element.id,
      statusId,
      elementStatusHistory,
    },
    function*() {
      yield notification('MESSAGES.COMMONS.SUCCESS', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification(
        'MESSAGES.ELEMENTS.STATUS_HISTORY.COULD_NOT_UPDATE_ELEMENT_STATUS',
        { type: 'error' }
      );
    }
  );
}

export default function*() {
  yield takeEvery(
    mapThemeActions.getElementSchema.toString(),
    loadingSaga(getElementSchema)
  );
  yield takeEvery(
    mapThemeActions.getElementDetails.toString(),
    loadingSaga(getElementDetails)
  );
  yield takeEvery(
    mapThemeActions.exportElement.toString(),
    loadingSaga(exportElement)
  );
  yield takeEvery(
    mapThemeActions.saveElement.toString(),
    loadingSaga(saveElement)
  );
  yield takeEvery(
    mapThemeActions.deleteElement.toString(),
    loadingSaga(deleteElement)
  );
  yield takeEvery(
    mapThemeActions.updateElementStatus.toString(),
    loadingSaga(updateElementStatus)
  );
}
