import React from 'react';

import cx from 'clsx';
import { object, string } from 'prop-types';

import LayersIcon from '@material-ui/icons/Layers';
import DeleteIcon from '@material-ui/icons/Delete';
import AlarmIcon from '@material-ui/icons/Alarm';
import AlarmErrorIcon from '@material-ui/icons/AlarmOff';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';

import { LAYER_STATUS } from 'commons/constants';
import { RecentLayersContext } from 'commons/contexts';

import styles from './styles';
import { ListItemSecondaryAction } from '@material-ui/core';

const useStyles = makeStyles(styles);

export function LayerStatusItem({ layer, map }) {
  const classes = useStyles();

  const { onDeleteLayer } = React.useContext(RecentLayersContext);

  const isReady = ({ status }) => status === LAYER_STATUS.READY;

  const hasError = ({ status }) => status === LAYER_STATUS.ERROR;

  const getLayerIcon = layer => {
    if (isReady(layer)) {
      return <LayersIcon />;
    }
    if (hasError(layer)) {
      return <AlarmErrorIcon />;
    }
    return <AlarmIcon />;
  };

  const handleLayerDelete = () => onDeleteLayer(layer.id, map);

  return (
    <ListItem>
      <ListItemIcon
        className={cx(classes.icon, {
          active: isReady(layer),
          error: hasError(layer),
        })}
      >
        {getLayerIcon(layer)}
      </ListItemIcon>
      <ListItemText
        primary={layer.name}
        primaryTypographyProps={{
          style: { fontWeight: '500' },
        }}
        secondary={hasError(layer) ? layer.error : ''}
        secondaryTypographyProps={{
          style: { color: hasError(layer) ? '#f44336' : '#fff' },
        }}
      />
      {hasError(layer) && (
        <ListItemSecondaryAction>
          <IconButton onClick={handleLayerDelete} aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

LayerStatusItem.propTypes = {
  /* own props */
  layer: object.isRequired,
  map: string.isRequired,
};

export default React.memo(LayerStatusItem);
