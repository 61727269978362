import { takeEvery, put } from 'redux-saga/effects';

import api from 'commons/api';
import notification from 'commons/notification';
import { approvalService } from 'commons/services';
import { approvalActions } from 'commons/store/approval';

function* getRequests() {
  yield* api(
    approvalService.getRequests,
    {},
    approvalActions.updateRequests,
    function*(err) {
      yield notification('MESSAGES.APPROVAL.COULD_NOT_GET_REQUESTS', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* getRequestDetails({ payload: id }) {
  yield* api(
    approvalService.getRequestDetails,
    id,
    approvalActions.updateRequestDetails,
    function*(err) {
      yield notification('MESSAGES.APPROVAL.COULD_NOT_GET_REQUEST_DETAILS', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* approveRequest({ payload }) {
  yield* api(
    approvalService.approveRequest,
    payload,
    function*(id) {
      yield put(approvalActions.getRequests());
      yield put(approvalActions.getRequestDetails(id));
    },
    function*(err) {
      yield notification('MESSAGES.APPROVAL.COULD_NOT_APPROVE_REQUEST', {
        type: 'error',
      });
    }
  );
}

function* disapproveRequest({ payload }) {
  yield* api(
    approvalService.disapproveRequest,
    payload,
    function*(id) {
      yield put(approvalActions.getRequests());
      yield put(approvalActions.getRequestDetails(id));
    },
    function*(err) {
      yield notification('MESSAGES.APPROVAL.COULD_NOT_APPROVE_REQUEST', {
        type: 'error',
      });
    }
  );
}

export default function*() {
  yield takeEvery(approvalActions.getRequests.toString(), getRequests);
  yield takeEvery(
    approvalActions.getRequestDetails.toString(),
    getRequestDetails
  );
  yield takeEvery(approvalActions.approveRequest.toString(), approveRequest);
  yield takeEvery(
    approvalActions.disapproveRequest.toString(),
    disapproveRequest
  );
}
