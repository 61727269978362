import React from 'react';
import { bool, func, object } from 'prop-types';

import cx from 'clsx';

import { Link } from 'react-router-dom';
import { replaceParams } from 'commons/history';
import routes from 'commons/routes';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

export function NestedApplication({ active, application, onSelect }) {
  const classes = useStyles();

  let to;
  if (application?.type) {
    to = replaceParams(routes.urls[(application?.type)], {
      id: application.id,
    });
  }
  const href = application?.url ?? undefined;

  const handleSelect = React.useCallback(() => onSelect(application), [
    application,
    onSelect,
  ]);

  return (
    <ListItemContainer
      to={to}
      href={href}
      className={cx(classes.container, { active })}
      onClick={handleSelect}
    >
      <ListItemText
        disableTypography
        className={cx(classes.text, { active })}
        primary={application.name}
      />
    </ListItemContainer>
  );
}

function ListItemContainer({ to, href, onClick, children, className }) {
  if (to) {
    return (
      <ListItem
        button
        to={to}
        component={Link}
        className={className}
        onClick={onClick}
      >
        {children}
      </ListItem>
    );
  }

  return (
    <ListItem
      button
      href={href}
      component="a"
      className={className}
      onClick={onClick}
    >
      {children}
    </ListItem>
  );
}

NestedApplication.propTypes = {
  /* own props */
  application: object.isRequired,
  active: bool.isRequired,
  onSelect: func.isRequired,
};

export default React.memo(NestedApplication);
