import { createAction } from 'redux-actions';

export default {
  getAllForms: createAction('form/GET_ALL_FORMS'),
  updateFormsList: createAction('form/UPDATE_FORMS_LIST'),
  deleteFormsList: createAction('form/DELETE_FORMS_LIST'),
  getForm: createAction('form/GET_FORM'),
  updateForm: createAction('form/GET_FORM_SUCCESS'),
  getFormFields: createAction('form/GET_FORM_FIELDS'),
  updateFormFields: createAction('form/UPDATE_FORM_FIELDS'),
  deleteFormFields: createAction('form/DELETE_FORM_FIELDS'),
  updateFormValue: createAction('form/UPDATE_FORM_VALUE'),
  deleteForm: createAction('form/DELETE_FORM'),
  deleteApplicationForms: createAction('form/DELETE_APPLICATION_FORMS'),
};
