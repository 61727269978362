import React, { useCallback } from 'react';
import Autocomplete, { createFilter } from 'react-select';

import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';

import Control from './Control';
import Menu from './Menu';
import MenuList from './MenuList';
import NoOptionsMessage from './NoOptionsMessage';
import Option from './Option';
import Placeholder from './Placeholder';
import SingleValue from './SingleValue';
import ValueContainer from './ValueContainer';

export default function SelectAutocomplete({
  name,
  label,
  options,
  value,
  valueFunction,
  onChange,
  disabled,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const _valueFunction = useCallback(
    (value, option) => {
      if (!valueFunction) {
        return option.value === value;
      }

      return valueFunction(value, option);
    },
    [valueFunction]
  );

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  const _value = React.useMemo(
    () => (!!value ? options.find(o => _valueFunction(value, o)) : ''),
    [options, value, _valueFunction]
  );

  return (
    <Autocomplete
      value={_value}
      classes={classes}
      onChange={onChange}
      options={options}
      filterOption={createFilter({ ignoreAccents: false })}
      components={components}
      styles={selectStyles}
      placeholder=""
      inputId={name}
      TextFieldProps={{
        label,
        InputLabelProps: {
          htmlFor: name,
          shrink: true,
        },
      }}
      isDisabled={!!disabled}
    />
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250,
    minWidth: 290,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

const components = {
  Control,
  Menu,
  MenuList,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};
