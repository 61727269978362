import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/styles';

import Loading from 'commons/components/Loading';
import {
  ActivityElementsContext,
  ActivityDrawerContext,
} from 'commons/contexts';
import { activitySelectors, activityActions } from 'commons/store/activity';
import { loadingSelectors } from 'commons/store/loading';

import FilterHeader from './FilterHeader';
import ElementsList from './ElementsList';
import ActivityElementItem from './ActivityElementItem';

import styles from './styles';

const useStyles = makeStyles(styles);

const { isLoading } = loadingSelectors;
const { activityElementsSelector } = activitySelectors;
const {
  filterElements,
  updateElementsBuffer,
  addElementsToActivity,
  removeElementsFromActivity,
} = activityActions;

const initialFilter = {
  theme: {},
  category: {},
  elementType: {},
};

export function ActivityElementsDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [filter, setFilter] = React.useState(initialFilter);
  const [selected, setSelected] = React.useState([]);

  const { elementsDrawerOpen, onSecondaryDrawerToggle } = React.useContext(
    ActivityDrawerContext
  );

  const elementsLoading = useSelector(s => isLoading(s, filterElements));
  const activityElements = useSelector(activityElementsSelector);
  const { elementsBuffer } = useSelector(({ activity }) => activity);
  const {
    editing: { themes },
  } = useSelector(({ activity }) => activity);

  React.useEffect(() => {
    const elements = activityElements.find(
      item => item.theme.id === filter.theme.id
    );
    setSelected(prev =>
      !!elements && !elements.length ? elements.elementIds : prev
    );
  }, [activityElements, filter.theme]);

  const handleFilterChange = React.useCallback(
    ({ name, value }) => {
      setSelected([]);
      setFilter(prev => {
        const nextFilter = { ...prev, [name]: value };
        dispatch(filterElements(nextFilter));

        return nextFilter;
      });
    },
    [dispatch]
  );

  const handleEdit = React.useCallback(
    (theme, elementIds) => {
      const _theme = themes.find(item => item.id === theme.id);
      handleFilterChange({ name: 'theme', value: _theme });
      setSelected(elementIds);
    },
    [handleFilterChange, themes]
  );

  const handleDelete = React.useCallback(
    id => dispatch(removeElementsFromActivity(id)),
    [dispatch]
  );

  const handleAdd = React.useCallback(() => {
    const { theme } = filter;
    const { id, name } = theme;

    dispatch(
      addElementsToActivity({ theme: { id, name }, elementIds: selected })
    );
  }, [dispatch, filter, selected]);

  const handleElementSelect = React.useCallback(id => {
    setSelected(prev => {
      const _seleted = new Set(prev);
      _seleted.has(id) ? _seleted.delete(id) : _seleted.add(id);

      return Array.from(_seleted);
    });
  }, []);

  const handleClose = () => {
    dispatch(updateElementsBuffer([]));
    setFilter(initialFilter);
    setSelected([]);
    onSecondaryDrawerToggle();
  };

  return (
    <Drawer
      anchor="right"
      ModalProps={{
        BackdropProps: {
          classes: {
            root: classes.backdrop,
          },
        },
      }}
      open={elementsDrawerOpen}
      onClose={handleClose}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h6">
            <FormattedMessage variant="h6" id="LABELS.MAP.LIST_OF_ELEMENTS" />
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </div>
        <div className={classes.content}>
          <div className={classes.elementsBuffer}>
            <FilterHeader filter={filter} onChange={handleFilterChange} />
            <ActivityElementsContext.Provider
              value={{ onSelect: handleElementSelect, selected }}
            >
              {elementsLoading ? (
                <Loading />
              ) : (
                <ElementsList elements={elementsBuffer} onAdd={handleAdd} />
              )}
            </ActivityElementsContext.Provider>
          </div>
          <div className={classes.activityElements}>
            <div className={classes.activityElementsLabel}>
              <FormattedMessage id="LABELS.ACTIVITIES.SELECTED_ELEMENTS" />
            </div>
            <List>
              {activityElements.map(({ theme, elementIds }) => (
                <ActivityElementItem
                  key={theme.id}
                  theme={theme}
                  elements={elementIds}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              ))}
            </List>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default ActivityElementsDrawer;
