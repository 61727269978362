import React from 'react';
import { object, array, func } from 'prop-types';

import Dropzone from 'react-fine-uploader/dropzone';
import FileInput from 'react-fine-uploader/file-input';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'recompose';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import Delete from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

export function FilesDropzone({
  intl,
  submittedFiles,
  uploader,
  onSubmitted,
  onDelete,
}) {
  const classes = useStyles();

  React.useEffect(() => {
    uploader.on('submitted', onSubmitted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    uploader.on('submitted', onSubmitted);
  }, [onSubmitted, uploader]);

  const renderFileInput = () => {
    return (
      <FileInput
        multiple
        accept="*/*"
        className={classes.fileInput}
        uploader={uploader}
        text={{
          selectFiles: intl.formatMessage({
            id: 'LABELS.FILES.CLICK_HERE_TO_SELECT_FILES',
          }),
        }}
      />
    );
  };

  const renderSubmittedFile = file => {
    return (
      <React.Fragment key={file.id}>
        <ListItem>
          <ListItemText
            classes={{ secondary: classes.secondary }}
            primary={file.name}
            secondary={
              file.duplicated
                ? intl.formatMessage({
                    id: 'LABELS.COMMONS.DUPLICATED_FILE_WARNING',
                  })
                : null
            }
          />
          <ListItemSecondaryAction>
            <IconButton onClick={() => onDelete(file.id)}>
              <Delete />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </React.Fragment>
    );
  };

  return (
    <div className={classes.container}>
      <Dropzone multiple className={classes.dropzone} uploader={uploader}>
        {renderFileInput()}
        {!submittedFiles.length && (
          <Typography variant="h6" className={classes.dropFileText}>
            <FormattedMessage id="LABELS.COMMONS.DRAG_FILES_TO_HERE" />
          </Typography>
        )}
        <div className={classes.uploadedFiles}>
          <List dense>{submittedFiles.map(renderSubmittedFile)}</List>
        </div>
      </Dropzone>
    </div>
  );
}

FilesDropzone.propTypes = {
  /* own props */
  submittedFiles: array.isRequired,
  uploader: object.isRequired,
  onSubmitted: func.isRequired,
  onDelete: func.isRequired,
};

export default compose(
  injectIntl,
  React.memo
)(FilesDropzone);
