import { createAction } from 'redux-actions';

export default {
  showGlobalLoading: createAction('app/SHOW_GLOBAL_LOADING'),
  hideGlobalLoading: createAction('app/HIDE_GLOBAL_LOADING'),
  showGlobalDrawer: createAction('app/SHOW_GLOBAL_DRAWER'),
  hideGlobalDrawer: createAction('app/HIDE_GLOBAL_DRAWER'),
  getModules: createAction('app/GET_MODULES'),
  updateModules: createAction('app/GET_MODULES_SUCCESS'),
  getAppSettings: createAction('app/GET_APP_SETTINGS'),
  updateAppSettings: createAction('app/GET_APP_SETTINGS_SUCCESS'),
  saveCurrentApplication: createAction('app/SAVE_CURRENT_APPLICATION'),
  clearCurrentApplication: createAction('app/CLEAR_CURRENT_APPLICATION'),
  clearApp: createAction('app/CLEAR_APP'),
  initApp: createAction('app/INIT_APP'),
  showUploadsDrawer: createAction('file/SHOW_UPLOADS_DRAWER'),
  hideUploadsDrawer: createAction('file/HIDE_UPLOADS_DRAWER'),
  getUsedDailyLimit: createAction('app/GET_USED_DAILY_LIMIT'),
};
