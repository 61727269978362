import React from 'react';
import { object, func, number, array } from 'prop-types';

import { IntlProvider } from 'react-intl';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/styles';
import { BunchThemeProvider } from 'commons/layout';

import Loading from 'commons/components/Loading';
import background from 'commons/background';
import { getIntlProviderConfig } from 'commons/locale';

import { appActions } from 'commons/store/app';

import styles from './styles';

export class App extends React.PureComponent {
  static propTypes = {
    /* own props */
    settings: object.isRequired,
    loading: number.isRequired,
    modules: array.isRequired,
    location: object.isRequired,
    /* app actions */
    initApp: func.isRequired,
    /* material-ui styles */
    classes: object.isRequired,
  };

  state = {
    bg: '',
  };

  timeInterval = null;

  componentDidMount() {
    this.props.initApp();

    this.timeInterval = setInterval(() => {
      this.setState({
        bg: background(this.props.settings),
      });
    }, 1 * 30 * 60 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  getBackground = () => {
    const { settings } = this.props;
    const { bg } = this.state;

    const hasSettings = !!Object.keys(settings.locales || {}).length;

    if (hasSettings) {
      this.configureWindow();
      return bg || background(settings);
    }

    return '';
  };

  configureWindow = () => {
    const {
      settings: { favicon, title },
    } = this.props;
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');

    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = favicon;
    document.getElementsByTagName('head')[0].appendChild(link);

    window.document.title = title;
  };

  renderApp = () => {
    const {
      settings: { theme, locales, language },
    } = this.props;
    const intlProviderProps = getIntlProviderConfig(locales, language);
    return (
      <BunchThemeProvider theme={theme}>
        <IntlProvider {...intlProviderProps} onError={console.info}>
          {this.props.children}
        </IntlProvider>
      </BunchThemeProvider>
    );
  };

  render() {
    const { classes, loading } = this.props;
    const backgroundImage = this.getBackground();

    return (
      <div className={classes.container} style={{ backgroundImage }}>
        <CssBaseline />
        {loading >= 1 && <Loading />}
        {!!backgroundImage &&
          this.renderApp() /* Que flag estupida pra determinar se o app inicializou... */}
      </div>
    );
  }
}

const mapDispatchToProps = {
  initApp: appActions.initApp,
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps
  )
)(App);
