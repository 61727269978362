//TODO: add immer
import Immutable from 'immutable';

import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { SHAPE_TYPE, AREA_DEFAULT_OPTIONS } from 'commons/constants';
import {
  parseGeojsonShapeFeature,
  computeDistanceBetween,
  computeRectangleArea,
  isShapeEmpty,
} from 'commons/geometry';

import { mapShapeActions } from 'commons/store/mapShape';

/* selectors */
const extractParsedShapes = ({ shapes, editable }) => {
  const parsedShapes = shapes
    .map(shape => parseGeojsonShapeFeature(shape.geoJson, editable))
    .reduce((acc, items) => [...acc, ...items], []);

  return parsedShapes.reduce((acc, item) => {
    const shapesForType = acc[item.shape] || [];
    acc[item.shape] = [...shapesForType, item];
    return acc;
  }, {});
};

const shapesSelector = (areaShapes, types) => [...areaShapes[types]];

const snapshotsSelector = ({ mapShape }) => mapShape.areaSnapshots.snapshots;

const activeSnapshotsSelector = ({ mapShape }) =>
  mapShape.areaSnapshots.activeSnapshots;

const parsedActiveSnapshotsSelector = createSelector(
  activeSnapshotsSelector,
  activeSnapshots => ({
    ids: activeSnapshots.keySeq().toArray(),
    shapes: activeSnapshots.valueSeq().toArray(),
  })
);

const activeSnapshotsShapesSelector = createSelector(
  parsedActiveSnapshotsSelector,
  extractParsedShapes
);

const shapesBeingEditingSelector = createSelector(
  ({ areaShapes }) => areaShapes,
  ({ activeShape }) => activeShape.type,
  (areaShapes, type) => (!!type ? [...areaShapes[type]] : [])
);

export const selectors = {
  shapesSelector,
  shapesBeingEditingSelector,
  snapshotsSelector,
  activeSnapshotsSelector,
  parsedActiveSnapshotsSelector,
  activeSnapshotsShapesSelector,
};

/* states */
const areaShapesState = {
  distances: Immutable.List(), // id, path: [{ lat, lng }], length: 0, tag: {}
  rectangles: Immutable.List(), // id, bounds: {}, area: 0, tag: {}
  polygons: Immutable.List(), // id, path: [{ lat, lng }], area: 0, tag: {}
  circles: Immutable.List(), // id, center: { lat, lng }, radius: 0, area: 0, tag: {}
};

const areaSnapshotsState = {
  snapshots: [],
  history: [],
  editingArea: {},
  activeSnapshots: Immutable.Map(),
};

const initialState = {
  areaShapes: areaShapesState,
  activeShape: { mode: '', type: '' },
  areaSnapshots: areaSnapshotsState,
  tags: [],
};

/* handlers */
const updateTagInAllLists = (list, target, tag) =>
  list.map(item => (item.tag.id === target ? { ...item, tag } : item));

const mountCircleRadiusAndArea = radius => ({
  radius: +radius.toFixed(2),
  area: (Math.pow(radius, 2) * Math.PI).toFixed(2),
});

const mountRectangleBoundsAndArea = bounds => ({
  bounds: bounds.toJSON(),
  area: computeRectangleArea(bounds),
});

const mountPolygonPathAndArea = path => {
  const {
    geometry: { spherical },
  } = window.google.maps;

  return {
    path: path.getArray().map(item => item.toJSON()),
    area: spherical.computeArea(path).toFixed(2),
  };
};

const updateShapesForType = (state, shapes, type) => {
  const { areaShapes } = state;

  return {
    ...state,
    areaShapes: { ...areaShapes, [type]: shapes },
  };
};

const updatePropertyInAreaSnapshots = ({ state, data, property }) => ({
  ...state,
  areaSnapshots: { ...state.areaSnapshots, [property]: data },
});

const addPointToDistanceShape = (state, { payload }) => {
  const { areaShapes } = state;
  const { distances } = areaShapes;

  let _distances = Immutable.List(distances);

  const lastDistance = [...distances][0];

  if (lastDistance.path.length) {
    const distancePoint = lastDistance.path.slice(-1).pop();

    const distanceBetweenPoints = computeDistanceBetween(
      payload,
      distancePoint
    );

    _distances = _distances.update(0, item => ({
      ...item,
      length: +(item.length + +distanceBetweenPoints).toFixed(2),
    }));
  }

  _distances = _distances.update(0, item => ({
    ...item,
    path: [...item.path, payload],
  }));

  return updateShapesForType(state, _distances, SHAPE_TYPE.DISTANCES);
};

const updateDistance = (state, { payload }) => {
  const { areaShapes } = state;
  const { distances } = areaShapes;
  const { id, path } = payload;
  const {
    geometry: { spherical },
  } = window.google.maps;

  const index = distances.findIndex(item => item.id === id);
  const _distances = distances.update(index, item => ({
    ...item,
    path: path.getArray().map(({ lat, lng }) => ({ lat: lat(), lng: lng() })),
    length: +spherical.computeLength(path).toFixed(2),
  }));

  return updateShapesForType(state, _distances, SHAPE_TYPE.DISTANCES);
};

const addPathToPolygon = (state, { payload }) => {
  const { areaShapes } = state;
  const { polygons } = areaShapes;

  const _polygons = polygons.update(0, item => ({
    ...item,
    ...mountPolygonPathAndArea(payload),
  }));

  return updateShapesForType(state, _polygons, SHAPE_TYPE.POLYGONS);
};

const updatePolygon = (state, { payload }) => {
  const { areaShapes } = state;
  const { polygons } = areaShapes;
  const { id, path } = payload;

  const index = polygons.findIndex(item => item.id === id);

  const _polygons = polygons.update(index, item => ({
    ...item,
    ...mountPolygonPathAndArea(path),
  }));

  return updateShapesForType(state, _polygons, SHAPE_TYPE.POLYGONS);
};

const addBoundsToRectangle = (state, { payload }) => {
  const { areaShapes } = state;
  const { rectangles } = areaShapes;

  const _rectangles = rectangles.update(0, item => ({
    ...item,
    ...mountRectangleBoundsAndArea(payload),
  }));

  return updateShapesForType(state, _rectangles, SHAPE_TYPE.RECTANGLES);
};

const updateRectangle = (state, { payload }) => {
  const { areaShapes } = state;
  const { rectangles } = areaShapes;
  const { id, bounds } = payload;

  const index = rectangles.findIndex(item => item.id === id);
  const _rectangles = rectangles.update(index, item => ({
    ...item,
    ...mountRectangleBoundsAndArea(bounds),
  }));

  return updateShapesForType(state, _rectangles, SHAPE_TYPE.RECTANGLES);
};

const addSettingsToCircle = (state, { payload }) => {
  const { areaShapes } = state;
  const { circles } = areaShapes;

  const { radius } = payload;

  const _circles = circles.update(0, item => ({
    ...item,
    center: payload.getCenter().toJSON(),
    ...mountCircleRadiusAndArea(radius),
  }));

  return updateShapesForType(state, _circles, SHAPE_TYPE.CIRCLES);
};

const updateCircle = (state, { payload }) => {
  const { areaShapes } = state;
  const { circles } = areaShapes;
  const { id, radius } = payload;

  const index = circles.findIndex(item => item.id === id);
  const _circles = circles.update(index, item => ({
    ...item,
    ...mountCircleRadiusAndArea(radius),
  }));

  return updateShapesForType(state, _circles, SHAPE_TYPE.CIRCLES);
};

const addNewShape = (state, { payload }) => {
  const { areaShapes } = state;
  const { shape, type } = payload;

  let _shapes = Immutable.List(areaShapes[type]);
  const lastShapeAdded = [..._shapes][0];

  if (!!lastShapeAdded && isShapeEmpty(lastShapeAdded, type)) {
    return { ...state };
  }

  _shapes = _shapes.unshift({
    id: Math.random()
      .toString()
      .substr(2),
    tag: AREA_DEFAULT_OPTIONS,
    ...shape,
  });

  return updateShapesForType(state, _shapes, type);
};

const deleteShape = (state, { payload }) => {
  const { areaShapes } = state;
  const { shapeId, type } = payload;

  let _shapes = Immutable.List(areaShapes[type]);
  const index = _shapes.findIndex(item => item.id === shapeId);

  _shapes = _shapes.delete(index);

  return updateShapesForType(state, _shapes, type);
};

const changeShapeTag = (state, { payload }) => {
  const { areaShapes } = state;
  const { tag: newTag, shape, type } = payload;

  let _shapes = Immutable.List(areaShapes[type]);
  const index = _shapes.findIndex(item => item.id === shape.id);

  _shapes = _shapes.update(index, ({ tag, ...rest }) => ({
    ...rest,
    tag: { ...tag, ...newTag },
  }));

  return updateShapesForType(state, _shapes, type);
};

const changeShapeDescription = (state, { payload }) => {
  const { areaShapes } = state;
  const { description, shape, type } = payload;

  let _shapes = Immutable.List(areaShapes[type]);
  const index = _shapes.findIndex(item => item.id === shape.id);

  _shapes = _shapes.update(index, shape => ({
    ...shape,
    description,
  }));

  return updateShapesForType(state, _shapes, type);
};

const updateTagInAllShapes = (areaShapes, target, newTag) => {
  const { distances, rectangles, polygons, circles } = areaShapes;

  return {
    distances: updateTagInAllLists(distances, target, newTag),
    rectangles: updateTagInAllLists(rectangles, target, newTag),
    polygons: updateTagInAllLists(polygons, target, newTag),
    circles: updateTagInAllLists(circles, target, newTag),
  };
};

const toggleAreaSnapshot = (state, { payload }) => {
  const { areaSnapshots } = state;
  const { editingArea, activeSnapshots } = areaSnapshots;
  const { parent, ...restPayload } = payload;

  const _activeSnapshots = activeSnapshots.has(restPayload.id)
    ? activeSnapshots.delete(restPayload.id)
    : activeSnapshots.set(restPayload.id, restPayload);

  return updatePropertyInAreaSnapshots({
    state: parent === editingArea.id ? clearShapeAndEditingArea(state) : state,
    data: _activeSnapshots,
    property: 'activeSnapshots',
  });
};

const editAreaSnapshot = (state, { payload }) => {
  const _state = clearShapeAndEditingArea(state);

  const { areaSnapshots, areaShapes } = _state;
  const { activeSnapshots } = areaSnapshots;

  const { id, name } = payload;
  const _activeSnapshots = activeSnapshots.delete(payload.areaShape.id);

  const parsedShapes = extractParsedShapes({
    shapes: [payload.areaShape],
    editable: true,
  });

  const _areaShapes = Object.keys(parsedShapes).reduce(
    (acc, item) => {
      acc[item] = Immutable.List(parsedShapes[item]);
      return acc;
    },
    { ...areaShapes }
  );

  return {
    ..._state,
    areaShapes: {
      ..._areaShapes,
    },
    areaSnapshots: {
      ...areaSnapshots,
      editingArea: { id, name },
      activeSnapshots: _activeSnapshots,
    },
  };
};

const clearShapeAndEditingArea = state => {
  const { areaSnapshots } = state;

  return {
    ...state,
    areaShapes: areaShapesState,
    areaSnapshots: { ...areaSnapshots, editingArea: {} },
  };
};

const deleteAreaSnapshot = (state, { payload }) => {
  const { areaSnapshots } = state;
  const { snapshots, history, activeSnapshots } = areaSnapshots;

  const { shapeId, areaId, areaShapeId } = payload;

  let _history = history;
  let _activeSnapshots = activeSnapshots;

  if (shapeId || areaShapeId) {
    _history = history.filter(item => item.id !== (shapeId || areaShapeId));
    _activeSnapshots = activeSnapshots.delete(shapeId || areaShapeId);
  }

  const _snapshots =
    !shapeId || (shapeId && !history.length)
      ? snapshots.filter(item => item.id !== areaId)
      : snapshots;

  return {
    ...state,
    areaSnapshots: {
      ...areaSnapshots,
      snapshots: _snapshots,
      history: _history,
      activeSnapshots: _activeSnapshots,
    },
  };
};

const updateSnapshotHistory = (state, { payload }) => {
  const { areaSnapshots } = state;
  const { activeSnapshots } = areaSnapshots;

  let _activeSnapshots = activeSnapshots;

  payload.forEach(
    item => (_activeSnapshots = _activeSnapshots.set(item.id, { ...item }))
  );

  return {
    ...state,
    areaSnapshots: {
      ...areaSnapshots,
      history: payload,
      activeSnapshots: _activeSnapshots,
    },
  };
};

const clearSnapshotHistory = state => {
  const { areaSnapshots } = state;
  const { activeSnapshots, history } = areaSnapshots;

  let _activeSnapshots = activeSnapshots;
  history
    .slice(1)
    .forEach(item => (_activeSnapshots = _activeSnapshots.delete(item.id)));

  return {
    ...state,
    areaSnapshots: {
      ...areaSnapshots,
      history: [],
      activeSnapshots: _activeSnapshots,
    },
  };
};

export default handleActions(
  {
    [mapShapeActions.restoreState]: (_, { payload }) => payload,

    [mapShapeActions.clearMapShapes]: () => initialState,

    [mapShapeActions.clearShapes]: clearShapeAndEditingArea,

    [mapShapeActions.clearTags]: state => ({ ...state, tags: [] }),

    [mapShapeActions.updateTagsList]: (state, { payload }) => ({
      ...state,
      tags: payload,
    }),

    [mapShapeActions.addTagToList]: (state, { payload }) => ({
      ...state,
      tags: [payload, ...state.tags],
    }),

    [mapShapeActions.updateTagInList]: (state, { payload }) => ({
      ...state,
      tags: state.tags.map(item => (item.id === payload.id ? payload : item)),
    }),

    [mapShapeActions.deleteTagFromList]: (state, { payload }) => ({
      ...state,
      tags: state.tags.filter(item => item.id !== payload),
    }),

    [mapShapeActions.deleteTagFromAllShapes]: (state, { payload }) => ({
      ...state,
      areaShapes: updateTagInAllShapes(
        state.areaShapes,
        payload,
        AREA_DEFAULT_OPTIONS
      ),
    }),

    [mapShapeActions.updateTagInAllShapes]: (state, { payload }) => ({
      ...state,
      areaShapes: updateTagInAllShapes(state.areaShapes, payload.id, payload),
    }),

    [mapShapeActions.updateActiveShape]: (state, { payload = {} }) => ({
      ...state,
      activeShape: { ...state.activeShape, ...payload },
    }),

    [mapShapeActions.updateAreaSnapshots]: (state, { payload }) => ({
      ...state,
      areaSnapshots: {
        ...areaSnapshotsState,
        snapshots: payload,
      },
    }),

    [mapShapeActions.updateAreaSnapshotHistory]: updateSnapshotHistory,

    [mapShapeActions.clearHistory]: clearSnapshotHistory,

    [mapShapeActions.editAreaSnapshot]: editAreaSnapshot,

    [mapShapeActions.deleteAreaSnapshotFromList]: deleteAreaSnapshot,

    [mapShapeActions.toggleAreaSnapshot]: toggleAreaSnapshot,

    [mapShapeActions.addNewShape]: addNewShape,

    [mapShapeActions.deleteShape]: deleteShape,

    [mapShapeActions.changeShapeTag]: changeShapeTag,

    [mapShapeActions.changeShapeDescription]: changeShapeDescription,

    [mapShapeActions.addPointToDistance]: addPointToDistanceShape,

    [mapShapeActions.updateDistance]: updateDistance,

    [mapShapeActions.addPathToPolygon]: addPathToPolygon,

    [mapShapeActions.updatePolygon]: updatePolygon,

    [mapShapeActions.addBoundsToRectangle]: addBoundsToRectangle,

    [mapShapeActions.updateRectangle]: updateRectangle,

    [mapShapeActions.addSettingsToCircle]: addSettingsToCircle,

    [mapShapeActions.updateCircle]: updateCircle,
  },
  initialState
);
