import React from 'react';

import cx from 'clsx';
import { bool, object } from 'prop-types';
import { useDispatch } from 'react-redux';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { fileActions } from 'commons/store/file';

import styles from './styles';

const useStyles = makeStyles(styles);

export function MessageCard({ mine, message }) {
  const classes = useStyles(styles);
  const dispatch = useDispatch();

  const handleDownload = () => {
    dispatch(fileActions.downloadFile(message.attachment.id));
  };

  return (
    <div className={classes.container}>
      <Card className={cx(classes.content, { mine })}>
        <div className={cx(classes.name, { mine })}>
          <Typography variant="caption">{message.user.name}</Typography>
        </div>
        <div>
          <Typography variant="body2">{message.message}</Typography>
        </div>
        {message.attachment.id && (
          <div className={classes.attachment} onClick={handleDownload}>
            <AttachFileIcon />
            <span>{message.attachment.name}</span>
          </div>
        )}
      </Card>
    </div>
  );
}

MessageCard.propTypes = {
  /* own props */
  mine: bool.isRequired,
  message: object.isRequired,
};

export default React.memo(MessageCard);
