import React from 'react';
import { array, func } from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { AutoSizer, List } from 'react-virtualized';

import Add from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/styles';

import { ActivityElementsContext } from 'commons/contexts';

import ElementItem from './ElementItem';

import styles from './styles';

const useStyles = makeStyles(styles);

export function ElementsList({ elements = [], onAdd }) {
  const classes = useStyles();

  const { selected } = React.useContext(ActivityElementsContext);

  //TODO: Otimizar ordenação.
  const sortedElements = React.useMemo(
    () =>
      [...elements].sort(function(a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      }),
    [elements]
  );

  const renderRow = React.useCallback(
    ({ key, index, style }) => (
      <ElementItem key={key} style={style} element={sortedElements[index]} />
    ),
    [sortedElements]
  );

  return (
    <div className={classes.container}>
      <div className={classes.contentList}>
        {sortedElements.length ? (
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                rowCount={elements.length}
                rowHeight={50}
                rowRenderer={renderRow}
              />
            )}
          </AutoSizer>
        ) : (
          <div className={classes.noItemsMessage}>
            <FormattedMessage id="MESSAGES.ACTIVITIES.NO_ELEMENTS_SELECTED" />
          </div>
        )}
      </div>
      <div className={classes.footer}>
        <Fab
          variant="extended"
          color="secondary"
          disabled={!selected.length}
          size="small"
          onClick={onAdd}
          aria-label="Add"
        >
          <Add />
          <FormattedMessage id="LABELS.COMMONS.ADD" />
        </Fab>
      </div>
    </div>
  );
}

ElementsList.propTypes = {
  /* onw props */
  elements: array.isRequired,
  onAdd: func.isRequired,
};

export default React.memo(ElementsList);
