import React from 'react';
import { AutoSizer, List } from 'react-virtualized';

export default function MenuList({ children, maxHeight }) {
  const renderRow = ({ key, index, style }) => (
    <div key={key} style={{ ...style, backgroundColor: '#fff' }}>
      {children[index]}
    </div>
  );

  return (
    <div style={{ height: maxHeight, width: '100%' }}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            height={height}
            width={width}
            rowCount={children.length}
            rowHeight={45}
            rowRenderer={renderRow}
          />
        )}
      </AutoSizer>
    </div>
  );
}
