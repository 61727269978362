import React from 'react';
import { object, func } from 'prop-types';

import { compose } from 'recompose';
import { injectIntl, FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export function ActivityDialog({ dialog, onDeleteConfirm, onDialogCancel }) {
  return (
    <Dialog
      open={dialog.open}
      keepMounted
      onClose={onDialogCancel}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {dialog.title || ''}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {dialog.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={event => onDeleteConfirm(true)} color="primary">
          <FormattedMessage id={'LABELS.COMMONS.ALL_UP_COMING'} />
        </Button>
        <Button onClick={event => onDeleteConfirm(false)} color="primary">
          <FormattedMessage id={'LABELS.COMMONS.ONLY_THIS_ONE'} />
        </Button>
        <Button onClick={onDialogCancel} color="default">
          <FormattedMessage id={'LABELS.COMMONS.CANCEL'} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ActivityDialog.propTypes = {
  /* own props */
  dialog: object.isRequired,
  onDeleteConfirm: func.isRequired,
  onDialogCancel: func.isRequired,
};

export default compose(injectIntl)(ActivityDialog);
