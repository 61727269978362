import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  theme: {},
  element: {},
};

const mapElementSlice = createSlice({
  name: 'mapElement',
  initialState,
  reducers: {
    clearMapElement(state) {
      return initialState;
    },
    updateElement(state, { payload }) {
      state.element = payload;
    },
    updateMapElement(
      state,
      {
        payload: { elements, ...theme },
      }
    ) {
      state.element = elements[0];
      state.theme = theme;
    },
  },
});

export const currentElementSelector = ({ [mapElementSlice.name]: state }) =>
  state.element;

export const currentThemeSelector = ({ [mapElementSlice.name]: state }) =>
  state.theme;

export const mapElementSelectors = {
  currentElementSelector,
  currentThemeSelector,
};

export default mapElementSlice;
