import produce from 'immer';
import Immutable from 'immutable';
import { handleActions } from 'redux-actions';

import { activityActions } from 'commons/store/activity';

/* selectors */
const activitySelector = ({ activity }) => activity;

const elementsSelector = ({ activity }) => activity.elementsBuffer;

const activityEditingThemesSelector = ({ activity }) => activity.editing.themes;

const activityElementsSelector = ({ activity }) =>
  new Immutable.Map(activity.elements).valueSeq().toArray();

const activityElementsSizeSelector = ({ activity }) => {
  const { elements } = activity;

  return elements
    .entrySeq()
    .reduce((acc, [, { elementIds }]) => (acc += elementIds.length), 0);
};

export const selectors = {
  activitySelector,
  activityElementsSizeSelector,
  activityElementsSelector,
  elementsSelector,
  activityEditingThemesSelector,
};

const currentDate = new Date();
currentDate.setHours(0, 0, 0, 0);

/* states */
const initialState = {
  activities: [],
  activity: {
    initialDate: currentDate,
    dueDate: currentDate,
  },
  editing: {
    openedView: '',
    themes: [],
    forms: [],
    maps: [],
    users: [],
  },
  lists: {
    themes: [],
    users: [],
    maps: [],
  },
  elementForms: {},
  elementsBuffer: [],
  elements: Immutable.Map(), //{'themeId', {theme: {}, elementIds: []}}},
};

export default handleActions(
  {
    [activityActions.clearActivities]: state => initialState,

    [activityActions.updateActivityEditingThemes]: produce(
      (draft, { payload }) => {
        draft.elementsBuffer = [];
        draft.editing.themes = payload;
      }
    ),

    [activityActions.updateElementsBuffer]: produce((draft, { payload }) => {
      draft.elementsBuffer = payload;
    }),

    [activityActions.updateActivityMessages]: produce((draft, { payload }) => {
      draft.activity.messages = payload;
    }),

    [activityActions.updateActivityElementForms]: produce(
      (draft, { payload }) => {
        draft.elementForms = payload;
      }
    ),

    [activityActions.updateEditingActivity]: produce((draft, { payload }) => {
      draft.activity = payload;
    }),

    [activityActions.updateActivityEditingMaps]: produce(
      (draft, { payload }) => {
        draft.editing.maps = payload;
      }
    ),

    [activityActions.updateActivityEditingUsers]: produce(
      (draft, { payload }) => {
        draft.editing.users = payload;
      }
    ),

    [activityActions.updateActivityEditingForms]: produce(
      (draft, { payload }) => {
        draft.editing.forms = payload;
      }
    ),

    [activityActions.updateActivities]: produce((draft, { payload }) => {
      draft.activities = payload;
    }),

    [activityActions.addActivityToList]: produce((draft, { payload }) => {
      draft.activities.unshift(payload);
    }),

    [activityActions.updateActivityFormValues]: produce(
      (draft, { payload }) => {
        draft.activity.formValues = payload;
      }
    ),

    [activityActions.clearActivityElements]: produce(draft => {
      draft.elements = Immutable.Map();
    }),

    [activityActions.updateActivityInList]: produce((draft, { payload }) => {
      draft.activities = draft.activities.map(item =>
        item.id === payload.id ? payload : item
      );
    }),

    [activityActions.updateInActivityLists]: produce((draft, { payload }) => {
      const [name] = Object.keys(payload);
      const [value] = Object.values(payload);

      draft.lists[name] = value;
    }),

    [activityActions.addElementsToActivity]: produce((draft, { payload }) => {
      draft.elements = draft.elements.set(payload.theme.id, payload);
    }),

    [activityActions.removeElementsFromActivity]: produce(
      (draft, { payload }) => {
        draft.elements = draft.elements.delete(payload);
      }
    ),

    [activityActions.openActivityDrawer]: (state, { payload }) => {
      const {
        lists,
        activities,
        elementForms,
        editing: sEditing,
        activity: sActivity,
      } = state;
      const { view, activity: pActivity } = payload;

      const activity = pActivity || sActivity;
      const elements = Immutable.Map(activity.elements || state.elements);

      const editing = { ...sEditing, openedView: view };

      return {
        ...initialState,
        elementForms,
        editing,
        lists,
        activities,
        activity,
        elements,
      };
    },

    [activityActions.closeActivity]: ({ lists, activities }) => ({
      ...initialState,
      lists,
      activities,
    }),

    [activityActions.deleteActivityFromList]: produce((draft, { payload }) => {
      draft.activities.splice(
        draft.activities.findIndex(item => item.id === payload)
      );
    }),
  },
  initialState
);
