import { createBrowserHistory } from 'history';

export let history = null;

export const replaceParams = (path, params) =>
  Object.keys(params).reduce(
    (previousValue, currentValue) =>
      !!previousValue
        ? previousValue.replace(`:${currentValue}`, params[currentValue])
        : '',
    path
  );

export default () => {
  history = createBrowserHistory({ basename: process.env.REACT_APP_BASENAME });

  history.pushWithParams = (path = '', params = {}) => {
    history.push(replaceParams(path, params));
  };

  return history;
};
