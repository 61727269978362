import { handleActions } from 'redux-actions';

import { approvalActions } from 'commons/store/approval';

const initialState = {
  requests: [],
  details: {},
};

export default handleActions(
  {
    [approvalActions.updateRequests]: (state, { payload }) => ({
      ...state,
      requests: payload,
    }),
    [approvalActions.updateRequestDetails]: (state, { payload }) => ({
      ...state,
      details: payload,
    }),
    [approvalActions.deleteRequests]: state => initialState,
  },
  initialState
);
