import React from 'react';

import fileAPI from 'commons/services/file';

export default function FileDataLoader({ id, visible = true, children }) {
  const [fileData, setFileData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setFileData(null);
    if (!visible || !id) {
      return;
    }
    const fetchFileData = async () => {
      setLoading(true);
      try {
        const response = await fileAPI.getFileDetails(id);
        setFileData(response.data);
      } catch (error) {
        if (error.response.status !== 404) {
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchFileData();
  }, [id, visible]);
  return children({ fileData, loading });
}
