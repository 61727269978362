import React from 'react';
import { object, string, func } from 'prop-types';

import cx from 'clsx';

import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

export function UserAvatar({ user, onClick, className: classNameProp }) {
  const classes = useStyles();

  const { name = '', photo = '' } = user;

  const [firstName, surname] = React.useMemo(
    () => name.toUpperCase().split(' '),
    [name]
  );

  return (
    <div className={classes.container}>
      <Avatar
        onClick={onClick}
        className={cx(
          classes.avatar,
          !!onClick && classes.clickable,
          classNameProp
        )}
      >
        {!photo && (
          <span>{`${firstName.substr(0, 1)}${(surname || '').substr(
            0,
            1
          )}`}</span>
        )}
      </Avatar>
    </div>
  );
}

UserAvatar.propTypes = {
  /* own props */
  user: object.isRequired,
  className: string.isRequired,
  onClick: func,
};

export default React.memo(UserAvatar);
