export default {
  container: {
    marginRight: '.5rem',
    flex: '1',
    boxSizing: 'border-box',
    borderRadius: 3,
    resize: 'none',
    lineHeight: '24px',
    overflow: 'auto',
    height: 'auto',
    padding: 8,
  },
};
