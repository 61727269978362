import { toast } from 'react-toastify';

import { getFormattedMessage } from 'commons/locale';

export default function*(msg, config) {
  const _config = { translate: true, type: 'info', ...config };
  const message = _config.translate ? getFormattedMessage(msg) : msg;

  const { type, translate, ...restConfig } = config;

  yield toast[config.type](message, restConfig);
}
