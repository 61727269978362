export default {
  APP: '/appLoader',
  APPROVALS: '/approvals',
  ENTRIES: '/entries/:id',
  DETAILS: '/details/:dId',
  MAP_SETTINGS: '/maps',
  CLIENT_MAPS: '/maps/:id',
  LAYERED_MAP: '/maps/:id/layered',
  USERS: '/users',
  FILES: '/files',
  LAYERS: '/layers',
  SERVICES: '/services',
  ACTIVITIES: '/activities',
  PANEL: '/',
  CLIENTS: '/clients',
  LOGIN: '/login',
  GUEST: '/guest',
  IMPLICIT_GRANT: '/implicit-grant',
  SETTINGS: '/settings',
  RESET_PASSWORD: '/reset-password',
  WRONG: '/wrong',
  NEW: '/new',
  DETAILS_REFACTOR: '/details',
  THEMES: '/themes',
  HELP: '/help',
  PROFILES: '/profiles',
  EXPORT: '/export',
  IMPORT: '/import',
};
