export default {
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 999,
    overflow: 'hidden',
    backgroundColor: '#fff',
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#696969',
  },
  message: {
    margin: '3rem 0 1.5rem',
  },
  reloadButton: {
    textTransform: 'uppercase',
    color: '#0000ff',
    fontSize: '.9rem',
    fontWeight: 500,
    cursor: 'pointer',
  },
};
