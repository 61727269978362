import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';

import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import CrashRoute from 'commons/components/CrashRoute';
import configureStore from 'commons/store';

import 'commons/api';
import 'commons/interceptor';

import App from './App';
import './index.css';

import * as Sentry from '@sentry/browser';

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_SENTRY_RELEASE
) {
  Sentry.init({
    dsn: 'https://72b13118c52a4ec1b7febeeed22e0fd7@sentry.io/1890433',
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}

if (process.env.NODE_ENV === 'development') {
  // const whyDidYouRender = require('@welldone-software/why-did-you-render');
  // whyDidYouRender(React, {
  //   trackAllPureComponents: true,
  // });
}

const { store, history } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route path="/" component={App} />
      <Route path="/wrong" component={CrashRoute} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
