import React from 'react';
import { object } from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { ActivityElementsContext } from 'commons/contexts';

export function ElementItem({ element, style }) {
  const { selected, onSelect } = React.useContext(ActivityElementsContext);

  // const getElementName = () => {
  //   const { fields, values } = element;
  //   let name = '';

  //   if (!fields.length) {
  //     const [firstValue] = Object.values(values);
  //     return firstValue;
  //   }

  //   for (let key of fields) {
  //     name = values[key];
  //     if (!!name) {
  //       return name;
  //     }
  //   }
  // };

  const handleSelect = React.useCallback(() => onSelect(element.id), [
    element.id,
    onSelect,
  ]);

  return (
    <ListItem dense button onClick={handleSelect} style={style}>
      <Checkbox checked={selected.includes(element.id)} disableRipple />
      <ListItemText primary={element.name} />
    </ListItem>
  );
}

ElementItem.propTypes = {
  /* own props */
  element: object.isRequired,
  style: object.isRequired,
};

export default React.memo(ElementItem);
