import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

export function Loading({ small }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.overlay} />
      <div className={classes.content}>
        <CircularProgress size={small ? 30 : 60} thickness={3} />
      </div>
    </div>
  );
}

export default React.memo(Loading);
