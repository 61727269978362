import React from 'react';

import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import styles from './styles';

const imgHolder = process.env.PUBLIC_URL + '/images/blank_image.png';
const useStyles = makeStyles(styles);

export function ActivityInfo({ activity }) {
  const classes = useStyles();

  const imageUrl = React.useMemo(
    () => (!!activity.image ? activity.image.thumbnail : imgHolder),
    [activity]
  );

  return (
    <div className={classes.container}>
      <div
        className={classes.image}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
      <aside className={classes.info}>
        <div className={classes.group}>
          <div className={classes.item}>
            <Typography variant="caption">
              <FormattedMessage id="LABELS.ACTIVITIES.RESPONSIBLE" />
            </Typography>
            <Typography variant="subtitle1">
              {(activity.responsible || {}).name}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography variant="caption">
              <FormattedMessage id="LABELS.COMMONS.AUTHOR" />
            </Typography>
            <Typography variant="subtitle1">
              {(activity.author || {}).name}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography variant="caption">
              <FormattedMessage id="LABELS.ACTIVITIES.INITIAL_DATE" />
            </Typography>
            <Typography variant="subtitle1">
              {moment(activity.initialDate).format('DD/MM/YYYY')}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography variant="caption">
              <FormattedMessage id="LABELS.ACTIVITIES.CRITICAL_DATE" />
            </Typography>
            <Typography variant="subtitle1">
              {moment(activity.criticalDate).format('DD/MM/YYYY')}
            </Typography>
          </div>
          <div className={classes.item}>
            <Typography variant="caption">
              <FormattedMessage id="LABELS.ACTIVITIES.DUE_DATE" />
            </Typography>
            <Typography variant="subtitle1">
              {moment(activity.dueDate).format('DD/MM/YYYY')}
            </Typography>
          </div>
        </div>
        <div className={classes.group}>
          <div className={classes.item}>
            <Typography variant="caption">
              <FormattedMessage id="LABELS.COMMONS.DESCRIPTION" />
            </Typography>
            <Typography variant="subtitle1">{activity.description}</Typography>
          </div>
        </div>
        {activity.status === 'FINISHED' && !!activity.concludedBy && (
          <div className={classes.group}>
            <div className={classes.item}>
              <Typography variant="caption">
                <FormattedMessage id="LABELS.ACTIVITIES.CONCLUDED_BY" />
              </Typography>
              <Typography variant="subtitle1">
                {activity.concludedBy.name}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography variant="caption">
                <FormattedMessage id="LABELS.ACTIVITIES.CONCLUDED_AT" />
              </Typography>
              <Typography variant="subtitle1">
                {moment(activity.concludedAt).format('DD/MM/YYYY')}
              </Typography>
            </div>
          </div>
        )}
        {activity.reopenedBy && (
          <div className={classes.group}>
            <div className={classes.item}>
              <Typography variant="caption">
                <FormattedMessage id="LABELS.ACTIVITIES.REOPENED_BY" />
              </Typography>
              <Typography variant="subtitle1">
                {activity.reopenedBy.name}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography variant="caption">
                <FormattedMessage id="LABELS.ACTIVITIES.REOPENED_AT" />
              </Typography>
              <Typography variant="subtitle1">
                {moment(activity.reopenedAt).format('DD/MM/YYYY')}
              </Typography>
            </div>
          </div>
        )}
      </aside>
    </div>
  );
}

export default React.memo(ActivityInfo);
