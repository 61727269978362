import React from 'react';

import cx from 'clsx';
import { object, array } from 'prop-types';
import { compose } from 'recompose';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ChatIcon from '@material-ui/icons/Chat';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { ActivityFormContext } from 'commons/contexts';
import { activityActions } from 'commons/store/activity';

import ActivityDetailsHeader from './ActivityDetailsHeader';
import ActivityMessages from './ActivityMessages';
import ActivityDynamicForm from './ActivityDynamicForm';

import styles from './styles';

const useStyles = makeStyles(styles);

export function ActivityDetails({ activity, elements, forms, user }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const hasStarted = () => new Date() >= new Date(activity.initialDate);

  const [formView, setFormView] = React.useState(0);
  const [chat, setChat] = React.useState(!forms.length || !hasStarted());

  const currentForm = React.useMemo(() => forms[formView] || {}, [
    formView,
    forms,
  ]);

  const handleFormViewChange = (e, value) => setFormView(value);

  const handleChatToggle = () => setChat(prev => !prev);

  const handleFormSave = React.useCallback(
    (values, elementId) => {
      dispatch(
        activityActions.saveActivityFormValues({
          activityId: activity.id,
          formId: currentForm.formId,
          elementId,
          values,
        })
      );
    },
    [activity.id, currentForm.formId, dispatch]
  );

  const handleCriticalDateChange = React.useCallback(
    criticalDate => {
      dispatch(activityActions.saveActivity({ ...activity, criticalDate }));
    },
    [activity, dispatch]
  );

  const handleMessageSend = React.useCallback(
    ({ message, attachment }) => {
      dispatch(
        activityActions.saveActivityMessage({
          activityId: activity.id,
          message: {
            message,
            attachment,
            user,
          },
        })
      );
    },
    [activity.id, dispatch, user]
  );

  return (
    <div className={classes.container}>
      <ActivityDetailsHeader
        activity={activity}
        user={user}
        chatOpen={chat}
        hasForms={!!forms.length}
        onChangeCriticalDate={handleCriticalDateChange}
      />
      <div className={classes.content}>
        {!!forms.length && hasStarted() && (
          <div className={classes.forms}>
            <div className={classes.tabs}>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                value={formView}
                onChange={handleFormViewChange}
              >
                {forms.map(item => (
                  <Tab key={item.formId} label={item.name} />
                ))}
              </Tabs>
            </div>
            <div className={classes.formContent}>
              <ActivityFormContext.Provider
                value={{
                  onSave: handleFormSave,
                  blocked: activity.status === 'FINISHED',
                  formValues: activity.formValues,
                  formSchemas: activity.elementForms.formSchemas,
                }}
              >
                <ActivityDynamicForm
                  blocked={activity.status === 'FINISHED'}
                  form={currentForm}
                  elements={elements}
                />
              </ActivityFormContext.Provider>
            </div>
          </div>
        )}
        <div
          className={cx(classes.messages, {
            open: chat,
            noForms: !forms.length,
          })}
        >
          <div className={classes.messageHeader}>
            {chat && (
              <Typography variant="subtitle1" className={classes.messagesTitle}>
                <FormattedMessage id="LABELS.COMMONS.MESSAGES" />
              </Typography>
            )}
            {!!forms.length && hasStarted() && (
              <IconButton onClick={handleChatToggle}>
                {chat ? <ArrowForwardIcon /> : <ChatIcon />}
              </IconButton>
            )}
          </div>
          {chat && (
            <ActivityMessages
              user={user}
              blocked={activity.status === 'FINISHED'}
              messages={activity.messages}
              onSend={handleMessageSend}
            />
          )}
        </div>
      </div>
    </div>
  );
}

ActivityDetails.propTypes = {
  /* activity state */
  activity: object.isRequired,
  forms: array.isRequired,
  elements: array.isRequired,
  /* auth state */
  user: object.isRequired,
};

const mapDispatchToProps = ({
  auth: { user },
  activity: { activity, elementForms },
}) => {
  const { forms = [], elements = [] } = elementForms;

  return {
    activity,
    forms,
    elements,
    user,
  };
};

export default compose(
  connect(mapDispatchToProps),
  React.memo
)(ActivityDetails);
