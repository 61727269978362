import loadable from '@loadable/component';

import urls from './urls';
import types from './types';

export default {
  [types.export]: [
    {
      component: loadable(() => import('App/Export')),
    },
  ],
  [types.import]: [
    {
      component: loadable(() => import('App/Import')),
    },
  ],
  [types.entries]: [
    {
      component: loadable(() => import('App/Entries')),
    },
    {
      extendedPath: urls.DETAILS,
      component: loadable(() => import('App/Entries/EntryDetails')),
    },
  ],
  [types.themes]: [
    {
      component: loadable(() => import('App/Themes')),
    },
    {
      extendedPath: '/:themeId',
      component: loadable(() => import('App/Themes/ThemeDetails')),
      routes: [],
    },
  ],
  [types.app]: [
    {
      component: loadable(() => import('App/AppLoader')),
    },
  ],
  [types.approvals]: [
    {
      component: loadable(() => import('App/Approvals')),
    },
  ],
  [types.files]: [
    {
      component: loadable(() => import('App/Files')),
    },
  ],
  [types.layers]: [
    {
      component: loadable(() => import('App/LayerTypes')),
    },
  ],
  [types.users]: [
    {
      component: loadable(() => import('App/Users')),
    },
  ],
  [types.profiles]: [
    {
      component: loadable(() => import('App/Profiles')),
    },
  ],
  [types.clients]: [
    {
      component: loadable(() => import('App/Clients')),
    },
  ],
  [types.services]: [
    {
      component: loadable(() => import('App/Services')),
    },
  ],
  [types.maps]: [
    {
      component: loadable(() => import('App/MapsList')),
    },
  ],
  [types.activities]: [
    {
      path: urls.ACTIVITIES,
      exact: false,
      component: loadable(() => import('App/Activities')),
    },
  ],
  [types.default]: [
    {
      path: urls.PANEL,
      component: loadable(() => import('App/ModulesPanel')),
    },
    {
      path: urls.HELP,
      component: loadable(() => import('App/Help')),
    },
    {
      path: urls.LAYERED_MAP,
      component: loadable(() => import('App/LayeredMap')),
    },
    {
      path: `${urls.CLIENT_MAPS}`,
      component: loadable(() => import('App/MapsList')),
    },
    {
      path: urls.SETTINGS,
      exact: false,
      component: loadable(() => import('App/Settings')),
    },
  ],
};
