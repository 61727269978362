export default {
  container: {
    display: 'flex',
  },
  filterField: {
    margin: '1rem 0 0 1rem',
    '&:first-child': {
      marginLeft: 0,
    },
  },
};
