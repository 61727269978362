import React from 'react';

import { object, array, func } from 'prop-types';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { useDispatch } from 'react-redux';

import RefreshIcon from '@material-ui/icons/Refresh';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/styles';

import routes from 'commons/routes';
import { RecentLayersContext } from 'commons/contexts';
import { appActions } from 'commons/store/app';
import { mapActions } from 'commons/store/map';

import styles from './styles';
import { MapLayerItem } from './MapLayerItem/MapLayerItem';

const useStyles = makeStyles(styles);

const { hideUploadsDrawer } = appActions;
const { removeErrorLayer } = mapActions;

function TemporaryLayers({ history, maps, onLayerRefresh }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    onLayerRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLayerErrorDelete = React.useCallback(
    (map, layer) => dispatch(removeErrorLayer({ map, layer })),
    [dispatch]
  );

  const handleMapSelect = React.useCallback(
    ({ id }) => {
      dispatch(hideUploadsDrawer());
      history.pushWithParams(routes.urls.LAYERED_MAP, { id });
    },
    [dispatch, history]
  );

  const renderList = () => (
    <RecentLayersContext.Provider
      value={{ onDeleteLayer: handleLayerErrorDelete }}
    >
      <List disablePadding>
        {maps.map(map => (
          <MapLayerItem key={map.id} map={map} onSelect={handleMapSelect} />
        ))}
      </List>
    </RecentLayersContext.Provider>
  );

  const renderWarning = () => (
    <div className={classes.warning}>
      <FormattedMessage id="MESSAGES.MAP.NO_LAYERS_TO_DISPLAY" />
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h6">
          <FormattedMessage id="LABELS.COMMONS.LAYERS" />
        </Typography>
        <IconButton
          onClick={onLayerRefresh}
          className={classes.refreshBtn}
          aria-label="Refresh"
        >
          <RefreshIcon />
        </IconButton>
      </div>
      {maps.length ? renderList() : renderWarning()}
    </div>
  );
}

TemporaryLayers.propTypes = {
  /* own props */
  maps: array.isRequired,
  onLayerRefresh: func.isRequired,
  /* react-router */
  history: object.isRequired,
};

export default compose(
  withRouter,
  React.memo
)(TemporaryLayers);
