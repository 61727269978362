export default ({ palette }) => ({
  container: {
    height: '100%',
    width: '52rem',
    flexDirection: 'column',
    display: 'flex',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '.2rem 0 .2rem 1rem',
    justifyContent: 'space-between',
    borderBottom: '1px solid #e6e6e6',
    background: '#f9f9f9',
  },
  closeButton: {
    margin: '.3rem',
    height: 43,
    width: 43,
  },
  content: {
    display: 'flex',
    flex: '1',
    overflow: 'hidden',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  elementsBuffer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '2 1 65%',
    padding: '0 1rem',
    borderRight: '1px solid #dedede',
  },
  activityElementsLabel: {
    margin: '.5rem 1rem 1rem',
    fontSize: '.9rem',
  },
  activityElements: {
    flex: '1 1 35%',
  },
});
