import React from 'react';
import { string, object, func } from 'prop-types';

import cx from 'clsx';

import { Link } from 'react-router-dom';
import { replaceParams } from 'commons/history';
import routes from 'commons/routes';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';

import { AppContext } from 'commons/contexts';
import ConditionalWrapper from 'commons/components/ConditionalWrapper';

import NestedApplication from './NestedApplication';

import styles from './styles';

export class CollapsibleModule extends React.PureComponent {
  static contextType = AppContext;

  static propTypes = {
    /* own props */
    activeModule: string.isRequired,
    module: object.isRequired,
    application: object.isRequired,
    onSelect: func.isRequired,
    /* material-ui styles */
    classes: object.isRequired,
  };

  static defaultProps = {
    /* own props */
    activeModule: '',
  };

  state = {
    open: this.context.expanded || false,
  };

  isCollapsible = () => this.props.module.applications.length >= 2;

  handleCollapseSelect = () => {
    if (this.isCollapsible()) {
      this.setState(({ open }) => ({ open: !open }));
      this.context.openMenu();
      return;
    }
    const {
      module: { applications, ...rest },
    } = this.props;
    this.props.onSelect(rest, applications[0]);
  };

  handleNestedListItemSelect = application => {
    const {
      module: { applications, ...clone },
    } = this.props;
    this.props.onSelect(clone, application);
  };

  renderCollapsedIcon = () => {
    const { classes } = this.props;
    const { handleMenuToggle } = this.context;

    return (
      <ListItem
        onClick={handleMenuToggle}
        classes={{ root: classes.collapsedListItemIcon }}
        button
        disableGutters
      >
        {this.renderIcon()}
      </ListItem>
    );
  };

  renderIcon = () => {
    const { classes, module, activeModule, expanded } = this.props;
    return (
      <ConditionalWrapper
        condition={!expanded}
        wrapper={children => (
          <Tooltip
            title={module.name.toUpperCase()}
            placement="right"
            enterDelay={expanded ? 5000 : 100}
          >
            {children}
          </Tooltip>
        )}
      >
        <ListItemIcon className={classes.iconItem}>
          <div
            className={cx(classes.iconContainer, {
              active: activeModule === module.id,
            })}
          >
            <Icon
              className={cx(classes.icon, {
                active: activeModule === module.id,
              })}
            >
              {module.icon}
            </Icon>
          </div>
        </ListItemIcon>
      </ConditionalWrapper>
    );
  };

  renderCollapse = () => {
    const { classes, module, application, activeModule } = this.props;
    const { expanded } = this.context;
    const { open } = this.state;

    return (
      <Collapse
        className={classes.nestedCollapseList}
        in={(open || activeModule === module.id) && expanded}
        timeout="auto"
      >
        <List component="div" disablePadding>
          {module.applications.map(item => (
            <NestedApplication
              key={item.id}
              application={item}
              onSelect={this.handleNestedListItemSelect}
              active={item.id === application.id && module.id === activeModule}
            />
          ))}
        </List>
      </Collapse>
    );
  };

  render() {
    const { classes, module, activeModule } = this.props;
    const { open } = this.state;

    const application =
      module.applications.length > 1 ? undefined : module.applications[0];

    let to;
    if (application?.type) {
      to = replaceParams(routes.urls[(application?.type)], {
        id: application.id,
      });
    }
    const href = application?.url ?? undefined;

    const CollapseIcon =
      open && activeModule === module.id ? ExpandLess : ExpandMore;

    return (
      <div className={classes.container}>
        <List disablePadding className={classes.collapseList}>
          <ListItemContainer
            to={to}
            href={href}
            className={classes.collapseItem}
            onClick={this.handleCollapseSelect}
          >
            {this.renderIcon()}
            <ListItemText
              inset
              disableTypography
              className={cx(classes.collapsibleText, {
                active: activeModule === module.id,
              })}
              primary={module.name}
            />
            {this.isCollapsible() && <CollapseIcon className={classes.icon} />}
          </ListItemContainer>
          {this.isCollapsible() && open && this.renderCollapse()}
        </List>
      </div>
    );
  }
}

function ListItemContainer({ to, href, onClick, children, className }) {
  if (to) {
    return (
      <ListItem
        button
        to={to}
        component={Link}
        className={className}
        onClick={onClick}
      >
        {children}
      </ListItem>
    );
  }

  return (
    <ListItem
      button
      href={href}
      component="a"
      className={className}
      onClick={onClick}
    >
      {children}
    </ListItem>
  );
}

export default withStyles(styles)(CollapsibleModule);
