import axios from 'axios';

export default {
  getEntries: ({ moduleId, applicationId }) =>
    axios.get(`/entries/${moduleId}/${applicationId}/table`),
  getEntryValues: ({ moduleId, applicationId }) =>
    axios.get(`/entries/filter/answer/${moduleId}/${applicationId}`),
  getEntryDetails: id => axios.get(`/entries/${id}/details`),
  getHistory: id => axios.get(`/entries/${id}/history`),
  saveEntry: entry => axios.post('/entries/', entry),
  deleteEntry: id => axios.delete(`/entries/${id}`),
};
