import axios from 'axios';

export default {
  updateUsersRestrictions: ({ mapId, folderId, users }) =>
    axios.put(`/maps/${mapId}/layers/${folderId}/restrictions`, { users }),

  moveNode: ({ mapId, nodeId, parentId }) =>
    axios.put(`/maps/${mapId}/layers/move/${nodeId}/to/${parentId}`),

  addNode: ({ mapId, parentId, node }) =>
    axios.post(`/maps/${mapId}/layers/${parentId}`, node),

  updateNode: ({ mapId, node }) =>
    axios.put(`/maps/${mapId}/layers/${node.id}`, node),

  updateLayerOrder: ({ mapId, nodeId, order }) =>
    axios.put(`/maps/${mapId}/layers/${nodeId}/order/${order}`),

  getAllMapsTrees: nodeToRemove =>
    axios.get('/maps/layers/all', { params: { nodeToRemove } }),

  deleteNode: ({ mapId, nodeId }) =>
    axios.delete(`/maps/${mapId}/layers/${nodeId}`),

  addLayerFiles: data => axios.post('/maps/layers', data),

  addDXFLayers: data => axios.post('/maps/layers', data),

  addDXFLayerFiles: data => axios.post('/maps/layers/internal', data),

  getDXFInputs: layerId => axios.get(`/maps/layers/${layerId}/columns/Layer`),

  addLayersToNode: ({ layers, map, node }) =>
    axios.post(`/maps/${map.id}/layers/destiny/${node.id}`, layers),

  getLayerFilesStatus: id => axios.get(`/maps/layers/${id}`),

  getLayerDetails: id => axios.get(`/maps/layers/${id}/details`),

  getLayerFilters: ({ mapId, nodeId }) =>
    axios.get(`/maps/${mapId}/layers/${nodeId}/filters`),

  getExportLayer: ({ urlExport }) => axios.get(urlExport),
};
