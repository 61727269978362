import axios from 'axios';

export default {
  getRequests: () => axios.get('/requests/approvals/'),
  getRequestDetails: id => axios.get(`/requests/approvals/${id}/`),
  approveRequest: approval =>
    axios.post(`/requests/approvals/${approval.id}/approve`, {
      comment: approval.comment,
    }),
  disapproveRequest: disapproval =>
    axios.post(`/requests/approvals/${disapproval.id}/disapprove`, {
      comment: disapproval.comment,
    }),
};
