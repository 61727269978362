import React from 'react';
import { func, object } from 'prop-types';

import { compose } from 'recompose';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

import { DatePickerAdapter } from 'commons/form';

import FilesDropzone from './FilesDropzone';

import styles from './styles';

const useStyles = makeStyles(styles);

export function FileUploaderForm({
  intl,
  uploader,
  node,
  onUploadStoredFiles,
  onCancel,
}) {
  const classes = useStyles();
  const unmounted = React.useRef(false);
  const [submittedFiles, setSubmittedFiles] = React.useState([]);

  React.useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const addFileToQueue = React.useCallback(
    file => {
      if (unmounted.current) {
        return;
      }
      setSubmittedFiles(submittedFiles => {
        const alreadyAdded = submittedFiles.find(item => item.id === file.id);
        return !alreadyAdded ? [...submittedFiles, file] : submittedFiles;
      });
    },
    [setSubmittedFiles]
  );

  const isDuplicatedFile = React.useCallback(
    fileName =>
      node.children.filter(({ name }) => fileName === name).map(({ id }) => id)
        .length,
    [node]
  );

  const handleFileSubmitted = React.useCallback(
    (id, name) => {
      addFileToQueue({ id, name, duplicated: isDuplicatedFile(name) });
    },
    [addFileToQueue, isDuplicatedFile]
  );

  const handleDeleteFile = id => {
    const _submittedFiles = submittedFiles.filter(item => item.id !== id);

    setSubmittedFiles(_submittedFiles);
    uploader.methods.cancel(id);
  };

  const handleUploadFiles = React.useCallback(
    values => e => {
      node.date = values.date;
      onUploadStoredFiles(submittedFiles);
    },
    [node, onUploadStoredFiles, submittedFiles]
  );

  const handleUploadCancel = React.useCallback(() => {
    onCancel(submittedFiles);
  }, [onCancel, submittedFiles]);

  const renderForm = ({ values }) => (
    <form className={classes.form}>
      <div className={classes.fields}>
        <Field
          name="date"
          margin="dense"
          variant="inline"
          autoComplete="off"
          component={DatePickerAdapter}
          className={classes.field}
          label={intl.formatMessage({ id: 'LABELS.COMMONS.DATE' })}
          minDateMessage={intl.formatMessage({
            id: 'MESSAGES.ACTIVITIES.DATE_LESS_THAN_CURRENT_DATE',
          })}
          fullWidth
          autoOk
        />
      </div>
      <FilesDropzone
        submittedFiles={submittedFiles}
        uploader={uploader}
        onSubmitted={handleFileSubmitted}
        onDelete={handleDeleteFile}
      />
      <div className={classes.footer}>
        <Button variant="contained" onClick={handleUploadCancel}>
          <FormattedMessage id="LABELS.COMMONS.CANCEL" />
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleUploadFiles(values)}
          disabled={!submittedFiles.length}
          className={classes.saveBtn}
        >
          <FormattedMessage id="LABELS.FILES.UPLOAD_FILES" />
        </Button>
      </div>
    </form>
  );

  return (
    <div className={classes.container}>
      <Form
        initialValues={{ date: new Date() }}
        onSubmit={handleUploadFiles}
        render={renderForm}
      />
    </div>
  );
}

FileUploaderForm.propTypes = {
  /* own props*/
  uploader: object.isRequired,
  node: object.isRequired,
  onCancel: func.isRequired,
  onUploadStoredFiles: func.isRequired,
};

export default compose(
  injectIntl,
  React.memo
)(FileUploaderForm);
