import { createAction } from 'redux-actions';

export default {
  getMaps: createAction('map/GET_MAPS'),
  restoreState: createAction('map/RESTORE_STATE'),
  savePreviousMapStack: createAction('map/SAVE_PREVIOUS_MAP_STACK'),
  restorePreviousMapStack: createAction('map/RESTORE_PREVIOUS_MAP_STACK'),
  clearPreviousMapStack: createAction('map/CLEAR_PREVIOUS_MAP_STACK'),
  updateMaps: createAction('map/GET_MAPS_SUCCESS'),
  getMap: createAction('map/GET_MAP_REQUEST'),
  deleteMap: createAction('map/DELETE_MAP'),
  deleteMapFromList: createAction('map/DELETE_MAP_SUCCESS'),
  removeErrorLayer: createAction('map/REMOVE_ERROR_LAYER'),
  getRecentlyCreatedLayers: createAction('map/GET_RECENTLY_CREATED_LAYERS'),
  updateRecentlyCreatedLayers: createAction(
    'map/UPDATE_RECENTLY_CREATED_LAYERS'
  ),
  clearMap: createAction('map/CLEAR_MAP'),
  updateMap: createAction('map/GET_MAP_SUCCESS'),
  updateMapBbox: createAction('map/UPDATE_MAP_BBOX'),
  saveMapType: createAction('map/SAVE_MAP_TYPE'),
  saveMapAreaType: createAction('map/SAVE_MAP_AREA_TYPE'),
  deleteMapAreaType: createAction('map/DELETE_MAP_AREA_TYPE'),
  getMapAreaTypes: createAction('map/GET_MAP_AREA_TYPES'),
  updateMapAreaTypes: createAction('map/GET_MAP_AREA_TYPES_SUCCESS'),
  getAttrs: createAction('map/GET_MAP_ATTRS_REQUEST'),
  getMapDetails: createAction('map/GET_MAP_DETAILS'),
  getMapPreferences: createAction('map/GET_MAP_PREFERENCES'),
  updateMapPreferences: createAction('map/UPDATE_MAP_PREFERENCES'),
  saveMapViews: createAction('map/SAVE_MAP_VIEWS'),
  getMapViews: createAction('map/GET_MAP_VIEWS'),
  updateMapViews: createAction('map/GET_MAP_VIEWS_SUCCESS'),
  getMapsByClientId: createAction('map/GET_MAPS_BY_CLIENT_ID'),
  saveMap: createAction('map/SAVE_MAP_REQUEST'),
  saveAttr: createAction('map/SAVE_MAP_ATTR_REQUEST'),
  getMapUsers: createAction('map/GET_MAP_USERS'),
  updateTree: createAction('map/UPDATE_TREE'),
  saveCurrentView: createAction('map/SAVE_CURRENT_VIEW'),
  getMapUsersAssociation: createAction('map/GET_MAP_USERS_ASSOCIATION'),
  updateMapUsersAssociation: createAction('map/UPDATE_MAP_USERS_ASSOCIATION'),
  saveMapUsersAssociation: createAction('map/SAVE_MAP_USERS_ASSOCIATION'),
  clearMapUsersAssociation: createAction('map/CLEAR_MAP_USERS_ASSOCIATION'),
};
