export default {
  container: {
    position: 'absolute',
    top: '45%',
    left: '3.5rem',
    right: '3.5rem',
    textAlign: 'center',
  },
  title: {
    fontSize: '1.3rem',
  },
  message: {
    margin: '.5rem 0 0',
    fontSize: '.95rem',
  },
};
