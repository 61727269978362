import React from 'react';

import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/styles';

import SelectCircularProgress from 'commons/components/SelectCircularProgress';

import { ITEM_HEIGHT, ITEM_PADDING_TOP } from 'commons/constants';

const getMenuProps = props => ({
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: props.large ? 480 : 280,
    },
  },
});

const styles = ({ palette, spacing }) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: spacing() / 4,
  },
});

const renderChips = props => {
  const {
    classes,
    value = [],
    options,
    mapOptionToKey,
    mapOptionToLabel,
  } = props;

  return (
    <div className={classes.chips}>
      {value.map(selectedKey => {
        const selectedValue = options.find(
          option => selectedKey === mapOptionToKey(option)
        );
        const selectedLabel = mapOptionToLabel(selectedValue);

        return (
          <Chip
            key={selectedKey}
            label={selectedLabel}
            className={classes.chip}
          />
        );
      })}
    </div>
  );
};

const renderOptions = props => {
  const { options, mapOptionToKey, mapOptionToLabel, value = [] } = props;

  return options.map(option => {
    const optionKey = mapOptionToKey(option);
    const optionLabel = mapOptionToLabel(option);

    return (
      <MenuItem key={optionKey} value={optionKey}>
        <Checkbox
          checked={value.some(selectedKey => optionKey === selectedKey)}
          style={{ marginLeft: '-12px' }}
        />
        <ListItemText primary={optionLabel} style={{ marginLeft: '-6px' }} />
      </MenuItem>
    );
  });
};

export function MultiSelect(props) {
  const {
    value,
    mapOptionToKey,
    mapOptionToLabel,
    onChange,
    classes,
    inputProps,
    InputProps,
    renderValue,
    large,
    loading,
    ...other
  } = props;

  return (
    <Select
      multiple
      value={value}
      onChange={onChange}
      input={<Input {...other} />}
      renderValue={
        renderValue || (value && value.length > 0 && renderChips(props))
      }
      MenuProps={getMenuProps(props)}
      inputProps={inputProps}
      {...(loading
        ? { IconComponent: SelectCircularProgress, disabled: true }
        : null)}
    >
      {renderOptions(props)}
    </Select>
  );
}

export default withStyles(styles, { withTheme: true })(MultiSelect);
