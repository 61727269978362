export default ({ palette }) => ({
  container: {
    width: '46rem',
    flex: '1',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  footer: {
    padding: '.5rem 1rem',
    textAlign: 'end',
  },
  field: {
    margin: '.4rem .8rem',
  },
  noTopMargin: {
    margin: '0 .8rem',
  },
  noBottomMargin: {
    margin: '.4rem .8rem 0',
  },
  nowrap: {
    marginBottom: '1rem',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  noWrapBottomMargin: {
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  size: {
    backgroundColor: '#ff4566',
    color: '#fff',
  },
  elementsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    color: '#f44336',
    fontSize: '.75rem',
    marginTop: 8,
  },
  elements: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    fontSize: '.9rem',
    padding: '.5rem 1rem',
    borderRadius: '5px',
  },
});
