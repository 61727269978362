import axios from 'axios';

export default {
  logIn: credentials => axios.post('/users/login', credentials),
  getLoggedInUser: () => axios.get('/users/me'),
  recoveryPassword: username =>
    axios.post('/users/login/reset-password', { username }),
  resetPassword: body => {
    const { password, key } = body;
    return axios.post(`/users/login/change-password/${key}`, { password });
  },
  changePassword: body => axios.put('/users/me/update-password', body),
};
