import { createAction } from 'redux-actions';
import { filterElements, filterElementsFull } from './mapThemeSlice';

export default {
  restoreState: createAction('mapTheme/RESTORE_STATE'),
  clearMapTheme: createAction('mapTheme/CLEAR_MAP_THEME'),

  updateEditingElement: createAction('mapTheme/updateEditingElement'),
  openElementEditor: createAction('mapTheme/openElementEditor'),
  closeElementEditor: createAction('mapTheme/closeElementEditor'),

  addMapTheme: createAction('mapTheme/addMapTheme'),

  filterElements,
  filterElementsFull,
  updateIntoThemes: createAction('mapTheme/updateIntoThemes'),

  getElementDetails: createAction('mapTheme/getElementDetails'),

  exportElement: createAction('mapTheme/exportElement'),

  getElementSchema: createAction('mapTheme/getElementSchema'),

  saveElement: createAction('mapTheme/saveElement'),
  deleteElement: createAction('mapTheme/deleteElement'),

  addElementToTheme: createAction('mapTheme/addElementToTheme'),
  updateElementInTheme: createAction('mapTheme/updateElementInTheme'),
  deleteElementFromTheme: createAction('mapTheme/deleteElementFromTheme'),

  openVideoPlayer: createAction('mapTheme/OPEN_VIDEO_PLAYER'),

  setIsGeoReferencedVideo: createAction('mapTheme/SET_IS_GEOREFERENCED_VIDEO'),

  updateElementStatus: createAction('mapTheme/UPDATE_ELEMENT_STATUS'),
};
