export default {
  container: {
    textAlign: 'right',
    position: 'relative',
  },
  button: {
    margin: '.5rem .5rem 0 0',
  },
  image: {
    maxWidth: '100%',
    '&.clickable': {
      cursor: 'pointer',
    },
  },
  buttonLoading: {
    color: '#fff',
  },
  loadingContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageLoading: {
    color: '#3a71ca',
  },
  upload: {
    textAlign: 'center',
  },
};
