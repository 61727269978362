import axios from 'axios';

import { config } from 'commons/store';

import auth from 'commons/auth';
import routes from 'commons/routes';

axios.interceptors.request.use(
  config => {
    config.headers = config.headers || {};
    //        /settings       : Para evitar invalid_token em token expirado. Não consegui configurar no backend.
    // FIXME: /files/download : A API de download de arquivos ainda não tem autenticação.
    if (!config.url.match(/^\/settings|^\/files\/download/)) {
      config.headers = { ...config.headers, ...auth.getHeaders() };
    }

    return config;
  },
  err => Promise.reject(err)
);

axios.interceptors.response.use(
  response => response,
  err => {
    if (err.response.status === 401) {
      const { urls } = routes;
      const { history } = config;

      auth.clear();
      window.location.pathname !==
        process.env.REACT_APP_BASENAME + urls.LOGIN && history.push(urls.LOGIN);
    }
    return Promise.reject(err);
  }
);
