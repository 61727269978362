import axios from 'axios';

export default {
  getTreeNode: nodeId => axios.get(`/files/filesystem/${nodeId}`),

  getNodeFolders: id => axios.get(`/files/filesystem/${id}/folders`),

  insertNode: ({ parentId, node }) =>
    axios.post(`/files/filesystem/${parentId}`, node),

  moveFolder: ({ source, destiny }) =>
    axios.put(`/files/filesystem/move/${source}/to/${destiny}`),

  updateNode: ({ node }) => axios.put(`/files/filesystem/${node.id}`, node),

  deleteNode: nodeId => axios.delete(`/files/filesystem/${nodeId}`, nodeId),

  download: fileId =>
    axios.get(`/files/download?id=${encodeURIComponent(fileId)}`, { responseType: 'blob' }),

  getMapFileTreeForLayer: ({ map, isDXF }) =>
    axios.get(`/maps/${map}/filetree/layerinput${isDXF ? '/dxf' : ''}`),

  getFileDetails: fileId =>
    axios.get(`/files?id=${encodeURIComponent(fileId)}`),

  reduceVideo: id => axios.put(`/files/filesystem/reduceVideo/${id}`),

  getUsedDailyLimit: () => axios.get(`/files/usedLimit`),
};
