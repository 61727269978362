import axios from 'axios';

export default {
  addNode: ({ mapId, parentId, node }) =>
    axios.post(`/maps/${mapId}/filetree/${parentId}`, node),

  updateNode: ({ mapId, node }) =>
    axios.put(`/maps/${mapId}/filetree/${node.id}`, node),

  deleteNode: ({ mapId, nodeId }) =>
    axios.delete(`/maps/${mapId}/filetree/${nodeId}`),

  addFiles: ({ mapId, parentId, files }) =>
    axios.post(`/maps/${mapId}/filetree/${parentId}/files`, files),

  download: fileId =>
    axios.get(`/files/download?id=${encodeURIComponent(fileId)}`, { responseType: 'blob' }),

  getTreeNode: nodeId => axios.get(`/files/filesystem/${nodeId}/element`),
};
