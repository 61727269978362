import React from 'react';
import { object, func, bool } from 'prop-types';

import moment from 'moment';
import { compose } from 'recompose';
import { injectIntl, FormattedMessage } from 'react-intl';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Timer from '@material-ui/icons/Timer';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';

import ActivityActions from 'App/Activities/ActivityActions';
import { STATUS } from 'commons/constants';

import ActivityInfo from './ActivityInfo';

import styles from './styles';

const useStyles = makeStyles(styles);

export function ActivityDetailsHeader({
  intl,
  activity,
  user,
  hasForms,
  chatOpen,
  onChangeCriticalDate,
}) {
  const classes = useStyles();

  const [criticalDate, setCriticalDate] = React.useState(activity.criticalDate);
  const [details, setDetails] = React.useState(false);

  React.useEffect(() => {
    chatOpen && hasForms && setDetails(false);
  }, [chatOpen, hasForms]);

  const isEditable = React.useMemo(() => {
    const { responsible = {}, author = {} } = activity;

    return (
      (responsible.id === user.id || author.id === user.id) &&
      activity.status !== 'FINISHED'
    );
  }, [activity, user]);

  const activityStatus = React.useMemo(() => {
    const status = STATUS.find(item => item.id === activity.status) || {};
    const { color = '#fff', initials = '' } = status;

    return { color, initials };
  }, [activity]);

  const handleDetailsToggle = () => setDetails(prev => !prev);

  return (
    <div className={classes.container}>
      <div className={classes.generalInfo}>
        <div className={classes.wrapContent}>
          <Avatar
            style={{ backgroundColor: activityStatus.color }}
            className={classes.userAvatarDrawer}
          >
            <Typography className={classes.statusInitials}>
              {activityStatus.initials}
            </Typography>
          </Avatar>
          <div className={classes.title}>
            <Typography variant="h6">{activity.title}</Typography>
            <Typography variant="subtitle2">
              {(activity.responsible || {}).name}
            </Typography>
          </div>
          <div className={classes.dueDate}>
            <Icon className={classes.criticalClockIcon}>
              <Timer />
            </Icon>
            {isEditable ? (
              <DatePicker
                name="criticalDate"
                format="DD/MM/YYYY"
                disabled={activity.status === 'FINISHED'}
                onChange={setCriticalDate}
                value={criticalDate}
                onAccept={onChangeCriticalDate}
                minDate={activity.initialDate}
                maxDate={activity.dueDate}
                InputProps={{
                  endAdornment: <ArrowDropDownIcon />,
                }}
                invalidDateMessage={intl.formatMessage({
                  id: 'LABELS.COMMONS.INVALID_DATE',
                })}
                placeholder={intl.formatMessage({
                  id: 'LABELS.COMMONS.BEGINNING',
                })}
              />
            ) : (
              <Typography variant="subtitle1">
                {moment(activity.dueDate).format('DD/MM/YYYY')}
              </Typography>
            )}
          </div>
        </div>
        {isEditable && <ActivityActions activity={activity} />}
      </div>
      <div className={classes.details} onClick={handleDetailsToggle}>
        <Typography variant="button" display="block">
          <FormattedMessage id="LABELS.COMMONS.DETAILS" />
        </Typography>
        {details ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </div>
      {details && <ActivityInfo activity={activity} />}
    </div>
  );
}

ActivityDetailsHeader.propTypes = {
  /* own props */
  activity: object.isRequired,
  user: object.isRequired,
  chatOpen: bool,
  hasForms: bool,
  onChangeCriticalDate: func.isRequired,
  /* react-intl */
  intl: object.isRequired,
};

export default compose(
  injectIntl,
  React.memo
)(ActivityDetailsHeader);
