import {
  takeEvery,
  all,
  select,
  put,
  putResolve,
  fork,
} from 'redux-saga/effects';

import api from 'commons/api';
import routes from 'commons/routes';
import notification from 'commons/notification';
import { config } from 'commons/store';
import { VIEWS } from 'commons/constants';
import { mapService } from 'commons/services';
import { mapActions } from 'commons/store/map';
import { mapActivityActions } from 'commons/store/mapActivity';
import { mapThemeActions } from 'commons/store/mapTheme';
import { mapFileActions } from 'commons/store/mapFile';
import { mapLayerActions } from 'commons/store/mapLayer';
import { mapShapeActions } from 'commons/store/mapShape';
import { mapElementActions } from 'commons/store/mapElement';
import { loadingSaga } from 'commons/store/loading';

const mapViewActions = {
  [VIEWS.ELEMENT_DETAILS]: {
    set: mapElementActions.updateMapElement,
    clear: mapElementActions.clearMapElement,
  },
  [VIEWS.FILES]: {
    set: mapFileActions.updateTreeNode,
    clear: mapFileActions.clearMapFiles,
  },
  [VIEWS.THEMES]: {
    set: mapThemeActions.addMapTheme,
    clear: mapThemeActions.clearMapTheme,
  },
  [VIEWS.LAYERS]: {
    set: mapLayerActions.updateTree,
    clear: mapLayerActions.clearMapLayer,
  },
  [VIEWS.AREA_SNAPSHOTS]: {
    set: mapShapeActions.updateAreaSnapshots,
    clear: mapShapeActions.clearMapShapes,
  },
  [VIEWS.MAP_ACTIVITIES]: {
    set: mapActivityActions.updateMapActivities,
    clear: mapActivityActions.clearMapActivities,
  },
};

function* getMaps({ payload, loading = true }) {
  yield* api(
    mapService.getMaps,
    payload,
    mapActions.updateMaps,
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_GET_MAPS', { type: 'error' });
    },
    { multiple: false, loading: loading }
  );
}

function* getMapAreaTypes() {
  yield* api(
    mapService.getMapAreaTypes,
    {},
    mapActions.updateMapAreaTypes,
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_GET_MAP_AREA_TYPES', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* saveMapAreaType({ payload }) {
  yield* api(
    mapService.saveMapAreaType,
    payload,
    function*() {
      yield fork(getMapAreaTypes);
    },
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_SAVE_MAP_AREA_TYPE', {
        type: 'error',
      });
    }
  );
}

function* deleteMapAreaType({ payload }) {
  yield* api(
    mapService.deleteMapAreaType,
    payload,
    function*() {
      yield fork(getMapAreaTypes);
    },
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_REMOVE_MAP_AREA_TYPE', {
        type: 'error',
      });
    }
  );
}

function* getMapPreferences({ payload }) {
  yield* api(
    mapService.getMapPreferences,
    payload,
    mapActions.updateMapPreferences,
    function*(err) {
      yield put(mapActions.updateMapPreferences({ type: 'roadmap' }));
    },
    { multiple: false }
  );
}

function* getMapsByClientId({ payload }) {
  try {
    const { data } = yield mapService.getMapsByClientId(payload);
    yield put(mapActions.updateMaps(data));
  } catch (err) {
    yield notification('MESSAGES.MAP.COULD_NOT_GET_MAPS', { type: 'error' });
  }
}

function* removeErrorLayer({ payload }) {
  const { layer } = payload;
  try {
    yield mapService.deleteErrorLayer(layer.id);

    yield* getRecentlyCreatedLayers();
  } catch (err) {
    console.log(err);
  }
}

function* getMapUsersAssociation({ payload }) {
  yield* api(
    mapService.getMapUsersAssociation,
    payload,
    mapActions.updateMapUsersAssociation,
    function*(err) {
      yield notification('MESSAGES.USER.COULD_NOT_GET_USERS', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* saveMapUsersAssociation({ payload }) {
  yield* api(
    mapService.saveMapUsersAssociation,
    payload,
    function*() {
      yield notification('MESSAGES.USER.USERS_ASSOCIATION_SAVED', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.USER.COULD_NOT_SAVE_USERS_ASSOCIATION', {
        type: 'error',
      });
    }
  );
}

function* restorePreviousMapStack() {
  const {
    map: { previousStack },
    mapElement,
    auth: { user },
  } = yield select(state => state);

  yield put(mapLayerActions.clearMapLayer());

  yield putResolve(mapThemeActions.restoreState(previousStack.mapTheme));

  yield putResolve(mapThemeActions.updateElementInTheme(mapElement.element));

  yield put(mapActivityActions.restoreState(previousStack.mapActivity));
  yield put(mapFileActions.restoreState(previousStack.mapFile));
  yield put(mapShapeActions.restoreState(previousStack.mapShape));
  yield put(mapLayerActions.restoreState(previousStack.mapLayer));
  yield put(mapActions.restoreState(previousStack.previousMap));

  yield put(mapElementActions.clearMapElement());
  yield put(mapActions.clearPreviousMapStack());

  yield put(mapLayerActions.updateActiveLayers(user.preferences));
}

function* configElementDetails({ views, map, elementId }) {
  const {
    map: previousMap,
    mapLayer,
    mapTheme,
    mapActivity,
    mapFile,
    auth: { user },
  } = yield select(state => state);

  const previousStack = {
    elementId,
    previousMap,
    mapLayer,
    mapFile,
    mapActivity,
    mapTheme,
  };

  yield put(mapActions.savePreviousMapStack(previousStack));

  yield putResolve(mapActions.updateMapViews({ views, map }));
  yield put(mapViewActions[VIEWS.THEMES].clear());

  yield* populateMapViews(views);

  yield put(mapLayerActions.updateActiveLayers(user.preferences));
}

function* populateMapViews(views) {
  const types = views.map(item => item.type);

  yield all(types.map(type => putResolve(mapViewActions[type].clear())));

  yield all(
    views.map(item => {
      let dataView = item.type === VIEWS.THEMES ? item : item.data;
      if (item.type === VIEWS.FILES) {
        dataView = item.data.children;
      }

      return put(mapViewActions[item.type].set(dataView));
    })
  );
}

function* getMapViews({ payload }) {
  const { elementId } = payload;

  yield* api(
    mapService.getMapViews,
    payload,
    function*(data) {
      const { mapViews: views, ...map } = data;
      const { user } = yield select(({ auth }) => auth);

      if (!!elementId) {
        yield* configElementDetails({ views, map, elementId });
        return;
      }

      yield* populateMapViews(views);
      yield putResolve(mapActions.updateMapViews({ views, map }));
      yield put(mapLayerActions.updateActiveLayers(user.preferences));
      yield put(mapActions.clearPreviousMapStack());
    },
    err => console.log(err)
  );
}

function* getMap({ payload }) {
  yield* api(
    mapService.getMap,
    payload,
    mapActions.updateMap,
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_GET_MAPS', { type: 'error' });
    },
    { multiple: false }
  );
}

function* saveMap({ payload }) {
  yield* api(
    mapService.saveMap,
    payload,
    function*(data) {
      yield fork(getMap, { payload: data });
      yield fork(getMaps, { payload: null });

      yield notification('MESSAGES.MAP.SUCCESSFUL_SAVED_MAP', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_SAVE_MAP', { type: 'error' });
    }
  );
}

function* updateMapBbox({ payload }) {
  yield* api(
    mapService.updateMapBbox,
    payload,
    function*() {
      yield fork(getMap, { payload: payload.mapId });
      yield notification('MESSAGES.MAP.SUCCESSFUL_SAVED_MAP', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_SAVE_MAP', { type: 'error' });
    }
  );
}

function* saveMapType({ payload }) {
  yield* api(
    mapService.saveMapType,
    payload,
    mapActions.updateMapPreferences,
    function*(err) {
      yield put(mapActions.updateMapPreferences({ type: payload.type }));
    },
    { loading: false, multiple: false }
  );
}

function* saveMapViews({ payload }) {
  yield* api(
    mapService.saveMapViews,
    payload,
    function*(data) {
      yield fork(getMapPreferences, { payload: data });
      yield notification('MESSAGES.MAP.SUCCESSFUL_SAVED_MAP', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_SAVE_MAP', { type: 'error' });
    }
  );
}

function* deleteMap({ payload }) {
  yield* api(
    mapService.deleteMap,
    payload,
    mapActions.deleteMapFromList,
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_DELETE_MAP', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* saveAttr({ payload: { id, attributes } }) {
  yield* api(
    mapService.saveAttr,
    { id, attributes },
    function*(data) {
      yield fork(getMap, { payload: data });
      yield fork(getMaps, { payload: null });

      yield notification('MESSAGES.MAP.SUCCESSFUL_SAVED_ATTRS', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_SAVE_ATTRS', {
        type: 'error',
      });
    }
  );
}

function* getRecentlyCreatedLayers() {
  yield* api(
    mapService.getRecentlyLayers,
    {},
    mapActions.updateRecentlyCreatedLayers,
    function*(err) {
      const url = config.history.location.pathname;

      if (url === routes.urls.LOGIN) {
        return;
      }
      yield notification('MESSAGES.MAP.COULD_NOT_GET_RECENTLY_LAYERS', {
        type: 'error',
      });
    },
    { multiple: false, loading: false }
  );
}

function* clearMapData() {
  yield put(mapThemeActions.clearMapTheme());
  yield put(mapLayerActions.clearMapLayer());
  yield put(mapShapeActions.clearMapShapes());
}

export default function*() {
  yield takeEvery(mapActions.getMaps.toString(), getMaps);
  yield takeEvery(mapActions.getMapViews.toString(), getMapViews);
  yield takeEvery(mapActions.getMap.toString(), getMap);
  yield takeEvery(mapActions.getMapPreferences.toString(), getMapPreferences);
  yield takeEvery(mapActions.saveMap.toString(), saveMap);
  yield takeEvery(mapActions.updateMapBbox.toString(), updateMapBbox);
  yield takeEvery(mapActions.deleteMap.toString(), deleteMap);
  yield takeEvery(mapActions.saveAttr.toString(), saveAttr);
  yield takeEvery(mapActions.saveMapType.toString(), saveMapType);
  yield takeEvery(mapActions.saveMapViews.toString(), saveMapViews);
  yield takeEvery(mapActions.getMapAreaTypes.toString(), getMapAreaTypes);
  yield takeEvery(mapActions.removeErrorLayer.toString(), removeErrorLayer);
  yield takeEvery(mapActions.saveMapAreaType.toString(), saveMapAreaType);
  yield takeEvery(
    mapActions.getRecentlyCreatedLayers.toString(),
    getRecentlyCreatedLayers
  );
  yield takeEvery(
    mapActions.restorePreviousMapStack.toString(),
    restorePreviousMapStack
  );
  yield takeEvery(mapActions.deleteMapAreaType.toString(), deleteMapAreaType);
  yield takeEvery(
    mapActions.getMapsByClientId.toString(),
    loadingSaga(getMapsByClientId)
  );
  yield takeEvery(mapActions.clearMap.toString(), clearMapData);
  yield takeEvery(
    mapActions.getMapUsersAssociation.toString(),
    getMapUsersAssociation
  );
  yield takeEvery(
    mapActions.saveMapUsersAssociation.toString(),
    saveMapUsersAssociation
  );
}

export { getMaps };
