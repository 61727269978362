import produce from 'immer';

import { handleActions } from 'redux-actions';

import { authActions } from 'commons/store/auth';

const initialState = {
  user: {},
  availablePermissions: [],
};

export default handleActions(
  {
    [authActions.updateLoggedInUser]: produce((draft, { payload }) => {
      draft.user = payload;
    }),
    [authActions.deleteLoggedInUser]: produce(draft => {
      draft.user = {};
    }),
  },
  initialState
);
