import { takeEvery, put, fork, select } from 'redux-saga/effects';

import api from 'commons/api';
import notification from 'commons/notification';
import { mapShapeService } from 'commons/services';
import { mapSelectors } from 'commons/store/map';
import { mapShapeActions } from 'commons/store/mapShape';

function* getTags() {
  const { clientId } = yield select(mapSelectors.mapSelector);

  yield* api(
    mapShapeService.getTags,
    clientId,
    mapShapeActions.updateTagsList,
    err => console.log(err),
    { loading: false, multiple: false }
  );
}

function* getAreaSnapshots({ payload }) {
  yield* api(
    mapShapeService.getAreaSnapshots,
    payload,
    mapShapeActions.updateAreaSnapshots,
    err => console.log(err),
    { multiple: false }
  );
}

function* saveTag({ payload }) {
  const { clientId } = yield select(mapSelectors.mapSelector);

  yield* api(
    mapShapeService[payload.id ? 'updateTag' : 'saveTag'],
    { clientId, tag: payload },
    function*(data) {
      yield put(
        mapShapeActions[payload.id ? 'updateTagInList' : 'addTagToList']({
          id: data,
          ...payload,
        })
      );
      if (payload.id) {
        yield put(mapShapeActions.updateTagInAllShapes(payload));
      }
    },
    function*(err) {
      yield notification('MESSAGES.SHAPES.COULD_NOT_GET_TAGS', {
        type: 'error',
      });
    },
    { loading: false }
  );
}

function* saveAreaSnapshot({ payload }) {
  yield* api(
    mapShapeService[payload.id ? 'updateAreaSnapshot' : 'saveAreaSnapshot'],
    payload,
    function*() {
      yield fork(getAreaSnapshots, { payload: payload.mapId });
      yield put(mapShapeActions.clearShapes());
      yield notification('MESSAGES.SHAPES.SUCCESSFUL_SAVED_AREA', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.SHAPES.COULD_NOT_SAVE_AREA', {
        type: 'error',
      });
    }
  );
}

function* deleteAreaSnapshot({ payload }) {
  const { shapeId } = payload;

  yield* api(
    mapShapeService[shapeId ? 'deleteAreaSnapshotShape' : 'deleteAreaSnapshot'],
    payload,
    function*() {
      yield put(mapShapeActions.deleteAreaSnapshotFromList(payload));
    },
    function*(err) {
      yield notification('MESSAGES.SHAPES.COULD_NOT_DELETE_AREA', {
        type: 'error',
      });
    }
  );
}

function* getSnapshotHistory({ payload }) {
  yield* api(
    mapShapeService.getSnapshotHistory,
    payload,
    function*(data) {
      yield put(
        mapShapeActions.updateAreaSnapshotHistory(
          data.map(item => ({ ...item, parent: payload.snapshot }))
        )
      );
    },
    function*(err) {
      yield notification('MESSAGES.SHAPES.COULD_NOT_GET_AREAS', {
        type: 'error',
      });
    }
  );
}

function* deleteTag({ payload }) {
  const { clientId } = yield select(mapSelectors.mapSelector);

  yield* api(
    mapShapeService.deleteTag,
    { clientId, tagId: payload },
    function*(data) {
      yield put(mapShapeActions.deleteTagFromList(data));
      yield put(mapShapeActions.deleteTagFromAllShapes(data));
    },
    function*(err) {
      yield notification('MESSAGES.SHAPES.COULD_NOT_GET_TAGS', {
        type: 'error',
      });
    },
    { loading: false }
  );
}

export default function*() {
  yield takeEvery(mapShapeActions.getTags.toString(), getTags);
  yield takeEvery(mapShapeActions.saveTag.toString(), saveTag);
  yield takeEvery(
    mapShapeActions.saveAreaSnapshot.toString(),
    saveAreaSnapshot
  );
  yield takeEvery(
    mapShapeActions.getAreaSnapshotHistory.toString(),
    getSnapshotHistory
  );
  yield takeEvery(
    mapShapeActions.getAreaSnapshots.toString(),
    getAreaSnapshots
  );
  yield takeEvery(
    mapShapeActions.deleteAreaSnapshot.toString(),
    deleteAreaSnapshot
  );
  yield takeEvery(mapShapeActions.deleteTag.toString(), deleteTag);
}
