import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Close from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import auth from 'commons/auth';
import { fineUploader } from 'commons/uploader';
import { VIEWS, FILE_UPLOAD_STATUS } from 'commons/constants';
import { appActions } from 'commons/store/app';
import { fileActions } from 'commons/store/file';
import { mapFileActions } from 'commons/store/mapFile';
import { mapActions, mapSelectors } from 'commons/store/map';

import FilesProgress from './FilesProgress';
import TemporaryLayers from './TemporaryLayers';
import FileUploaderForm from './FileUploaderForm';
import fileAPI from 'commons/services/file';

import styles from './styles';
import DailyUploadLimit from 'commons/components/DailyUploadLimit';

const useStyles = makeStyles(styles);

const { insertNode } = fileActions;
const { getTreeNode } = mapFileActions;
const { getRecentlyCreatedLayers } = mapActions;
const { hideUploadsDrawer, showUploadsDrawer } = appActions;

export function UploadsDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [, setState] = React.useState();

  const maps = useSelector(mapSelectors.getRecentlyLayersSelector);
  const { open = false, view = '', node = {} } = useSelector(
    ({ app: { uploads } }) => uploads
  );

  const upload = React.useRef({
    uploader: fineUploader(auth.getHeaders()),
    submitted: {},
    uploaded: [],
  });

  React.useEffect(() => {
    upload.current.uploader.on('error', handleUploadError);
    upload.current.uploader.on('complete', handleUploadComplete);
    upload.current.uploader.on('progress', handleUploadProgress);

    dispatch(getRecentlyCreatedLayers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    return () => {
      upload.current = {
        submitted: {},
        uploaded: [],
      };
    };
  }, []);

  const forceUpdate = React.useCallback(() => setState({}), []);

  let allSubmittedFiles = Object.values(upload.current.submitted).reduce(
    (acc, value) => [...acc, ...value.files],
    []
  );

  const [fileToProcess, setFileToProcess] = React.useState(null);

  const findNodeByFileId = id =>
    Object.values(upload.current.submitted).find(item =>
      item.files.some(file => file.id === id)
    );

  const updateFilesStatus = (id, file) => {
    const { SUCCESS, ERROR } = FILE_UPLOAD_STATUS;

    const savedNode = findNodeByFileId(id);
    const status = file.success ? SUCCESS : ERROR;

    upload.current.submitted[savedNode.node.id] = {
      ...savedNode,
      files: savedNode.files.map(item =>
        item.id === id ? { ...item, status } : item
      ),
    };

    forceUpdate();
  };

  const handleHideDrawer = React.useCallback(
    () => dispatch(hideUploadsDrawer()),
    [dispatch]
  );

  const handleLayerRefresh = React.useCallback(
    () => dispatch(getRecentlyCreatedLayers()),
    [dispatch]
  );

  const handleUploadProgress = (id, name, byte, size) => {
    const savedNode = findNodeByFileId(id);
    const progress = Math.ceil((byte * 100) / size);

    upload.current.submitted[savedNode.node.id] = {
      ...savedNode,
      files: savedNode.files.map(item =>
        item.id === id ? { ...item, progress } : item
      ),
    };

    forceUpdate();
  };

  const handleCancelUpload = React.useCallback(
    submittedFiles => {
      submittedFiles.forEach(file =>
        upload.current.uploader.methods.cancel(file.id)
      );
      dispatch(hideUploadsDrawer());
    },
    [dispatch]
  );

  const handleUploadedFiles = React.useCallback(
    submittedFiles => {
      const _node = upload.current.submitted[node.id] || {};
      const files = _node.files || [];

      files.unshift(...submittedFiles);

      upload.current.submitted[node.id] = { node, files };
    },
    [node]
  );

  const handleUploadError = id => {
    const savedNode = findNodeByFileId(id);

    upload.current.submitted[savedNode.node.id] = {
      ...savedNode,
      files: savedNode.files.map(item =>
        item.id === id ? { ...item, status: FILE_UPLOAD_STATUS.ERROR } : item
      ),
    };

    forceUpdate();
  };

  const handleUploadComplete = async (id, name, data, responseJSON) => {
    const response = !!responseJSON.response
      ? JSON.parse(responseJSON.response)
      : {};

    const notUploadedYet = upload.current.uploaded.indexOf(id) === -1;
    const savedNode = await findNodeByFileId(id);
    let dataFormatada = new Date(savedNode.node.date);
    dataFormatada =
      dataFormatada.getFullYear() +
      '-' +
      (dataFormatada.getMonth() + 1) +
      '-' +
      dataFormatada.getDate();
    if (!!response.id && !!savedNode && notUploadedYet) {
      await dispatch(
        await insertNode({
          parentId: savedNode.node.id,
          node: {
            name: data.name,
            parent: savedNode.node.id,
            data: {
              id: data.id,
              length: data.length,
              type: data.type,
              date: dataFormatada.toDateString,
            },
          },
          silent: true,
        })
      );

      await dispatch(await getTreeNode({ node: savedNode.node.id }));
      await updateUsedDailyLimit();
      upload.current.uploaded.push(id);
    }

    updateFilesStatus(id, data);
    upload.current.uploader.methods.removeFileRef(id);
  };

  const updateUsedDailyLimit = React.useCallback(async () => {
    try {
      const response = await fileAPI.getUsedDailyLimit();
      dispatch(appActions.getUsedDailyLimit(response.data));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  const handleUploadStoredFiles = React.useCallback(
    files => {
      upload.current.uploader.methods.uploadStoredFiles();
      handleUploadedFiles(files);
      dispatch(showUploadsDrawer({ view: VIEWS.UPLOAD_PROGRESS }));
    },
    [dispatch, handleUploadedFiles]
  );

  useEffect(() => {
    let files;
    const init = async () => {
      if (view === VIEWS.REDUCE_PROGRESS) {
        if (!!node) {
          files = [
            { duplicated: 0, id: node.id, name: node.name, progress: 100 },
          ];
          upload.current.submitted[node.parent] = { node, files };
          setFileToProcess(files);
        }
        const response = await fileAPI.reduceVideo(node.data.id);
        if (response.data) {
          files[0].status = FILE_UPLOAD_STATUS.SUCCESS;
          upload.current.submitted[node.parent] = { node, files };
          setFileToProcess(files);
          forceUpdate();
        } else {
          files[0].status = FILE_UPLOAD_STATUS.PROCESSERROR;
          upload.current.submitted[node.parent] = { node, files };
          setFileToProcess(files);
          forceUpdate();
        }
      }
    };
    init();
  }, [view, forceUpdate, node]);

  return (
    <Drawer anchor="right" open={open} onClose={handleHideDrawer} keepMounted>
      <div className={classes.header}>
        <Typography variant="h6">
          <FormattedMessage id="LABELS.COMMONS.UPLOADS" />
        </Typography>
        <IconButton className={classes.iconButton} onClick={handleHideDrawer}>
          <Close />
        </IconButton>
      </div>

      <DailyUploadLimit classes={classes} />

      <div className={classes.content}>
        {view === VIEWS.UPLOAD_FILES && (
          <FileUploaderForm
            uploader={upload.current.uploader}
            node={node}
            onUploadStoredFiles={handleUploadStoredFiles}
            onCancel={handleCancelUpload}
          />
        )}
        {view === VIEWS.UPLOAD_PROGRESS && (
          <>
            <FilesProgress files={allSubmittedFiles} />
            <TemporaryLayers maps={maps} onLayerRefresh={handleLayerRefresh} />
          </>
        )}
        {view === VIEWS.REDUCE_PROGRESS && (
          <>
            <FilesProgress
              files={allSubmittedFiles}
              fileToProcess={fileToProcess}
            />
            <TemporaryLayers maps={maps} onLayerRefresh={handleLayerRefresh} />
          </>
        )}
      </div>
    </Drawer>
  );
}

export default UploadsDrawer;
