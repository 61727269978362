export default {
  container: {
    display: 'flex',
    cursor: 'pointer',
    padding: '.5rem',
    alignItems: 'center',
    borderTop: '1px solid #efefef',
    '&:hover': {
      backgroundColor: '#efefef',
    },
  },
  image: {
    marginRight: '1rem',
    height: '4rem',
    width: '4rem',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
};
