import React, { useMemo } from 'react';

import { object, bool, array } from 'prop-types';
import { compose } from 'recompose';
import { useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';

import MessageHolder from 'commons/components/MessageHolder';
import { ActivityFormContext } from 'commons/contexts';
import { appActions } from 'commons/store/app';

import FormRender from './FormRender';
import ElementFormValues from './ElementFormValues';
import ElementFormItem from './ElementFormItem';

export function ActivityDynamicForm({ intl, blocked, form, elements }) {
  const dispatch = useDispatch();

  const { formValues, formSchemas, onSave } = React.useContext(
    ActivityFormContext
  );

  const formSchemasIndex = useMemo(() => {
    return formSchemas.reduce((acc, schemaIndex) => {
      // ElementId eh opcional. Atividades sem elementos.
      acc[schemaIndex.formId + (schemaIndex.elementId || '')] =
        schemaIndex.schema;
      return acc;
    }, {});
  }, [formSchemas]);

  const handleFormSave = React.useCallback(values => onSave(values), [onSave]);

  const handleElementSave = React.useCallback(
    (element, values) => onSave(values, element),
    [onSave]
  );

  const handlElementSelect = React.useCallback(
    element => {
      dispatch(
        appActions.showGlobalDrawer({
          title: `${intl.formatMessage({
            id: 'LABELS.ACTIVITIES.FILL_ACTIVITY',
          })} - ${form.name} - ${element.name}`,
          component: (
            <ElementFormValues
              blocked={blocked}
              form={form}
              schema={formSchemasIndex[form.formId + element.id]}
              element={element}
              onSave={handleElementSave}
            />
          ),
        })
      );
    },
    [blocked, dispatch, form, handleElementSave, intl, formSchemasIndex]
  );

  if (!elements.length && !formSchemasIndex[form.formId]) {
    return (
      <MessageHolder
        title="MESSAGES.ACTIVITIES.NO_ELEMENTS"
        message="MESSAGES.ACTIVITIES.YOU_HAVE_SELECTED_A_ELEMENT_FORM"
      />
    );
  }

  if (!elements.length) {
    return (
      <FormRender
        blocked={blocked}
        schema={formSchemasIndex[form.formId]}
        values={formValues[form.formId]}
        onSave={handleFormSave}
      />
    );
  }

  return (
    <>
      {elements.map(item => (
        <ElementFormItem
          key={item.id}
          element={item}
          onSelect={handlElementSelect}
        />
      ))}
    </>
  );
}

ActivityDynamicForm.propTypes = {
  /* own props */
  elements: array.isRequired,
  form: object.isRequired,
  blocked: bool.isRequired,
  /* react-intl */
  intl: object.isRequired,
};

export default compose(
  injectIntl,
  React.memo
)(ActivityDynamicForm);
