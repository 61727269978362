import React from 'react';
import { object, func } from 'prop-types';

import SwipeableViews from 'react-swipeable-views';
import qs from 'querystring';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

import auth from 'commons/auth';
import routes from 'commons/routes';
import { authActions } from 'commons/store/auth';
import { appActions } from 'commons/store/app';

import LoginContainer from 'commons/components/LoginContainer';

import LoginForm from './LoginForm';
import RecoveryForm from './RecoveryForm';

import styles from './styles';

export class Login extends React.PureComponent {
  state = {
    index: 0,
  };

  static propTypes = {
    /* state app */
    settings: object.isRequired,
    /* auth actions */
    logIn: func.isRequired,
    recoveryPassword: func.isRequired,
    /* app actions */
    clearApp: func.isRequired,
    /* material-ui styles */
    classes: object.isRequired,
    /* react-router */
    history: object.isRequired,
  };

  static getDerivedStateFromProps({ history }) {
    !!auth.getToken() && history.push(routes.urls.PANEL);
    return null;
  }

  constructor(props) {
    super(props);
    const {
      history: { location },
    } = props;
    this.redirect = qs.parse((location.search + '').substring(1)).redirect;
  }

  componentDidMount() {
    this.props.clearApp();
  }

  handleLogIn = values =>
    this.props.logIn({
      ...values,
      redirect: this.redirect,
    });

  handleRecovery = values => this.props.recoveryPassword(values.username);

  hangleViewToggle = () => {
    this.setState(({ index }) => ({ index: !!index ? 0 : 1 }));
  };

  render() {
    const {
      classes,
      settings: { logo, changePasswordDisabled },
    } = this.props;
    const { index } = this.state;

    return (
      <LoginContainer>
        <div className={classes.form}>
          {index === 1 && (
            <Button
              className={classes.backButton}
              onClick={this.hangleViewToggle}
            >
              <KeyboardArrowLeft />
              <FormattedMessage id="LABELS.COMMONS.BACK" />
            </Button>
          )}
          <img className={classes.logo} src={logo} alt="logo" />
          <SwipeableViews axis="x" index={index} className={classes.swipeViews}>
            <LoginForm
              onSubmit={this.handleLogIn}
              onRecovery={this.hangleViewToggle}
              changePasswordDisabled={changePasswordDisabled}
            />
            <RecoveryForm
              canRender={!!index}
              onRecovery={this.handleRecovery}
            />
          </SwipeableViews>
        </div>
      </LoginContainer>
    );
  }
}

const mapStateToProps = ({ app: { settings } }) => ({ settings });

const { clearApp } = appActions;

const { recoveryPassword, logIn } = authActions;

const mapDispatchToProps = { recoveryPassword, clearApp, logIn };

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Login);
