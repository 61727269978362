export default {
  container: {
    flex: '1',
    display: 'flex',
    width: '32rem',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    padding: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    flex: '1',
    overflow: 'auto',
  },
  loading: {
    color: '#fff',
    marginRight: '.5rem',
  },
};
