export default {
  container: {
    display: 'flex',
    backgroundColor: '#f9f9f9',
    padding: '.5rem 1rem',
    borderTop: '1px solid #dcdcdc',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 1.5em',
    flex: '1',
  },
  group: {
    display: 'flex',
    marginBottom: '.5rem',
    justifyContent: 'space-between',
  },
  item: {},
  image: {
    width: '12rem',
    height: '8rem',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
};
