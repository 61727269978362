export default {
  header: {
    display: 'flex',
    height: '3.5em',
    alignItems: 'center',
    minHeight: '3.5rem',
    padding: '.2rem 0 .2rem 1rem',
    justifyContent: 'space-between',
    boxShadow: '0 0 10px 0 #0000007a',
    background: '#f9f9f9',
    zIndex: 9,
  },
  content: {
    width: '46rem',
    overflow: 'hidden auto',
  },
  iconButton: {
    margin: '.3rem',
    height: 43,
    width: 43,
  },
  uploadLimitInfo: {
    margin: '30px 20px 0 20px',
    borderBottom: '1px solid #ccc',
    paddingBottom: '20px',
    fontSize: '16px',
  },
};
