export default {
  container: {
    marginTop: '1rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 .5rem',
  },
  hit: {
    fontSize: '.9rem',
    color: '#808080',
  },
  noItemsMessage: {
    textAlign: 'center',
    fontSize: '.9rem',
    margin: '1rem 6rem',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  loading: {
    textAlign: 'center',
    margin: '.5rem 0 1rem',
  },
  contentList: {
    flex: '1',
    marginTop: '.5rem',
    backgroundColor: '#f7f4f4',
    overflow: 'hidden auto',
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem .5rem .6rem 0',
  },
};
