import axios from 'axios';

const getServices = () => axios.get('/requests/services/summary');

const getService = serviceId =>
  axios.get(`/requests/services/${serviceId}`, {
    transformResponse: [
      data => {
        let jsonData = JSON.parse(data);

        let mainForm = jsonData.forms.find(form => form.mainForm);
        let documentForm = jsonData.forms.find(form => !form.mainForm);

        jsonData.formId = mainForm ? mainForm.formId : undefined;
        jsonData.documentsFormId = documentForm
          ? documentForm.formId
          : undefined;

        return jsonData;
      },
    ],
  });

const getPaymentMethods = () =>
  axios.get('/requests/integration/gru', {
    transformResponse: [
      data => {
        return JSON.parse(data).map(element => {
          return {
            label: element.deServico,
            value: element.reference,
          };
        });
      },
    ],
  });

const getDynamicForms = ({ tag }) =>
  axios.get('/forms/summary', {
    params: {
      tag: tag,
    },
    transformResponse: [
      data => {
        let parsedJson = JSON.parse(data);
        let result = {};
        Object.keys(parsedJson).forEach(
          key =>
            (result[key] = parsedJson[key].map(obj => ({
              label: obj.name,
              value: obj.id,
            })))
        );
        return result;
      },
    ],
  });

const saveService = service => axios.post('/requests/services', service);

const updateService = payload =>
  axios.put(`/requests/services/${payload.service.id}`, payload);

const getServicesByApplicationId = applicationId =>
  axios.get(`/requests/services/application/${applicationId}`);

const saveRequestFromEntry = ({ entryId, serviceId }) =>
  axios.put(`/requests/entry/${entryId}/${serviceId}`);

export default {
  getServices,
  getPaymentMethods,
  getDynamicForms,
  saveService,
  updateService,
  getService,
  getServicesByApplicationId,
  saveRequestFromEntry,
};
