import produce from 'immer';

import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { mapThemeActions } from 'commons/store/mapTheme';

/* selectors */
const themesSelector = ({ mapTheme }) => mapTheme.themes;

const themesWithNoElementsSelector = ({ mapTheme }) => {
  return Object.values(mapTheme.themes).reduce(
    (acc, { elements, ...restTheme }) => {
      acc[restTheme.id] = restTheme;
      return acc;
    },
    {}
  );
};

const themeSelector = ({ mapTheme }, theme) => mapTheme.themes[theme.id];

const elementEditingSelector = ({ mapTheme }) => ({ ...mapTheme.editing });

const elementEditingLocationSelector = createSelector(
  elementEditingSelector,
  el => el.location
);

const elementsSelector = createSelector(
  themesSelector,
  themes => Object.values(themes).flatMap(({ elements = [] }) => elements)
);

export const selectors = {
  themeSelector,
  themesSelector,
  elementsSelector,
  elementEditingSelector,
  elementEditingLocationSelector,
  themesWithNoElementsSelector,
};

/* state */
const editing = {
  theme: null,
  element: null,
  location: null,
  schema: null,
  category: '',
  elementType: '',
  videoPlayerOpened: false,
  isGeoReferencedVideo: false,
};

const initialState = {
  themes: {}, //details, filters, elements, categories, elementTypes
  editing,
};

/* handler */
export default handleActions(
  {
    [mapThemeActions.restoreState]: (_, { payload }) => payload,

    [mapThemeActions.clearMapTheme]: () => initialState,

    [mapThemeActions.addMapTheme]: produce((draft, { payload }) => {
      const { data, ...details } = payload;

      draft.themes[details.id] = {
        details,
        ...data,
      };
    }),

    [mapThemeActions.updateIntoThemes]: produce((draft, { payload }) => {
      const { themeId, key, entity } = payload;
      const theme = draft.themes[themeId];

      draft.themes[themeId] = { ...theme, [key]: entity };
    }),

    [mapThemeActions.closeElementEditor]: produce(draft => {
      draft.editing = editing;
    }),

    [mapThemeActions.openElementEditor]: produce((draft, { payload }) => {
      draft.editing = { ...draft.editing, ...payload };
    }),

    [mapThemeActions.updateEditingElement]: produce((draft, { payload }) => {
      draft.editing = { ...draft.editing, ...payload };
    }),

    [mapThemeActions.addElementToTheme]: produce((draft, { payload }) => {
      draft.themes[payload.themeId].elements.unshift(payload);
      draft.editing = draft.videoPlayerOpened
        ? { ...editing, location: draft.editing.location }
        : editing;
    }),

    [mapThemeActions.updateElementInTheme]: produce((draft, { payload }) => {
      const elements = draft.themes[payload.themeId].elements;

      draft.editing = editing;
      draft.themes[payload.themeId].elements = elements.map(item =>
        item.id === payload.id ? payload : item
      );
    }),

    [mapThemeActions.deleteElementFromTheme]: produce((draft, { payload }) => {
      if (draft.themes && draft.themes[payload.themeId]) {
        const elements = draft.themes[payload.themeId].elements;

        draft.themes[payload.themeId].elements = elements.filter(
          item => item.id !== payload.id
        );
      }
    }),

    [mapThemeActions.openVideoPlayer]: produce((draft, { payload }) => {
      draft.videoPlayerOpened = payload;
    }),

    [mapThemeActions.setIsGeoReferencedVideo]: produce((draft, { payload }) => {
      draft.isGeoReferencedVideo = payload;
    }),
  },
  initialState
);
