export default ({ palette }) => ({
  container: {
    margin: '.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: 'fit-content',
    maxWidth: '80%',
    padding: '.5rem .75rem',
    minHeight: '3rem',
    '&.mine': {
      alignSelf: 'flex-end',
    },
  },
  attachment: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    marginTop: '.7rem',
    paddingTop: '.5rem',
    borderTop: '1px solid #e6e6e6',
  },
  name: {
    margin: '0 2rem .25rem 0',
    '&.mine': {
      color: palette.primary.main,
    },
  },
});
