import FineUploaderTraditional from 'fine-uploader-wrappers';
import axios from 'axios';

import { baseURL } from 'commons/api';

const API_REST_UPLOAD_TEMP = `${baseURL}/files/uploadtemp`;
const API_REST_UPLOAD = '/files/upload';

export const fileUploader = async data => {
  const response = await axios.post(API_REST_UPLOAD, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

export const fineUploader = headers =>
  new FineUploaderTraditional({
    options: {
      request: {
        endpoint: API_REST_UPLOAD_TEMP,
        customHeaders: headers,
        requireSuccessJson: false,
      },
      autoUpload: false,
    },
  });
