import { takeEvery, put, select } from 'redux-saga/effects';

import notification from 'commons/notification';
import api from 'commons/api';
import routes from 'commons/routes';
import { layerTypeService } from 'commons/services';
import { layerTypeActions } from 'commons/store/layerType';
import { mapLayerActions } from 'commons/store/mapLayer';

function* getLayerTypes() {
  yield* api(
    layerTypeService.getLayerTypes,
    {},
    layerTypeActions.updateLayerTypesList,
    err => console.log(err),
    { multiple: false }
  );
}

function* getLayerTypeDetails({ payload }) {
  yield* api(
    layerTypeService.getLayerTypeDetails,
    payload,
    function*(data) {
      const currApp = yield select(({ app }) => app.application.type);

      yield put(layerTypeActions.saveLayerTypeAsCurrent(data));
      if (currApp === routes.types.maps) {
        yield put(
          mapLayerActions.mergeLayerTypeAttrs({
            typeId: payload,
            data,
          })
        );
      }
    },
    function*(err) {
      yield notification('MESSAGES.LAYER_TYPE.COULD_NOT_GET_LAYER_TYPE', {
        type: 'error',
      });
    }
  );
}

function* saveLayerType({ payload }) {
  yield* api(
    layerTypeService.saveLayerType,
    payload,
    function*(data) {
      yield put(layerTypeActions.addLayerTypeToList(data));
      yield put(layerTypeActions.cancelLayerTypeEditing());
      yield notification('MESSAGES.LAYER_TYPE.SUCCESSFUL_SAVED_LAYER_TYPE', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.LAYER_TYPE.COULD_NOT_SAVE_LAYER_TYPE', {
        type: 'error',
      });
    },
    { loading: false }
  );
}

function* updateLayerType({ payload }) {
  yield* api(
    layerTypeService.updateLayerType,
    payload,
    function*(data) {
      yield put(layerTypeActions.updateLayerTypeInList(data));
      yield put(layerTypeActions.saveLayerTypeAsCurrent(data));
      yield notification('MESSAGES.LAYER_TYPE.SUCCESSFUL_SAVED_LAYER_TYPE', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.LAYER_TYPE.COULD_NOT_SAVE_LAYER_TYPE', {
        type: 'error',
      });
    },
    { loading: false }
  );
}

function* deleteLayerType({ payload }) {
  yield* api(
    layerTypeService.deleteLayerType,
    payload,
    function*(data) {
      yield put(layerTypeActions.removeLayerTypeFromList(data));
      yield notification('MESSAGES.LAYER_TYPE.SUCCESSFUL_DELETED_LAYER_TYPE', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.LAYER_TYPE.COULD_NOT_DELETE_LAYER_TYPE', {
        type: 'error',
      });
    },
    { loading: false }
  );
}

export default function*() {
  yield takeEvery(layerTypeActions.getLayerTypes.toString(), getLayerTypes);
  yield takeEvery(layerTypeActions.saveLayerType.toString(), saveLayerType);
  yield takeEvery(layerTypeActions.updateLayerType.toString(), updateLayerType);
  yield takeEvery(
    layerTypeActions.getLayerTypeDetails.toString(),
    getLayerTypeDetails
  );
  yield takeEvery(layerTypeActions.deleteLayerType.toString(), deleteLayerType);
}
