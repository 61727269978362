import React from 'react';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup';
import RadioGroup from '@material-ui/core/RadioGroup';
import { DatePicker } from '@material-ui/pickers';

import MultiSelect from 'commons/components/MultiSelect';
import ImageUploader from 'commons/components/ImageUploader';
import SelectCircularProgress from 'commons/components/SelectCircularProgress';
import SelectAutocomplete from 'commons/components/SelectAutocomplete';

//TODO: Improve Autocomplete component

export { TextFieldAdapter } from './adapters/TextFieldAdapter';

export const RadioButtonAdapter = ({
  input: { name, value, onChange, ...restInput },
  meta,
  label,
  options = [],
  ...rest
}) => {
  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        name={name}
        value={value || restInput.value}
        onChange={onChange}
      >
        <FormGroup row>
          {options.map(option => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio checked={value === option.value} />}
              label={option.name}
            />
          ))}
        </FormGroup>
      </RadioGroup>
    </FormControl>
  );
};

export const SwitchAdapter = ({
  input: { checked, name, onChange, ...restInput },
  value,
  meta,
  ...rest
}) => (
  <Switch
    {...rest}
    checked={!!checked}
    name={name}
    onChange={onChange}
    error={(meta.error || meta.submitError) && meta.touched}
    value={value}
    inputProps={restInput}
  />
);

export const CheckboxAdapter = ({
  input: { checked, name, onChange, ...restInput },
  value,
  meta,
  ...rest
}) => (
  <Checkbox
    {...rest}
    name={name}
    onChange={onChange}
    checked={!!checked}
    error={(meta.error || meta.submitError) && meta.touched}
    value={value}
    inputProps={restInput}
  />
);

const renderSelectOptions = (options, objectAsValue) =>
  options.map(item => (
    <MenuItem key={item.id} value={objectAsValue ? item : item.id}>
      {item.name}
    </MenuItem>
  ));

export const ImageUploadAdapter = ({
  input: { name, onChange, value },
  className,
  buttonClassName,
  showUploadButton,
}) => (
  <ImageUploader
    className={className}
    buttonClassName={buttonClassName}
    name={name}
    value={value}
    onChange={onChange}
    showUploadButton={showUploadButton}
  />
);

//TODO: use this as default
//TODO: rename this export to match the name pattern
export { SelectAdapter as Selector } from './adapters/SelectAdapter';

export const MultiSelectAdapter = ({
  input: { name, onChange, value, ...restInput },
  meta,
  label,
  disabled,
  className,
  fullWidth,
  nomarginbottom,
  ...rest
}) => (
  <FormControl
    className={className}
    style={{ marginBottom: nomarginbottom ? '0px' : '8px' }}
    margin="normal"
    error={(meta.error || meta.submitError) && meta.touched}
    disabled={disabled}
    fullWidth={fullWidth}
  >
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <MultiSelect
      {...rest}
      name={name}
      error={(meta.error || meta.submitError) && meta.touched}
      inputProps={restInput}
      onChange={onChange}
      value={value}
    />
    <FormHelperText>
      {meta.touched ? meta.error || meta.submitError : undefined}
    </FormHelperText>
  </FormControl>
);

export const AutocompleteAdapter = ({
  input: { name, value, onChange },
  meta,
  label,
  options,
  className,
  fullWidth,
  ...rest
}) => {
  const handleChange = ({ value }) => onChange(value);

  return (
    <FormControl
      className={className}
      fullWidth={fullWidth}
      error={(meta.error || meta.submitError) && meta.touched}
    >
      <SelectAutocomplete
        name={name}
        value={value}
        onChange={handleChange}
        label={label}
        options={options}
        {...rest}
      />
      {!!(meta.error || meta.submitError) && meta.touched && (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      )}
    </FormControl>
  );
};

export const SelectAdapter = ({
  input: { name, value = '', onChange, parse, format, ...restInput },
  meta,
  fullWidth,
  label,
  className,
  options,
  children,
  loading,
  objectAsValue = false,
  ...rest
}) => (
  <FormControl
    className={className}
    fullWidth={fullWidth}
    error={(meta.error || meta.submitError) && meta.touched}
  >
    <InputLabel htmlFor={name}>{label}</InputLabel>
    <Select
      {...rest}
      value={value}
      onChange={onChange}
      inputProps={restInput}
      {...(loading
        ? { IconComponent: SelectCircularProgress, disabled: true }
        : null)}
    >
      {options ? renderSelectOptions(options, objectAsValue) : children}
    </Select>
    {!!(meta.error || meta.submitError) && meta.touched && (
      <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
    )}
  </FormControl>
);

export const DatePickerAdapter = ({
  input: { name, onChange, value, ...restInput },
  meta,
  ...rest
}) => (
  <DatePicker
    {...rest}
    name={name}
    onChange={onChange}
    InputProps={{
      endAdornment: <ArrowDropDownIcon />,
    }}
    value={value}
    error={(meta.error || meta.submitError) && meta.touched}
    format="DD/MM/YYYY"
  />
);
