import { takeEvery, put, fork } from 'redux-saga/effects';

import api from 'commons/api';
import notification from 'commons/notification';
import { userService } from 'commons/services';
import { userActions } from 'commons/store/user';
import { authActions } from 'commons/store/auth';
import { loadingSaga } from 'commons/store/loading';
import { mapLayerActions } from 'commons/store/mapLayer';

function* getProfiles({ payload }) {
  yield* api(
    userService.getProfiles,
    payload,
    userActions.updateProfiles,
    err => console.log(err),
    { multiple: false }
  );
}

function* getUsersByClient({ payload }) {
  try {
    const { data } = yield userService.getAllUsers(payload);
    yield put(userActions.updateUsers(data));
  } catch (err) {
    console.log(err);
  }
}

function* toogleClientsListAutomatically() {
  yield* api(
    userService.toggleClients,
    {},
    authActions.updateLoggedInUser,
    err => console.log(err),
    { multiple: false }
  );
}

function* toogleMapsListAutomatically() {
  yield* api(
    userService.toggleMaps,
    {},
    authActions.updateLoggedInUser,
    err => console.log(err),
    { multiple: false }
  );
}

function* toogleMapsListOrder() {
  yield* api(
    userService.toggleMapsOrder,
    {},
    authActions.updateLoggedInUser,
    err => console.log(err),
    { multiple: false }
  );
}

function* getAllUsers({ payload }) {
  yield* api(
    userService.getAllUsers,
    payload,
    userActions.updateUsers,
    function*(err) {
      yield notification('MESSAGES.USER.COULD_NOT_GET_USERS', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* getActiveUsers({ payload }) {
  yield* api(
    userService.getActiveUsers,
    payload,
    userActions.updateUsers,
    function*(err) {
      yield notification('MESSAGES.USER.COULD_NOT_GET_USERS', {
        type: 'error',
      });
    },
    { multiple: false, loading: true }
  );
}

function* getNewUsersAssociation({ payload }) {
  yield* api(
    userService.getAllUsers,
    payload,
    userActions.updateNewUsersAssociation,
    function*(err) {
      yield notification('MESSAGES.USER.COULD_NOT_GET_USERS', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* getProfile({ payload }) {
  yield* api(
    userService.getProfile,
    payload,
    userActions.updateProfile,
    function*(err) {
      yield notification('MESSAGES.USER.COULD_NOT_GET_PROFILE', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* getUsersByProfile({ payload }) {
  yield* api(
    userService.getUsersByProfile,
    payload,
    userActions.updateUsers,
    function*(err) {
      yield notification('MESSAGES.USER.COULD_NOT_GET_USERS', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* getUsersByFilter({ payload }) {
  const { map, client, associate = false, forActivity = false } = payload;

  yield* api(
    userService.getUsersByFilter,
    { map, client },
    function*(data) {
      if (associate) {
        yield put(userActions.updateNewUsersAssociation(data));
        return;
      }
      const action = !forActivity
        ? userActions.updateUsers
        : userActions.updateCurrentMapUsers;

      yield put(action(data));
    },
    function*(err) {
      yield notification('MESSAGES.USER.COULD_NOT_GET_USERS', {
        type: 'error',
      });
    }
  );
}

function* getUsersByMaps({ payload }) {
  yield* api(
    userService.getUsersByMaps,
    payload,
    userActions.updateUsers,
    function*(err) {
      yield notification('MESSAGES.USER.COULD_NOT_GET_USERS', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* saveProfile({ payload }) {
  yield* api(
    userService.saveProfile,
    payload,
    function*() {
      yield fork(getProfiles, { payload: null });

      yield notification('MESSAGES.USER.SUCCESSFUL_SAVED_PROFILE', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.USER.COULD_NOT_SAVE_PROFILE', {
        type: 'error',
      });
    }
  );
}

function* toggleMapActiveLayer({ payload }) {
  yield* api(
    userService.toggleMapActiveLayer,
    payload,
    function*() {},
    err => console.log(err),
    { delay: 0, loading: false }
  );
}

function* updateLayerMapOpacity({ payload }) {
  yield* api(
    userService.updateLayerMapOpacity,
    payload,
    function*() {},
    err => console.log(err),
    { delay: 0, loading: false }
  );
}

function* getUser({ payload }) {
  yield* api(
    userService.getUser,
    payload,
    function*(data) {
      yield put(mapLayerActions.updateActiveLayers(data.preferences));
      yield put(userActions.updateUser(data));
    },
    function*(err) {
      yield notification('MESSAGES.USER.COULD_NOT_GET_USER', { type: 'error' });
    }
  );
}

// TODO: Depois de refatorar o userSlice, remover esse código.
// function* saveUser({ payload }) {
//   yield* api(
//     userService.saveUser,
//     payload,
//     function*() {
//       yield put(userActions.getAllUsers());
//       yield notification('MESSAGES.USER.SUCCESSFUL_SAVED_USER', {
//         type: 'success',
//       });
//     },
//     function*(err) {
//       const {
//         response: { data },
//       } = err;
//       const isStringData = typeof data === 'string';

//       yield notification(
//         isStringData ? data : 'MESSAGES.USER.COULD_NOT_SAVE_USER',
//         { type: 'error', translate: !isStringData }
//       );
//     }
//   );
// }

function* removeUserProfilePermission({ payload: { username, profileId } }) {
  yield* api(
    userService.removeUserProfilePermission,
    { username, profileId },
    function*() {
      yield fork(getUsersByProfile, { payload: profileId });

      yield notification(
        'MESSAGES.USER.SUCCESSFUL_REMOVED_ASSOCIATION_PROFILE',
        {
          type: 'success',
        }
      );
    },
    function*(err) {
      yield notification(
        'MESSAGES.USER.COULD_NOT_REMOVE_USER_PROFILE_PERMISSION',
        { type: 'error' }
      );
    }
  );
}

function* addUserProfilePermission({ payload: { username, profileId } }) {
  yield* api(
    userService.addUserProfilePermission,
    { username, profileId },
    function*() {
      yield fork(getUsersByProfile, { payload: profileId });

      yield notification('MESSAGES.USER.SUCCESSFUL_SAVED_ASSOCIATION_PROFILE', {
        type: 'success',
      });
    },
    function*(err) {
      console.log(err);
      yield notification(
        'MESSAGES.USER.COULD_NOT_ADD_USER_PROFILE_PERMISSION',
        {
          type: 'error',
        }
      );
    }
  );
}

function* getAvailablePermissions() {
  yield* api(
    userService.getAvailablePermissions,
    {},
    userActions.getAvailablePermissionsSuccess,
    err => console.log(err),
    { multiple: false }
  );
}

function* getProfilesByUser({ payload }) {
  yield* api(
    userService.getProfilesByUser,
    payload,
    userActions.updateUserProfiles,
    err => console.log(err),
    { multiple: false }
  );
}

function* setUserProfiles({ payload }) {
  yield* api(
    userService.setUserProfiles,
    payload,
    function*() {
      yield notification('MESSAGES.USER.SUCCESSFUL_SAVED_USER', {
        type: 'success',
      });
    },
    function*(err) {
      const {
        response: { data },
      } = err;
      const isStringData = typeof data === 'string';

      yield notification(
        isStringData ? data : 'MESSAGES.USER.COULD_NOT_SAVE_USER',
        { type: 'error', translate: !isStringData }
      );
    }
  );
}

function* updateMapAccess({ payload }) {
  yield* api(
    userService.updateMapAccess,
    payload,
    function*() {},
    err => console.log(err),
    { delay: 0, loading: false }
  );
}

export default function*() {
  yield takeEvery(
    userActions.getUsersByClient.toString(),
    loadingSaga(getUsersByClient)
  );
  yield takeEvery(userActions.getProfiles.toString(), getProfiles);
  yield takeEvery(userActions.getProfile.toString(), getProfile);
  yield takeEvery(userActions.getAllUsers.toString(), getAllUsers);
  yield takeEvery(
    userActions.getActiveUsers.toString().toString(),
    getActiveUsers
  );
  yield takeEvery(userActions.getUser, getUser);
  yield takeEvery(userActions.getUsersByProfile.toString(), getUsersByProfile);
  yield takeEvery(userActions.getUsersByMaps.toString(), getUsersByMaps);
  yield takeEvery(userActions.getUsersByFilter.toString(), getUsersByFilter);
  yield takeEvery(userActions.saveProfile.toString(), saveProfile);
  yield takeEvery(
    userActions.toggleMapActiveLayer.toString(),
    toggleMapActiveLayer
  );
  yield takeEvery(
    userActions.updateLayerMapOpacity.toString(),
    updateLayerMapOpacity
  );
  yield takeEvery(
    userActions.toogleClientsListAutomatically.toString(),
    toogleClientsListAutomatically
  );
  yield takeEvery(
    userActions.toogleMapsListAutomatically.toString(),
    toogleMapsListAutomatically
  );
  yield takeEvery(
    userActions.toogleMapsListOrder.toString(),
    toogleMapsListOrder
  );
  yield takeEvery(
    userActions.removeUserProfilePermission.toString(),
    removeUserProfilePermission
  );
  yield takeEvery(
    userActions.addUserProfilePermission.toString(),
    addUserProfilePermission
  );
  yield takeEvery(
    userActions.getNewUsersAssociation.toString(),
    getNewUsersAssociation
  );
  yield takeEvery(
    userActions.getAvailablePermissions.toString(),
    getAvailablePermissions
  );
  yield takeEvery(userActions.getProfilesByUser.toString(), getProfilesByUser);
  yield takeEvery(userActions.setUserProfiles.toString(), setUserProfiles);
  yield takeEvery(userActions.updateMapAccess.toString(), updateMapAccess);
}
