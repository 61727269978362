import { takeEvery, put, take } from 'redux-saga/effects';

import auth from 'commons/auth';
import routes from 'commons/routes';
import api from 'commons/api';
import { abilityHistoryListener } from 'commons/ability';
import { history } from 'commons/history';
import { appService } from 'commons/services';
import { appActions } from 'commons/store/app';
import { authActions } from 'commons/store/auth';

function* goToCrashRoute() {
  const url = routes.urls.WRONG;
  auth.clear();
  if (window.location.pathname !== url) {
    yield history.push(url);
  }
}

function* getModules() {
  yield* api(
    appService.getModules,
    {},
    function*(data) {
      yield put(appActions.updateModules(data));
      //TODO: update application and module regarding url
    },
    function*(err) {
      yield goToCrashRoute();
    }
  );
}

function* getAppSettings() {
  yield* api(
    appService.getAppSettings,
    {},
    function*(data) {
      if (!data) {
        yield goToCrashRoute();
        return;
      }
      yield put(appActions.updateAppSettings(data));
    },
    function*(err) {
      yield goToCrashRoute();
      yield put(appActions.clearApp());
    }
  );
}

function* initApp() {
  yield put(appActions.getAppSettings());
  yield take(appActions.updateAppSettings.toString());

  if (!!auth.getToken()) {
    yield put(appActions.getModules());
    yield put(authActions.getLoggedInUser());
  }

  yield take(authActions.updateLoggedInUser.toString());

  abilityHistoryListener();

  history.listen(abilityHistoryListener);
}

export default function*() {
  yield takeEvery(appActions.getModules.toString(), getModules);
  yield takeEvery(appActions.getAppSettings.toString(), getAppSettings);
  yield takeEvery(appActions.initApp.toString(), initApp);
}
