import { createIntl, createIntlCache } from 'react-intl';

import { DEFAULT_LOCALE } from 'commons/constants';

const cache = createIntlCache();

export const getLang = () => {
  const lang =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage ||
    DEFAULT_LOCALE;

  return lang.split('-')[0];
};

export const flattenMessages = (nestedMessages, prefix = '') => {
  let value = '';
  let prefixedKey = '';
  return Object.keys(nestedMessages).reduce((messages, key) => {
    value = nestedMessages[key];
    prefixedKey = prefix ? `${prefix}.${key}` : key;

    typeof value === 'string'
      ? (messages[prefixedKey] = value)
      : Object.assign(messages, flattenMessages(value, prefixedKey));

    return messages;
  }, {});
};

export const getIntlProviderConfig = (fetchedLocale, lang = getLang()) => {
  const language = fetchedLocale[lang] ? lang : DEFAULT_LOCALE;
  const intlProvider = {
    locale: language,
    messages: flattenMessages(fetchedLocale[language]),
  };
  sessionStorage.setItem('intlProvider', JSON.stringify(intlProvider));
  return intlProvider;
};

export function getFormattedMessage(id) {
  const config = JSON.parse(sessionStorage.getItem('intlProvider'));

  const intl = createIntl(
    {
      locale: getLang(),
      messages: config.messages,
    },
    cache
  );

  return intl.formatMessage({ id });
}
