export const LATE = {
  id: 'LATE',
  initials: 'A',
  name: 'Atrasada',
  color: '#ff0000',
};
export const WARNING = {
  id: 'WARNING',
  initials: 'PC',
  name: 'Prazo Crítico',
  color: '#ffa500',
};
export const RUNNING = {
  id: 'RUNNING',
  initials: 'DP',
  name: 'Dentro do Prazo',
  color: '#008000',
};
export const FINISHED = {
  id: 'FINISHED',
  initials: 'F',
  name: 'Finalizada',
  color: '#0000ff',
};
export const WAITING = {
  id: 'WAITING',
  initials: 'AG',
  name: 'Aguardando',
  color: '#ff69b4',
};
