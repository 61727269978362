import React from 'react';

import Close from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';

import { appActions } from 'commons/store/app';

import styles from './styles';

const useStyles = makeStyles(styles);

export function GlobalDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open = false, title = '', component, onClose } = useSelector(
    ({ app }) => app.drawer
  );

  const handleClose = React.useCallback(() => {
    !!onClose && onClose();
    dispatch(appActions.hideGlobalDrawer());
  }, [dispatch, onClose]);

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <div className={classes.header}>
        <Typography variant="h6">{title}</Typography>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </div>
      <div className={classes.content}>{open && component}</div>
    </Drawer>
  );
}

export default GlobalDrawer;
