import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import auth from 'commons/auth';
import allRoutes from 'commons/routes';

export default ({ component: Component, context, routes, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const {
        location: { pathname, search },
      } = props;

      return !!auth.getToken() ? (
        <Component {...props} routes={routes} />
      ) : (
        <Redirect
          to={`${allRoutes.urls.LOGIN}?redirect=${pathname}${search}`}
        />
      );
    }}
  />
);
