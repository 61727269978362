import { createAction } from 'redux-actions';

export default {
  getProfiles: createAction('user/GET_PROFILES_REQUEST'),
  getUsersByClient: createAction('user/GET_USERS_BY_CLIENT_REQUEST'),
  updateProfiles: createAction('user/GET_PROFILES_SUCCESS'),
  updateUserProfiles: createAction('user/GET_USER_PROFILES_SUCCESS'),
  getProfile: createAction('user/GET_PROFILE_REQUEST'),
  updateProfile: createAction('user/GET_PROFILE_SUCCESS'),
  getProfilesByUser: createAction('user/GET_PROFILES_BY_USER'),
  getAllUsers: createAction('user/GET_ALL_USERS_REQUEST'),
  getActiveUsers: createAction('user/GET_USERS_REQUEST'),
  updateUsers: createAction('user/GET_USERS_SUCCESS'),
  getUser: createAction('user/GET_USER_REQUEST'),
  updateUser: createAction('user/GET_USER_SUCCESS'),
  setUserProfiles: createAction('user/SET_USER_PROFILES'),
  toogleClientsListAutomatically: createAction(
    'user/TOGGLE_CLIENTS_LIST_AUTOMATICALLY'
  ),
  toogleMapsListAutomatically: createAction(
    'user/TOGGLE_MAPS_LIST_AUTOMATICALLY'
  ),
  toogleMapsListOrder: createAction('user/TOGGLE_MAPS_LIST_ORDER'),
  toggleMapActiveLayer: createAction('user/TOGGLE_MAP_ACTIVE_LAYER'),
  updateLayerMapOpacity: createAction('user/UPDATE_LAYER_MAP_OPACITY'),
  getUsersByProfile: createAction('user/GET_USERS_FROM_PROFILE_REQUEST'),
  getUsersByFilter: createAction('user/GET_USERS_BY_MAP_ID_REQUEST'),
  getUsersByActivityId: createAction('user/GET_USERS_BY_ACTIVITY_ID_REQUEST'),
  getUsersByMaps: createAction('user/GET_USERS_BY_MAPS_REQUEST'),
  saveProfile: createAction('user/SAVE_PROFILE_REQUEST'),
  deleteUser: createAction('user/DELETE_USER_REQUEST'),
  clearUsers: createAction('user/CLEAR_USERS'),
  removeUserProfilePermission: createAction(
    'user/REMOVE_PROFILE_PERMISSION_REQUEST'
  ),
  addUserProfilePermission: createAction(
    'user/SAVE_PROFILE_PERMISSION_REQUEST'
  ),
  getNewUsersAssociation: createAction(
    'user/GET_NEW_USERS_ASSOCIATION_REQUEST'
  ),
  updateNewUsersAssociation: createAction(
    'user/GET_NEW_USERS_ASSOCIATION_SUCCESS'
  ),
  updateCurrentMapUsers: createAction('user/GET_CURRENT_MAP_USERS_SUCCESS'),
  defineProfileAbilities: createAction('user/DEFINE_PROFILE_ABILITIES'),
  getAvailablePermissions: createAction('user/GET_AVAILABLE_PERMISSIONS'),
  getAvailablePermissionsSuccess: createAction(
    'user/GET_AVAILABLE_PERMISSIONS_SUCCESS'
  ),
  updateMapAccess: createAction('use/UPDATE_MAP_ACCESS'),
};
