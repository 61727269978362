import produce from 'immer';
import Immutable from 'immutable';

import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { layerTypeActions } from 'commons/store/layerType';
import { mapLayerActions } from 'commons/store/mapLayer';
import { VIEWS } from 'commons/constants';

/* selectors */
const mergeWithExpandedNode = (tree, expandedNodes) => {
  tree.contracted = tree.root ? false : !expandedNodes.has(tree.id);
  !!tree.children &&
    tree.children.forEach(child => mergeWithExpandedNode(child, expandedNodes));

  return tree;
};

const layersSelectors = ({ mapLayer }) => mapLayer.details.layers;

const activeLayersSelector = createSelector(
  layersSelectors,
  layers => ({
    ids: layers.keySeq().toArray(),
    list: layers.toArray(),
  })
);

const visibleLayersSelector = createSelector(
  activeLayersSelector,
  layers => layers.list.filter(layer => !!layer.visible)
);

const expandedLayerTreeSelector = ({ mapLayer }) => {
  const { editing } = mapLayer.details;
  const view = !!editing ? VIEWS.LAYERS : VIEWS.MAP;
  return mergeWithExpandedNode(mapLayer.tree, mapLayer.expandedNodes[view]);
};

export const selectors = {
  layersSelectors,
  activeLayersSelector,
  expandedLayerTreeSelector,
  visibleLayersSelector,
};

/* handler */
const layerState = {
  name: '',
  opacity: 1,
  typeId: '',
  files: [],
  types: [],
  styleFunction: '',
  attributes: [],
  merged: [],
};

const initialState = {
  tree: {},
  allLayersTree: {},
  expandedNodes: {
    map: Immutable.Set(),
    layers: Immutable.Set(),
  },
  details: {
    layer: layerState,
    node: null,
    layerInputs: [],
    layerInputId: '',
    editing: '',
    nodeId: '',
    layers: Immutable.Map(),
  },
};

const toggleLayerNode = (isDelete = false) => (state, { payload }) => {
  const {
    details: { layers },
  } = state;
  const { id } = payload;

  const hasAddedLayerYet = layers.has(id);

  const activeLayer = {
    visible: true,
    name: payload.name,
    nodeId: id,
    ...payload.data,
  };

  const _layers = hasAddedLayerYet
    ? isDelete
      ? layers.delete(id)
      : layers.updateIn([id], item => ({ ...item, visible: !item.visible }))
    : layers.set(id, activeLayer);

  return { ...state, details: { ...state.details, layers: _layers } };
};

const getActiveLayers = (
  tree,
  activeLayersKeys,
  activeLayers,
  layers = Immutable.Map()
) => {
  if (!!tree.data && activeLayersKeys.includes(tree.id)) {
    const activeLayer = activeLayers[tree.id];

    return layers.set(tree.id, {
      visible: true,
      name: tree.name,
      nodeId: tree.id,
      order: activeLayer.order,
      ...tree.data,
      opacity: activeLayer.opacity,
    });
  }
  if (tree.children) {
    tree.children.forEach(child => {
      layers = getActiveLayers(child, activeLayersKeys, activeLayers, layers);
    });
  }

  return layers;
};

const updateLayerFiles = (state, { types, columns }) => {
  const { details } = state;

  return {
    ...details,
    layer: {
      ...details.layer,
      types,
      attributes: Object.values(columns).map(attr => ({
        id: attr.id,
        key: attr.name,
        visible: false,
      })),
    },
  };
};

const updateLayerAttributes = (state, { payload }) => {
  const {
    details: { layers },
  } = state;
  const { id, attrs } = payload;

  const _layers = layers.updateIn([id], item => ({ ...item, ...attrs }));

  return { ...state, details: { ...state.details, layers: _layers } };
};

const mergeLayerAttributes = (state, { payload }) => {
  const {
    details: { layer },
  } = state;

  const {
    data: { attributes: layerTypeAttrs },
    typeId,
  } = payload;

  let merged = [];

  if (!!layerTypeAttrs && layer) {
    merged = [...layer.attributes];

    const layerKeys = layer.attributes.map(item => item.key);

    layerTypeAttrs.forEach(item => {
      merged = merged.map(attr => (attr.key === item.key ? item : attr));

      if (!layerKeys.includes(item.key)) {
        merged.push(item);
      }
    });
  }

  return {
    ...state,
    details: {
      ...state.details,
      layer: { ...layer, typeId, merged },
    },
  };
};

export default handleActions(
  {
    [mapLayerActions.restoreState]: (_, { payload }) => payload,

    [mapLayerActions.clearMapLayer]: () => initialState,

    [mapLayerActions.toggleNodeActive]: toggleLayerNode(false),

    [mapLayerActions.removeNodeFromMap]: toggleLayerNode(true),

    [mapLayerActions.changeLayerAttributes]: updateLayerAttributes,

    [mapLayerActions.openLayerEditor]: produce((draft, { payload }) => {
      draft.details = {
        ...initialState.details,
        editing: VIEWS.LAYERS,
      };
    }),

    [mapLayerActions.updateAllMapTrees]: produce((draft, { payload }) => {
      draft.allLayersTree = payload;
    }),

    [mapLayerActions.updateTree]: (state, { payload }) => {
      const { tree } = state;

      return { ...state, tree: payload || tree };
    },

    [mapLayerActions.toggleExpandNode]: (state, { payload }) => {
      const { expandedNodes } = state;
      const { node, view } = payload;

      let _expandedNodesTree = expandedNodes[view];

      _expandedNodesTree = _expandedNodesTree.has(node)
        ? _expandedNodesTree.delete(node)
        : _expandedNodesTree.add(node);

      return {
        ...state,
        expandedNodes: { ...expandedNodes, [view]: _expandedNodesTree },
      };
    },

    [layerTypeActions.saveLayerTypeAsCurrent]: (state, { payload }) => {
      const { details } = state;

      if (details.layer) {
        return {
          ...state,
          details: {
            ...details,
            layer: { ...details.layer, typeId: payload.id },
          },
        };
      }

      return state;
    },

    [mapLayerActions.updateExpandedNodes]: (state, { payload }) => {
      if (!payload) {
        return state;
      }

      const { expandedNodes } = state;

      return {
        ...state,
        expandedNodes: {
          ...expandedNodes,
          map: Immutable.Set([...expandedNodes.map, ...payload.expandedNodes]),
        },
      };
    },

    [mapLayerActions.updateLayerInputs]: produce((draft, { payload }) => {
      const { inputs, layerId, nodeId } = payload;

      draft.details.nodeId = nodeId;
      draft.details.layerInputId = layerId;
      draft.details.layerInputs = inputs.map((item, index) => ({
        id: index.toString(),
        name: item,
      }));
    }),

    [mapLayerActions.updateActiveLayers]: (state, { payload }) => {
      if (!payload) {
        return state;
      }

      const { layers } = payload;

      const { details, tree } = state;

      const activeLayersKeys = Object.keys(layers).filter(
        key => layers[key].active
      );

      return {
        ...state,
        details: {
          ...details,
          layers: getActiveLayers(
            tree,
            activeLayersKeys,
            payload.layers,
            details.layers
          ),
        },
      };
    },

    [mapLayerActions.addLayer]: produce((draft, { payload }) => {
      const {
        node: { children, ...rest },
        name,
        context,
      } = payload;

      draft.details.node = rest;
      draft.details.editing = context;
      draft.details.layer.name = name;
    }),

    [mapLayerActions.editUsersRestriction]: produce((draft, { payload }) => {
      const {
        node: { children, ...rest },
        context,
      } = payload;

      draft.details.node = rest;
      draft.details.editing = context;
    }),

    [mapLayerActions.cancelEditing]: produce((draft, { payload }) => {
      draft.allTrees = {};
      draft.details = initialState.details;
    }),

    [mapLayerActions.updateLayerFiles]: produce((draft, { payload }) => {
      draft.details.layer.id = payload;
    }),

    [mapLayerActions.updateLayerFilesStatus]: (state, { payload }) => {
      if (payload.columns) {
        return {
          ...state,
          attrSettings: payload,
          details: updateLayerFiles(state, payload),
        };
      }

      return state;
    },

    [mapLayerActions.updateLayerAttrs]: produce((draft, { payload }) => {
      draft.attrSettings = payload;
    }),

    [mapLayerActions.mergeLayerTypeAttrs]: mergeLayerAttributes,

    [mapLayerActions.editLayer]: produce((draft, { payload }) => {
      const { node, context } = payload;
      const { data } = node;

      draft.details.node = node;
      draft.details.editing = context;
      draft.details.layer = {
        name: node.name,
        ...data,
      };
    }),
  },
  initialState
);
