export default {
  container: {
    padding: '1em',
    flexDirection: 'column',
    borderBottom: '1px solid #dcdcdc',
  },
  generalInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  wrapContent: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
  },
  userAvatarDrawer: {
    marginRight: '1em',
  },
  statusInitials: {
    color: '#fff',
  },
  title: {
    flex: '1 0 10em',
  },
  dueDate: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 1 12em',
  },
  clockIcon: {
    color: '#cc2c2c',
    marginRight: '.5em',
  },
  criticalClockIcon: {
    color: '#ffa500',
    marginRight: '.5em',
  },
  details: {
    cursor: 'pointer',
    backgroundColor: '#f9f9f9',
    marginTop: '1rem',
    padding: '.5rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
};
