import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';

import styles from './styles';

function SelectCircularProgress({ classes }) {
  return (
    <div className={classes.progress}>
      <CircularProgress size={18} />
    </div>
  );
}

export default withStyles(styles)(SelectCircularProgress);
