import { createAction } from 'redux-actions';

export default {
  getPaymentMethods: createAction('service/GET_PAYMENT_METHODS_REQUEST'),
  updatePaymentMethods: createAction('service/GET_PAYMENT_METHODS_SUCCESS'),
  getDynamicForms: createAction('service/GET_DYNAMIC_FORMS_REQUEST'),
  updateDynamicForms: createAction('service/GET_DYNAMIC_FORMS_SUCCESS'),
  getServices: createAction('service/GET_SERVICES_REQUEST'),
  updateServices: createAction('service/GET_SERVICES_SUCCESS'),
  getService: createAction('service/GET_SERVICE_REQUEST'),
  updateService: createAction('service/GET_SERVICE_SUCCESS'),
  saveService: createAction('service/SAVE_SERVICE_REQUEST'),
  serviceSaved: createAction('service/SAVE_SERVICE_SUCCESS'),
  updateServiceRequest: createAction('service/UPDATE_SERVICE_REQUEST'),
  serviceUpdated: createAction('service/UPDATE_SERVICE_SUCCESS'),
  clearService: createAction('service/CLEAR_SERVICE'),
};
