import React from 'react';

import FormDinamicoReactRender from 'bunch-form-dinamico-react-render';
import { object, bool, func } from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

import auth from 'commons/auth';
import { getFormEnvironment } from 'commons/api';
import { loadingSelectors } from 'commons/store/loading';
import { activityActions } from 'commons/store/activity';

const { saveActivityFormValues } = activityActions;
const { isLoading } = loadingSelectors;

const useStyles = makeStyles({
  loading: { marginRight: '.5rem' },
});

export function FormRender({ schema, blocked, values, onSave }) {
  const classes = useStyles();

  const saveLoading = useSelector(s => isLoading(s, saveActivityFormValues));

  const environment = React.useMemo(() => {
    const headers = {
      Authorization: `Bearer ${auth.getToken()}`,
      'X-Tenant': auth.getTenant(),
    };

    return getFormEnvironment(headers);
  }, []);

  return (
    <FormDinamicoReactRender
      formData={values}
      form={schema}
      environment={environment}
      onSubmit={onSave}
    >
      <Button
        disabled={saveLoading || blocked}
        variant="contained"
        color="primary"
        type="submit"
      >
        {saveLoading && (
          <CircularProgress size={24} className={classes.loading} />
        )}
        <FormattedMessage id="LABELS.COMMONS.SAVE" />
      </Button>
    </FormDinamicoReactRender>
  );
}

FormRender.propTypes = {
  /* own props */
  values: object,
  blocked: bool.isRequired,
  schema: object.isRequired,
  onSave: func.isRequired,
};

export default React.memo(FormRender);
