import axios from 'axios';

export default {
  getActivityThemesByClient: clientId =>
    axios.get('/themes', { params: { clientId } }),

  filterElements: filters => {
    const { themeId, ...rest } = filters;
    return axios.get(`/themes/${themeId}/elements`, { params: rest });
  },

  getActivityThemesList: () => axios.get('/activities/themes'),

  getActivityMapsList: () => axios.get('/activities/maps'),

  getActivityUsersList: () => axios.get('/activities/users'),

  getActivityForms: payload =>
    axios.get(
      '/activities/forms',
      payload.clientId ? { params: { clientId: payload.clientId } } : {}
    ),

  saveActivityFormValues: ({ activityId, formId, elementId, values }) =>
    axios.put(`/activities/${activityId}/forms/${formId}/values`, values, {
      params: { elementId },
    }),

  saveActivity: activity => axios.post('/activities', activity),

  updateActivity: activity => axios.put('/activities', activity),

  getActivity: id => axios.get(`/activities/${id}`),

  filterActivities: filters => axios.post(`/activities/filter`, filters),

  deleteActivity: ({ activityId, deleteRecurrent }) =>
    axios.delete(`/activities/${activityId}/${deleteRecurrent}`),

  updateActivityMembers: ({ activityId, members }) =>
    axios.put(`/activities/${activityId}/members`, members),

  getActivityElementForms: activityId =>
    axios.get(`/activities/${activityId}/element-forms`),

  saveActivityMessage: ({ activityId, message }) =>
    axios.post(`/activities/${activityId}/message`, message),

  exportActivity: ({ activityId, templateId, extension }) =>
    axios({
      method: 'get',
      url: `/activities/export/${activityId}/template/${templateId}/extension/${extension}`,
      responseType: 'blob',
    }),
};
