import { handleActions } from 'redux-actions';

import { parametersActions } from 'commons/store/parameters';

const initialState = {
  parameters: null,
};

export default handleActions(
  {
    [parametersActions.uupdateParametersList]: (state, { payload }) => ({
      ...state,
      parameters: payload,
    }),
  },
  initialState
);
