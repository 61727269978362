import delay from '@redux-saga/delay-p';
import { takeEvery, putResolve, put, fork } from 'redux-saga/effects';
import api from 'commons/api';
import notification from 'commons/notification';
import { mapLayerService } from 'commons/services';
import { mapLayerActions } from 'commons/store/mapLayer';
import { appActions } from 'commons/store/app';
import { loadingSaga } from 'commons/store/loading';

import {
  VIEWS,
  GET_LAYER_DATA_TIMEOUT,
  MESSAGE_EXTENDED_AUTO_CLOSE_TIME,
} from 'commons/constants';

function* moveNode({ payload }) {
  yield* api(
    mapLayerService.moveNode,
    payload,
    mapLayerActions.updateTree,
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_MOVE_NODE', { type: 'error' });
    },
    { delay: 0, multiple: false, loading: false }
  );
}

function* addNode({ payload }) {
  yield* api(
    mapLayerService.addNode,
    payload,
    mapLayerActions.updateTree,
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_ADD_NODE', { type: 'error' });
    },
    { multiple: false }
  );
}

function* updateLayerOrder({ payload }) {
  yield* api(
    mapLayerService.updateLayerOrder,
    payload,
    mapLayerActions.updateTree,
    () => {},
    { multiple: false, loading: false }
  );
}

function* updateNode({ payload }) {
  yield* api(
    mapLayerService.updateNode,
    payload,
    mapLayerActions.updateTree,
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_UPDATE_NODE', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* deleteNode({ payload }) {
  yield* api(
    mapLayerService.deleteNode,
    payload,
    mapLayerActions.updateTree,
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_DELETE_NODE', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* addLayersToNode({ payload }) {
  try {
    const { data } = yield mapLayerService.addLayersToNode(payload);

    yield put(appActions.hideGlobalDrawer());
    yield put(mapLayerActions.openLayerEditor());
    yield put(mapLayerActions.updateTree(data));

    yield notification('MESSAGES.MAP.SUCCESSFUL_COPIED_LAYERS', {
      type: 'success',
    });
  } catch (e) {
    console.log(e);
    yield notification('MESSAGES.MAP.COULD_NOT_COPY_LAYERS', {
      type: 'error',
    });
  }
}

function* getAllMapsTrees({ payload }) {
  try {
    const { data } = yield mapLayerService.getAllMapsTrees(payload);
    yield put(mapLayerActions.updateAllMapTrees(data));
  } catch (e) {
    yield put(appActions.hideGlobalDrawer());
    yield notification('MESSAGES.MAP.COULD_NOT_GET_RECENTLY_LAYERS', {
      type: 'error',
    });
  }
}

function* getLayerFilesStatus({ payload }) {
  yield put(appActions.hideGlobalLoading());
  const { layerId, name, node } = payload;

  yield* api(
    mapLayerService.getLayerFilesStatus,
    layerId,
    function*({ data, status }) {
      yield put(appActions.hideGlobalDrawer());

      if (status === 202) {
        yield put(mapLayerActions.cancelEditing());
        yield notification('MESSAGES.MAP.YOUR_LAYER_WILL_BE_CREATED_LATER', {
          type: 'info',
          autoClose: MESSAGE_EXTENDED_AUTO_CLOSE_TIME,
        });
        return;
      }
      yield putResolve(
        mapLayerActions.addLayer({ node, name, context: VIEWS.ADD_LAYER })
      );
      yield put(mapLayerActions.updateLayerFilesStatus(data));
      yield notification('MESSAGES.MAP.SUCCESSFUL_ADDED_LAYER_FILES', {
        type: 'success',
      });
    },
    function*(err) {
      yield put(appActions.hideGlobalDrawer());
      yield notification('MESSAGES.MAP.COULD_NOT_CREATE_YOUR_LAYER', {
        type: 'error',
      });
    },
    {
      entireResponse: true,
    }
  );
}

function* getDXFInputs({ payload }) {
  const { layerId, name, nodeId, node } = payload;

  yield* api(
    mapLayerService.getDXFInputs,
    layerId,
    function*(data) {
      yield put(appActions.hideGlobalDrawer());
      yield putResolve(
        mapLayerActions.addLayer({
          node,
          name,
          context: VIEWS.ADD_LAYER_INPUTS,
        })
      );
      yield put(
        mapLayerActions.updateLayerInputs({
          nodeId,
          inputs: data,
          layerId: layerId,
        })
      );
    },
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_ADD_LAYER_FILES', {
        type: 'error',
      });
    }
  );
}

function* addDXFLayerFiles({ payload }) {
  const { name, node, ...rest } = payload;

  yield* api(
    mapLayerService.addDXFLayerFiles,
    rest,
    function*(layerId) {
      yield fork(getDXFInputs, {
        payload: { layerId, name, node, nodeId: rest.nodeId },
      });
    },
    function*(err) {
      yield notification(
        (err.response?.status === 400 && err.response.data?.message) ||
          'MESSAGES.MAP.COULD_NOT_ADD_LAYER_FILES',
        { type: 'error' }
      );
    }
  );
}

function* addDXFLayers({ payload }) {
  yield* api(
    mapLayerService.addDXFLayers,
    payload,
    function*(data) {
      yield put(mapLayerActions.openLayerEditor());
      yield notification('MESSAGES.MAP.YOUR_LAYER_WILL_BE_CREATED_LATER', {
        type: 'info',
        autoClose: MESSAGE_EXTENDED_AUTO_CLOSE_TIME,
      });
    },
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_ADD_LAYER_FILES', {
        type: 'error',
      });
    }
  );
}

function* addLayerFiles({ payload }) {
  const { name, node, ...rest } = payload;

  yield* api(
    mapLayerService.addLayerFiles,
    rest,
    function*(layerId) {
      if (!!layerId) {
        yield put(mapLayerActions.updateLayerFiles(layerId));
        yield put(appActions.showGlobalLoading());
        yield delay(GET_LAYER_DATA_TIMEOUT);
        yield fork(getLayerFilesStatus, { payload: { layerId, name, node } });
        return;
      }
      yield notification('MESSAGES.MAP.COULD_NOT_ADD_LAYER_FILES', {
        type: 'error',
      });
    },
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_ADD_LAYER_FILES', {
        type: 'error',
      });
    }
  );
}

function* getLayerDetails({ payload }) {
  yield* api(
    mapLayerService.getLayerDetails,
    payload,
    mapLayerActions.updateLayerAttrs,
    function*(err) {
      yield notification('MESSAGES.MAP.COULD_NOT_GET_LAYER_DETAILS', {
        type: 'error',
      });
    },
    { multiple: false }
  );
}

function* updateUsersFolderRestriction({ payload }) {
  yield* api(
    mapLayerService.updateUsersRestrictions,
    payload,
    function*(data) {
      yield put(mapLayerActions.updateTree(data));
      yield put(mapLayerActions.cancelEditing());
      yield notification(
        'MESSAGES.TREE.SUCCESSFUL_UPDATED_FOLDER_RESTRICTIONS',
        { type: 'success' }
      );
    },
    function*(err) {
      yield notification('MESSAGES.TREE.COULD_NOT_UPDATE_FOLDER_RESTRICTIONS', {
        type: 'error',
      });
    }
  );
}

function* exportLayer({ payload }) {
  const { urlExport } = payload;

  yield window.open(urlExport, '_blank');
}

export default function*() {
  yield takeEvery(mapLayerActions.addLayerFiles.toString(), addLayerFiles);
  yield takeEvery(
    mapLayerActions.getLayerFilesStatus.toString(),
    getLayerFilesStatus
  );
  yield takeEvery(mapLayerActions.getLayerDetails.toString(), getLayerDetails);
  yield takeEvery(
    mapLayerActions.updateLayerOrder.toString(),
    updateLayerOrder
  );
  yield takeEvery(
    mapLayerActions.addLayersToNode.toString(),
    loadingSaga(addLayersToNode)
  );
  yield takeEvery(
    mapLayerActions.getAllMapsTrees.toString(),
    loadingSaga(getAllMapsTrees)
  );
  yield takeEvery(mapLayerActions.moveNode.toString(), moveNode);
  yield takeEvery(
    mapLayerActions.addDXFLayerFiles.toString(),
    addDXFLayerFiles
  );
  yield takeEvery(mapLayerActions.addNode.toString(), addNode);
  yield takeEvery(mapLayerActions.addDXFLayers.toString(), addDXFLayers);
  yield takeEvery(mapLayerActions.updateNode.toString(), updateNode);
  yield takeEvery(mapLayerActions.deleteNode.toString(), deleteNode);
  yield takeEvery(
    mapLayerActions.updateUsersFolderRestriction.toString(),
    updateUsersFolderRestriction
  );
  yield takeEvery(mapLayerActions.exportLayer.toString(), exportLayer);
}
