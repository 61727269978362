import { takeEvery, put, fork } from 'redux-saga/effects';

import api from 'commons/api';
import notification from 'commons/notification';
import { clientService } from 'commons/services';
import { clientActions } from 'commons/store/client';
import { formActions } from 'commons/store/form';
import { loadingSaga } from 'commons/store/loading';

function* getClients({ payload }) {
  try {
    const { data } = yield clientService.getClients(payload);
    yield put(clientActions.updateClients(data));
  } catch (err) {
    console.log(err);
  }
}

function* getActiveClients() {
  try {
    const { data } = yield clientService.getActiveClients();
    yield put(clientActions.updateClients(data));
  } catch (err) {
    console.log(err);
  }
}

function* getClientDetails({ payload }) {
  yield* api(
    clientService.getClientDetails,
    payload.id,
    function*(form) {
      yield put(
        formActions.updateForm({
          form,
          application: 'clients',
          context: 'client',
        })
      );
      yield put(clientActions.saveClientAsCurrent(payload));
    },
    function*(err) {
      yield notification('MESSAGES.FORMS.COULD_NOT_GET_FORM_SCHEMA', {
        type: 'error',
      });
    }
  );
}

function* saveClient({ payload }) {
  yield* api(
    clientService[payload.id ? 'updateClient' : 'saveClient'],
    payload,
    function*(data) {
      if (!payload.id) {
        yield put(clientActions.deleteCurrentClient());
      }

      yield fork(getClients, { payload: null });

      yield notification('MESSAGES.CLIENTS.CLIENT_SUCCESSFUL_SAVED', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.CLIENTS.COULD_NOT_SAVE_CLIENT', {
        type: 'error',
      });
    }
  );
}

function* deleteClient({ payload }) {
  yield* api(
    clientService.deleteClient,
    payload,
    function*(data) {
      yield fork(getClients, { payload: null });

      yield notification('MESSAGES.CLIENTS.CLIENT_SUCCESSFUL_DELETED', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.CLIENTS.COULD_NOT_DELETE_CLIENT', {
        type: 'error',
      });
    }
  );
}

export default function*() {
  yield takeEvery(clientActions.getClients.toString(), loadingSaga(getClients));
  yield takeEvery(
    clientActions.getActiveClients.toString(),
    loadingSaga(getActiveClients)
  );
  yield takeEvery(clientActions.getClientDetails.toString(), getClientDetails);
  yield takeEvery(clientActions.saveClient.toString(), saveClient);
  yield takeEvery(clientActions.deleteClient.toString(), deleteClient);
}
