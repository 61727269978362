import { takeEvery, call, put, putResolve, select } from 'redux-saga/effects';

import api from 'commons/api';
import notification from 'commons/notification';
import routes from 'commons/routes';
import { config } from 'commons/store';
import { mapService, userService, activityService } from 'commons/services';
import { VIEWS } from 'commons/constants';
import { activityActions } from 'commons/store/activity';
import { mapActivityActions } from 'commons/store/mapActivity';
import { appActions } from 'commons/store/app';
import { loadingSaga } from 'commons/store/loading';

import FileSaver from 'file-saver';

function isUnauthorizedUser(activity, user) {
  const { responsible, author } = activity;

  const _responsible = responsible.id || responsible;
  const _author = author.id || author;

  return (
    user.id !== _responsible &&
    user.id !== _author &&
    !activity.members.includes(user.id)
  );
}

function* getActivityThemesByClient({ payload }) {
  try {
    const { data } = yield activityService.getActivityThemesByClient(payload);
    yield put(activityActions.updateActivityEditingThemes(data));
  } catch (err) {
    console.log(err);
  }
}

function* getActivityForms(req) {
  try {
    const { data } = yield activityService.getActivityForms(
      req ? req.payload : {}
    );
    yield put(activityActions.updateActivityEditingForms(data));
  } catch (err) {
    yield notification('MESSAGES.FORMS.COULD_NOT_GET_FORMS', {
      type: 'error',
    });
  }
}

function* filterElements({ payload }) {
  const { theme, category, elementType } = payload;

  const filter = {
    themeId: theme.id,
  };

  !!category.id && (filter['categoryId'] = category.id);
  !!elementType.id && (filter['elementTypeId'] = elementType.id);

  try {
    const { data } = yield activityService.filterElements(filter);
    yield put(activityActions.updateElementsBuffer(data));
  } catch (err) {
    console.log(err);
  }
}

function* getActivityMapsByClient({ payload }) {
  try {
    const { data } = yield mapService.getMapsByClientId({ id: payload });
    yield put(activityActions.updateActivityEditingMaps(data));
  } catch (err) {
    yield notification('MESSAGES.MAP.COULD_NOT_GET_MAPS', { type: 'error' });
  }
}

function* getActivityUsersByClient({ payload }) {
  try {
    const { data } = yield userService.getAllUsers(payload);
    yield put(activityActions.updateActivityEditingUsers(data));
  } catch (err) {
    yield notification('MESSAGES.USER.COULD_NOT_GET_USERS', {
      type: 'error',
    });
  }
}

function* getActivityThemesList() {
  try {
    const { data } = yield activityService.getActivityThemesList();
    yield put(activityActions.updateInActivityLists({ themes: data }));
  } catch (err) {
    console.log(err);
  }
}

function* getActivityMapsList() {
  try {
    const { data } = yield activityService.getActivityMapsList();
    yield put(activityActions.updateInActivityLists({ maps: data }));
  } catch (err) {
    console.log(err);
  }
}

function* getActivityUsersList() {
  try {
    const { data } = yield activityService.getActivityUsersList();
    yield put(activityActions.updateInActivityLists({ users: data }));
  } catch (err) {
    console.log(err);
  }
}

function* toggleActivityFavorite({ payload }) {
  try {
    yield activityService.updateActivity(payload);
    yield put(activityActions.updateActivityInList(payload));
  } catch (err) {
    yield notification('MESSAGES.ACTIVITIES.COULD_NOT_SAVE_ACTIVITY', {
      type: 'error',
    });
  }
}

function* saveActivity({ payload }) {
  yield* api(
    activityService[payload.id ? 'updateActivity' : 'saveActivity'],
    payload,
    function*(data) {
      payload.elements = {};
      const url = config.history.location.pathname;

      yield notification('MESSAGES.ACTIVITIES.ACTIVITY_SUCCESSFUL_SAVED', {
        type: 'success',
      });

      if (!payload.id) {
        yield put(activityActions.closeActivity());
        yield put(activityActions.addActivityToList(data));

        if (url.includes(routes.urls.MAP_SETTINGS)) {
          yield put(mapActivityActions.addActivityToList(data));
        }
        return;
      }

      yield put(activityActions.updateActivityInList(data));

      if (url.includes(routes.urls.MAP_SETTINGS)) {
        yield put(mapActivityActions.updateActivityInList(data));
      }

      if (data.status === 'FINISHED') {
        yield put(activityActions.closeActivity());
      }
    },
    function*(err) {
      yield notification('MESSAGES.ACTIVITIES.COULD_NOT_SAVE_ACTIVITY', {
        type: 'error',
      });
    }
  );
}

function* getActivity({ payload }) {
  try {
    const { data } = yield activityService.getActivity(payload);
    yield put(activityActions.updateEditingActivity(data));
    return data;
  } catch (err) {
    console.log(err);
  }
}

function* openActivityDetails({ payload }) {
  yield* api(
    activityService.getActivity,
    payload,
    function*(activity) {
      const user = yield select(({ auth }) => auth.user);

      if (isUnauthorizedUser(activity, user)) {
        yield notification(
          'MESSAGES.ACTIVITIES.YOU_DO_NOT_HAVE_ACTIVITY_ACCESS',
          {
            type: 'error',
          }
        );
        return;
      }
      try {
        yield put(activityActions.getActivityElementForms(activity));
      } catch (err) {
        yield notification('MESSAGES.ACTIVITIES.COULD_NOT_GET_ACTIVITY', {
          type: 'error',
        });
        return;
      }
    },
    function*(err) {
      yield notification('MESSAGES.ACTIVITIES.COULD_NOT_GET_ACTIVITY', {
        type: 'error',
      });
    }
  );
}

function* getEditingActivity({ payload }) {
  yield* api(
    activityService.getActivity,
    payload,
    function*(activity) {
      const user = yield select(({ auth }) => auth.user);
      const { client, author } = activity;

      if (user.id !== author.id) {
        yield notification(
          'MESSAGES.ACTIVITIES.YOU_DO_NOT_HAVE_ACTIVITY_ACCESS',
          {
            type: 'error',
          }
        );
        return;
      }

      const editConfig = { view: VIEWS.ACTIVITY_EDITOR, activity };

      yield put(activityActions.getActivityUsersByClient(client));
      yield put(activityActions.getActivityThemesByClient(client));
      yield put(activityActions.getActivityMapsByClient(client));

      yield put(activityActions.openActivityDrawer(editConfig));
    },
    function*(err) {
      yield notification('MESSAGES.ACTIVITIES.COULD_NOT_GET_ACTIVITY', {
        type: 'error',
      });
    }
  );
}

function* getActivityElementForms({ payload }) {
  yield put(appActions.showGlobalLoading());

  try {
    const activity = yield call(getActivity, { payload: payload.id });

    yield putResolve(
      activityActions.updateActivityElementForms(activity.elementForms)
    );

    yield put(
      activityActions.openActivityDrawer({
        view: VIEWS.ACTIVITY_FORM,
        activity,
      })
    );
  } catch (err) {
    yield notification('MESSAGES.ACTIVITIES.COULD_NOT_GET_ACTIVITY', {
      type: 'error',
    });
  } finally {
    yield put(appActions.hideGlobalLoading());
  }
}

function* filterActivities({ payload }) {
  try {
    const { data } = yield activityService.filterActivities(payload);
    yield put(activityActions.updateActivities(data));
  } catch (err) {
    console.log(err);
  }
}

function* saveActivityMessage({ payload }) {
  try {
    const { data } = yield activityService.saveActivityMessage(payload);
    yield put(activityActions.updateActivityMessages(data));
  } catch (err) {
    console.log(err);
  }
}

function* updateActivityMembers({ payload }) {
  try {
    const { data } = yield activityService.updateActivityMembers(payload);

    yield put(activityActions.updateActivityInList(data));
    yield put(appActions.hideGlobalDrawer());

    yield notification('MESSAGES.MAP.SUCCESSFUL_SAVED_ASSOCIATION_MAP', {
      type: 'success',
    });
  } catch (err) {
    yield notification('MESSAGES.MAP.COULD_NOT_ADD_USER_MAP_PERMISSION', {
      type: 'error',
    });
  }
}

function* deleteActivity({ payload }) {
  yield* api(
    activityService.deleteActivity,
    payload,
    function*(data) {
      const url = config.history.location.pathname;

      yield put(activityActions.deleteActivityFromList(data));

      if (url.includes(routes.urls.MAP_SETTINGS)) {
        yield put(mapActivityActions.deleteActivityFromList(data));
      }

      yield put(activityActions.closeActivity());
      yield notification('MESSAGES.ACTIVITIES.ACTIVITY_SUCCESSFUL_DELETED', {
        type: 'success',
      });
    },
    function*(err) {
      yield notification('MESSAGES.ACTIVITIES.COULD_NOT_DELETE_ACTIVITY', {
        type: 'error',
      });
    }
  );
}

function* saveActivityFormValues({ payload }) {
  try {
    const { data } = yield activityService.saveActivityFormValues(payload);
    yield put(activityActions.updateActivityFormValues(data.formValues));

    yield notification('MESSAGES.ACTIVITIES.ACTIVITY_SUCCESSFUL_SAVED', {
      type: 'success',
    });
  } catch (err) {
    yield notification('MESSAGES.ACTIVITIES.COULD_NOT_SAVE_ACTIVITY', {
      type: 'error',
    });
  }
}

function* exportActivity({ payload }) {
  yield* api(
    activityService.exportActivity,
    payload,
    function*({ headers, data }) {
      const fileName = yield headers['content-disposition']
        .split(';')[1]
        .split('=')[1];

      FileSaver.saveAs(
        new Blob([data], { type: headers['content-type'] }),
        fileName
      );
    },
    err => console.log(err),
    { entireResponse: true }
  );
}

export default function*() {
  yield takeEvery(
    activityActions.getActivityThemesByClient.toString(),
    loadingSaga(getActivityThemesByClient)
  );
  yield takeEvery(
    activityActions.filterElements.toString(),
    loadingSaga(filterElements)
  );
  yield takeEvery(
    activityActions.getActivityMapsByClient.toString(),
    loadingSaga(getActivityMapsByClient)
  );
  yield takeEvery(
    activityActions.getActivityUsersByClient.toString(),
    loadingSaga(getActivityUsersByClient)
  );
  yield takeEvery(
    activityActions.getActivityForms.toString(),
    loadingSaga(getActivityForms)
  );
  yield takeEvery(
    activityActions.getActivityThemesList.toString(),
    loadingSaga(getActivityThemesList)
  );
  yield takeEvery(
    activityActions.getActivityMapsList.toString(),
    loadingSaga(getActivityMapsList)
  );
  yield takeEvery(
    activityActions.getActivityUsersList.toString(),
    loadingSaga(getActivityUsersList)
  );
  yield takeEvery(
    activityActions.updateActivityMembers.toString(),
    loadingSaga(updateActivityMembers)
  );
  yield takeEvery(
    activityActions.filterActivities.toString(),
    loadingSaga(filterActivities)
  );
  yield takeEvery(
    activityActions.toggleActivityFavorite.toString(),
    loadingSaga(toggleActivityFavorite)
  );

  yield takeEvery(
    activityActions.getActivityElementForms.toString(),
    getActivityElementForms
  );

  yield takeEvery(
    activityActions.saveActivityMessage.toString(),
    loadingSaga(saveActivityMessage)
  );

  yield takeEvery(
    activityActions.getEditingActivity.toString(),
    getEditingActivity
  );

  yield takeEvery(
    activityActions.saveActivityFormValues.toString(),
    loadingSaga(saveActivityFormValues)
  );

  yield takeEvery(
    activityActions.openActivityDetails.toString(),
    openActivityDetails
  );

  yield takeEvery(activityActions.saveActivity.toString(), saveActivity);
  yield takeEvery(activityActions.deleteActivity.toString(), deleteActivity);

  yield takeEvery(activityActions.exportActivity.toString(), exportActivity);
}
