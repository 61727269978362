import axios from 'axios';

export default {
  getClients: text =>
    axios.get('/clients', !!text ? { params: { q: text } } : null),
  getActiveClients: () => axios.get('/clients/active'),
  getClientDetails: id => axios.get(`/clients/${id}`),
  saveClient: client => axios.post(`/clients`, client),
  updateClient: client => axios.put(`/clients/${client.id}`, client),
  deleteClient: id => axios.delete(`/clients/${id}`),
};
