import React from 'react';
import { node } from 'prop-types';

import { useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

function LoginContainer({ children }) {
  const classes = useStyles();
  const { settings } = useSelector(({ app }) => app);

  return (
    <div className={classes.container}>
      <div className={classes.headings}>
        <Typography variant="h2" classes={{ h2: classes.title }}>
          {settings.loginTitle}
        </Typography>
        <Typography variant="h4" classes={{ h4: classes.subtitle }}>
          {settings.loginSubtitle}
        </Typography>
      </div>
      <Paper elevation={10} square className={classes.content}>
        {children}
      </Paper>
    </div>
  );
}

LoginContainer.propTypes = {
  /* own props */
  children: node.isRequired,
};

export default React.memo(LoginContainer);
